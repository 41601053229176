import { InjectionToken, NgModule } from '@angular/core';
import { SsrTransferStateService } from './core/services/ssr-transfer-state.service';

export const RAF_PACKETA_API_KEY = new InjectionToken<string>('RAF_PACKETA_API_KEY');
export const RAF_PACKETA_EMBED_URL = new InjectionToken<string>('RAF_PACKETA_EMBED_URL');

/**
 * Packeta API key and embed url is provided in Node env variables and injected into Angular app during SSR (server.ts).
 * During SSR bootstrap this injected value is saved to TransferState (SsrTransferStateService) and transferred to browser.
 * During browser bootstrap this value is extracted from TransferState and provided as injection token again. (GtmModule, down below)
 * */

@NgModule({
  providers: [
    {
      provide: RAF_PACKETA_API_KEY,
      useFactory: (ssrTransferStateService: SsrTransferStateService) =>
        ssrTransferStateService.getPacketaTokensFromTransferState().packetaApiKey,
      deps: [SsrTransferStateService],
    },
    {
      provide: RAF_PACKETA_EMBED_URL,
      useFactory: (ssrTransferStateService: SsrTransferStateService) =>
        ssrTransferStateService.getPacketaTokensFromTransferState().packetaEmbedUrl,
      deps: [SsrTransferStateService],
    },
  ],
})
export class PacketaModule {}
