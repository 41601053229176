import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class Logger {
  readonly enableLogToConsole: boolean = true;

  constructor() {
    this.enableLogToConsole = environment.enableLogToConsole;
  }

  log(...msg: unknown[]) {
    if (this.enableLogToConsole) {
      console.log(...msg); // eslint-disable-line
    }
  }

  debug(...msg: unknown[]) {
    if (this.enableLogToConsole) {
      console.log(...msg); // eslint-disable-line
    }
  }

  warn(...msg: unknown[]) {
    if (this.enableLogToConsole) {
      console.warn(...msg); // eslint-disable-line
    }
  }

  error(...msg: unknown[]) {
    if (this.enableLogToConsole) {
      console.error(...msg); // eslint-disable-line
    }
  }
}
