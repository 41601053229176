import { Component, Input } from '@angular/core';

@Component({
  selector: 'raf-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
})
export class SectionComponent {
  @Input()
  variant: 'normal' | 'light' = 'normal';

  @Input()
  size: 'large' | 'medium' | 'small' | 'form' = 'large';

  @Input()
  fillSpace?: boolean;

  /**
   * Add spacing from bottom. 'nullContent' - null spacing of content layout with negative margin
   */
  @Input()
  gutterBottom?: 'normal' | 'nullContent' | 'negative' | 'none' = 'normal';

  /**
   * Add spacing from top. 'nullContent' - null spacing of content layout with negative margin
   */
  @Input()
  gutterTop?: 'normal' | 'nullContent' | 'negative' | 'none';
}
