import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order, Orders } from '../../shared/model/order.model';
import { environment } from '../../../environments/environment';
import { ProductSearchFilters } from '../../shared/model/product-search-filters.model';
import { OrderStatus } from '../../shared/model/order-status.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class OrderService {

  private DEFAULT_PAGE = 1;
  private DEFAULT_PAGE_SIZE = 4;
  private orderStatuses$: BehaviorSubject<OrderStatus[] | null> = new BehaviorSubject<OrderStatus[] | null>(null);

  constructor(private logger: Logger, private http: HttpClient) {
  }

  getOrders(filters: ProductSearchFilters): Observable<Orders | null> {
    const params = new HttpParams({fromObject: {
      ...filters,
        currentPage: filters.currentPage || this.DEFAULT_PAGE,
        pageSize: filters.pageSize || this.DEFAULT_PAGE_SIZE,
      }});
    return this.http.get<Orders>(`${environment.b2cEndpoint}/orders`, {params});
  }

  getOrder(orderId: string, cartId?: string): Observable<Order> {
    let params = new HttpParams();
    if (cartId) {
      params = params.append('cartId', cartId);
    }
    return this.http.get<Order>(`${environment.b2cEndpoint}/orders/${orderId}`, {params});
  }

  createOrder(cartId: string, simulatedOrder: Order, password?: string, heurekaUnwanted? : boolean): Observable<Order> {
    const params = new HttpParams({ fromObject: { cartId } });
    return this.http.post<Order>(`${environment.b2cEndpoint}/orders`, { simulatedOrder, password, heurekaUnwanted }, { params });
  }

  getOrderStatuses(): Observable<OrderStatus[]> {

    if (this.orderStatuses$.value) {
      return this.orderStatuses$.asObservable() as Observable<OrderStatus[]>;
    }

    return this.http.get<OrderStatus[]>(`${environment.b2cEndpoint}/orders/statuses`).pipe(
      tap(res => this.orderStatuses$.next(res))
    )
  }
}
