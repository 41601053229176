<ng-container *transloco="let t">
  <div class="mobile-menu-wrap" *ngIf="open" [@mobileMenu]>
    <div class="menu-container-backdrop" (click)="closeMenu()" ></div>
    <div class="mobile-menu-container">
      <div class="mobile-menu-header">
        <button *ngIf="selectedCategory$ | async" class="mat-button-small mobile-menu-nav-button" mat-icon-button (click)="onPrevCategory()">
          <raf-icon size="16px" icon="arrow-left"></raf-icon>
        </button>
        <div class="mobile-menu-header-title"><raf-text variant="body-large" color="inherit">{{ t('LAYOUT.CATEGORIES')}}</raf-text></div>
        <button class="mat-button-small mobile-menu-close-button" (click)="closeMenu()" mat-icon-button><raf-icon size="16px" icon="close"></raf-icon></button>
      </div>
      <div class="mobile-menu-content">
        <mat-nav-list class="mobile-menu-list mat-list-dividers" *ngIf="categories" [@listAnimation]="animationTrigger$ | async">
          <mat-list-item  *ngFor="let category of ((selectedCategory$ | async)?.subcategories || categories)">
            <a matLine (click)="onNav(category)" [routerLink]="category.url"><raf-text variant="body-medium" color="primary-color">{{ category.name }}</raf-text></a>
            <button class="mat-button-small" mat-icon-button *ngIf="category.subcategories?.length" (click)="onSelectCategory(category)"><raf-icon   size="12px" color="primary" icon="arrow-right"></raf-icon></button>
          </mat-list-item>
        </mat-nav-list>



        <mat-nav-list *ngIf="(selectedCategory$ | async) === null" class="mobile-menu-list mobile-menu-list--secondary mat-list-dividers mat-list-dividers---with-first">
          <mat-list-item *ngFor="let item of menuItems">
            <a matLine (click)="onNav(item)" [routerLink]="item.url"><raf-text variant="body-medium" color="primary-color">{{ item.text }}</raf-text></a>
            <!--<raf-icon size="12px" color="primary" icon="arrow-right"></raf-icon>-->
          </mat-list-item>
        </mat-nav-list>



        <mat-nav-list *ngIf="(selectedCategory$ | async) === null" class="mobile-menu-list mobile-menu-list--tertiary mat-list-dividers mat-list-dividers---with-first">
          <mat-list-item *ngIf="anonymous">
            <a matLine (click)="onLoginClick()"><raf-text variant="body-medium" color="primary-color">{{ t("LAYOUT.LOGIN") }}</raf-text></a>
            <raf-icon size="12px" color="primary" icon="lock"></raf-icon>
          </mat-list-item>

          <mat-list-item *ngIf="!anonymous">
            <a matLine (click)="onLogoutClick()"><raf-text variant="body-medium" color="primary-color">{{ t("LAYOUT.LOGOUT") }}</raf-text></a>
            <raf-icon size="12px" color="primary" icon="lock"></raf-icon>
          </mat-list-item>
        </mat-nav-list>
      </div>

    </div>
  </div>

 </ng-container>
