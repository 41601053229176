<ng-container *transloco="let t">
  <div class="quantity-component" [class.quantity-component-small]="size==='small'">
    <button class="quantity-button quantity-button-left" mat-icon-button (click)="onDecrease()" data-cy="dec-button">-</button>
    <input class="quantity-input" matInput type="number" [formControl]="fc" (blur)="onBlur()" autocomplete="off" [attr.aria-label]="t('GLOBAL.QUANTITY')"/>
    <button class="quantity-button quantity-button-right" mat-icon-button (click)="onIncrease()" data-cy="inc-button">+</button>
    <raf-form-field-error [control]="fc"></raf-form-field-error>

    <raf-text *ngIf="unit" [wrap]="false" class="unit" variant="body-large" weight="medium" color="grey-text">{{unit.symbol}}</raf-text>
  </div>
</ng-container>
