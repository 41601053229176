<ng-container *transloco="let t">
  <div class="mat-dialog-header mat-typography">
    <button class="mat-dialog-close-button mat-small" mat-icon-button (click)="close()">
      <raf-icon icon="close" size="16px"></raf-icon>
    </button>
  </div>

  <mat-dialog-content class="mat-typography two-side-layout">
    <div class="primary-side">
      <div *ngIf="(forgottenPasswordVisible$ | async) === false">
        <raf-text class="mb-3" variant="h3" color="primary-color">{{ t("LOGIN_DIALOG.TITLE") }}</raf-text>

        <form
          type="form"
          [formGroup]="fg"
          (ngSubmit)="onLogin()"
          #fgd="ngForm"
          *ngIf="(loginSuccess$ | async) === false"
        >
          <div class="stack stack-vertical stack-4">
            <raf-input
              [fullWidth]="true"
              type="text"
              name="username"
              formControlName="username"
              label="LOGIN_DIALOG.USERNAME_LABEL"
              data-cy="username-input"
            ></raf-input>
            <raf-input
              [fullWidth]="true"
              name="password"
              autocomplete="current-password"
              formControlName="password"
              label="LOGIN_DIALOG.PASSWORD_LABEL"
              type="password"
              [hidePasswordStrengthIndicator]="true"
              data-cy="password-input"
            ></raf-input>

            <div>
              <div class="row">
                <div class="col-12 col-md-auto flex-grow-1 mb-2">
                  <button
                    class="full-width"
                    type="submit"
                    mat-flat-button
                    color="primary"
                    data-cy="submit-btn"
                    [disabled]="loading$ | async"
                    [rafLoading]="(loading$ | async) === true"
                  >
                    {{ t("LOGIN_DIALOG.SUBMIT") }}
                  </button>
                </div>
                <div class="col-12 mb-2" *ngIf="isMobile$ | async">
                  <a class="full-width" mat-stroked-button routerLink="/registrace" (click)="onNav()"
                     data-cy="registration-btn">{{
                    t("LOGIN_DIALOG.REGISTRATION")
                  }}</a>
                </div>
                <div class="col-12 col-md-auto mb-2">
                  <a class="full-width" mat-flat-button (click)="toggleForgottenPassword(true)"
                     data-cy="forgotten-pass-btn">{{
                    t("LOGIN_DIALOG.FORGOTTEN_PASSWORD")
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div *ngIf="loginSuccess$ | async">
          <raf-status-block [mainTitle]="t('LOGIN_DIALOG.LOGIN_SUCCESS')" motive="success"></raf-status-block>
        </div>
      </div>

      <!-- LEFT HALF - FORGOTTEN PASSWORD -->
      <div *ngIf="forgottenPasswordVisible$ | async">
        <a class="back-button edge-left mat-button-small" mat-flat-button (click)="toggleForgottenPassword(false)"
           data-cy="back-btn">
          <raf-icon class="start-icon" size="16px" icon="arrow-left"></raf-icon>
          {{ t("LOGIN_DIALOG.BACK_TO_LOGIN") }}
        </a>

        <ng-container *ngIf="(forgottenPasswordSuccess$ | async) === false">
          <raf-text class="mb-3" variant="h3" color="primary-color">{{
            t("LOGIN_DIALOG.FORGOTTEN_PASSWORD_TITLE")
          }}</raf-text>

          <form type="form" [formGroup]="fg" (ngSubmit)="onForgottenPass()">
            <div class="stack stack-vertical stack-8">
              <raf-input [fullWidth]="true" formControlName="username" type="text"
                         name="username" label="LOGIN_DIALOG.USERNAME_LABEL"
                         data-cy="forgotten-password-username-input"></raf-input>

                <div>
                  <div class="row">
                    <div class="col-12 col-md-auto flex-grow-1 mb-2">
                      <button
                        class="full-width"
                        type="submit"
                        mat-raised-button
                        color="primary"
                        data-cy="forgotten-password-submit-btn"
                        [disabled]="loading$ | async"
                        [rafLoading]="(loading$ | async) === true"
                      >
                        {{ t("LOGIN_DIALOG.FORGOTTEN_PASSWORD_SUBMIT") }}
                      </button>
                    </div>

                    <ng-container *ngIf="isMobile$ | async">
                      <div class="col-12 mb-3 mt-5">
                        <raf-text variant="body-medium" color="black-text" align="center">{{ t("LOGIN_DIALOG.REGISTRATION_TITLE") }}</raf-text>
                      </div>

                      <div class="col-12">
                        <a class="full-width" mat-stroked-button routerLink="/registrace" (click)="onNav()" data-cy="registration-btn">{{
                          t("LOGIN_DIALOG.REGISTRATION")
                        }}</a>
                      </div>
                    </ng-container>
                  </div>
                </div>
            </div>
          </form>
        </ng-container>

        <raf-status-block *ngIf="forgottenPasswordSuccess$ | async" [mainTitle]="t('LOGIN_DIALOG.FORGOTTEN_PASSWORD_SUCCESS')" motive="success"></raf-status-block>
      </div>
    </div>
    <!-- RIGHT HALF - REGISTRATION -->
    <div class="secondary-side" *ngIf="(isMobile$ | async) === false && (loginSuccess$ | async) === false">
      <raf-text class="mb-1" variant="h3" color="primary-color">{{ t("LOGIN_DIALOG.REGISTRATION_TITLE") }}</raf-text>
      <raf-text variant="body-large" color="grey-text">{{ t("LOGIN_DIALOG.REGISTRATION_DESCRIPTION") }}</raf-text>

      <ul class="icon-list mt-4 mb-4">
        <li>
          <raf-icon icon="circle-check" color="success"></raf-icon>
          <raf-text variant="body-large" color="success-color">{{ t("LOGIN_DIALOG.REGISTRATION_HINT_1") }}</raf-text>
        </li>
        <li>
          <raf-icon icon="circle-check" color="success"></raf-icon>
          <raf-text variant="body-large" color="success-color">{{ t("LOGIN_DIALOG.REGISTRATION_HINT_2") }}</raf-text>
        </li>
        <li>
          <raf-icon icon="circle-check" color="success"></raf-icon>
          <raf-text variant="body-large" color="success-color">{{ t("LOGIN_DIALOG.REGISTRATION_HINT_3") }}</raf-text>
        </li>
      </ul>

      <a mat-stroked-button routerLink="/registrace" (click)="onNav()" data-cy="registration-btn">{{ t("LOGIN_DIALOG.REGISTRATION") }}</a>
    </div>
  </mat-dialog-content>
</ng-container>
