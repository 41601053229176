import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Manufacturers } from '../../model/manufacturers.model';

@Component({
  selector: 'raf-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnersComponent {

  @Input()
  data?: Manufacturers;
}
