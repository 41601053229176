import { APP_INITIALIZER } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export const iconProvider = {
  provide: APP_INITIALIZER,
  useFactory: (iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) => {
    return () => {
      const path = 'assets/svg-icons.svg';
      iconRegistry.addSvgIconSetInNamespace('main', sanitizer.bypassSecurityTrustResourceUrl(path));
    };
  },
  multi: true,
  deps: [MatIconRegistry, DomSanitizer],
};
