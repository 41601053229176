import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductCategory } from '../../../model/product-category.model';
import { GtmService } from '../../../../core/services/gtm.service';

@Component({
  selector: 'raf-catalog-navigation-menu',
  templateUrl: './catalog-navigation-menu.component.html',
  styleUrls: ['./catalog-navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogNavigationMenuComponent {
  @Input()
  categories?: ProductCategory[];

  activeCategory$: BehaviorSubject<ProductCategory | null> = new BehaviorSubject<ProductCategory | null>(null);

  constructor(private gtm: GtmService) {}

  setActiveCategory(category: ProductCategory): void {
    this.activeCategory$.next(category);
  }

  resetActiveCategory(): void {
    this.activeCategory$.next(null);
  }

  onNav(category: ProductCategory) {
    this.logGtm(category);
    this.resetActiveCategory();
  }

  private logGtm(item: ProductCategory): void {
    this.gtm.selectContent('header', item.name);
  }
}
