import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ProductStock, Stock } from '../../model/stock.model';
import { ProductService } from '../../../core/services/product.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutService } from '../../../core/services/layout.service';
import { TextColor } from '../text/text.component';
import { DIALOG_SIZE } from '../../const/dialog-sizes.const';
import { DialogService } from '../../../core/services/dialog.service';
import { SiteStockDialogComponent } from '../site-stock-dialog/site-stock-dialog.component';

@Component({
  selector: 'raf-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class StockComponent implements OnChanges {
  @Input()
  stock?: ProductStock;
  totalSiteCount = 0;

  @Input()
  productId?: string;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  stockDetail$: BehaviorSubject<ProductStock | null> = new BehaviorSubject<ProductStock | null>(null);
  mobile$: Observable<boolean>;

  constructor(private productService: ProductService,
              private layoutService: LayoutService,
              public dialogService: DialogService) {
    this.mobile$ = this.layoutService.mdDown$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['stock']) {
      this.totalSiteCount = this.stock?.siteTotalCount || 0;
    }
  }

  openStockDetail(): void {
    this.dialogService.open(SiteStockDialogComponent, {
      panelClass: 'mat-dialog',
      maxWidth: DIALOG_SIZE.medium,
      data: this.productId
    });
  }

  siteStockColor(site: Stock): TextColor {
    if (site.stockLevel > 0) {
      return 'success-color';
    } else if (site.stockLevel <= 0 && this.stock?.status === "ON_ORDER") {
      return 'grey-text';
    }

    return 'warn-color';
  }

  get stockTextKey(): string {
    return 'PRODUCT.STOCK.' + (this.stock?.status || 'OUT_OF_STOCK');
  }

  get stockColor(): TextColor {
    return this.inStockEverywhere || this.inStockSomewhere ? 'success-color' : 'grey-text';
  }

  get inStockEverywhere(): boolean {
    const inStockTotalCount = (this.stock?.inStockTotalCount || 0);
    return inStockTotalCount > 0 && this.stock?.inStockTotalCount === this.totalSiteCount;
  }

  get inStockSomewhere(): boolean {
    const inStockTotalCount = (this.stock?.inStockTotalCount || 0);
    return inStockTotalCount > 0 && inStockTotalCount < (this.totalSiteCount || 0);
  }

  get outOfStock(): boolean {
    return !this.stock || (this.stock?.status !== "ON_ORDER" && this.stock?.inStockTotalCount === 0);
  }

  get onOrder(): boolean {
    return this.stock?.status === "ON_ORDER" && this.stock?.inStockTotalCount === 0;
  }
}
