import { animate, state, style, transition, trigger } from '@angular/animations';

export const opacityAnimations = trigger('opacityState', [
  state('void', style({ opacity: '0' })),
  state('enter', style({ opacity: '1' })),
  state('leave', style({ opacity: '0' })),
  transition('* => *', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const opacityLoadingAnimations = trigger('opacityLoadingState', [
  state('void', style({ opacity: '0' })),
  state('enter', style({ opacity: '1' })),
  state('leave', style({ opacity: '0' })),
  transition('* => *', animate('300ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const triggerShowHeightState = trigger('autoHeightState', [
  transition('void => *', [
    style({
      height: '0px',
      minHeight: '0',
      overflow: 'hidden',
    }),
    animate(
      '250ms ease-in',
      style({
        height: '*',
      })
    ),
  ]),
  transition('* => void', [
    style({
      height: '*',
      overflow: 'hidden',
    }),
    animate(
      '250ms ease-out',
      style({
        height: '0',
        overflow: 'hidden',
      })
    ),
  ]),
]);

export const triggerAnimationHighlightState = trigger('animationHighlight', [
  state('*', style({ transform: 'scale(1)' })),
  transition('* => *', [
    animate(
      '200ms cubic-bezier(0.25, 0.8, 0.25, 1)',
      style({
        transform: 'scale(1.2)',
      })
    ),
    animate('200ms cubic-bezier(0.25, 0.8, 0.25, 1)'),
  ]),
]);

export const animationRotate = trigger('rotate180', [
  state('false', style({ transform: 'rotate(0deg)' })),
  state('true', style({ transform: 'rotate(180deg)' })),
  transition('0 => 1', [
    animate(
      '150ms cubic-bezier(0.25, 0.8, 0.25, 1)' ),
  ]),
  transition('1 => 0', [
    animate(
      '150ms cubic-bezier(0.25, 0.8, 0.25, 1)'
    ),
  ]),
]);

export const subMenuAnimations = trigger('subMenuState', [
  state('void', style({ transform: 'translateY(-10px)', opacity: '0' })),
  state('enter', style({ transform: 'translateY(0)', opacity: '1' })),
  state('leave', style({ transform: 'translateX(-10px)', opacity: '0' })),
  transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const subMenuOverlayAnimations = trigger('subMenuOverlayState', [
  state('void', style({ opacity: '0' })),
  state('enter', style({ opacity: '1' })),
  state('leave', style({ opacity: '0' })),
  transition('* => *', animate('500ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const sideDialogAnimations = trigger('slideDialog', [
  state('void', style({ transform: 'translateX(100%)' })),
  state('enter', style({ transform: 'translateX(0)', 'box-shadow': '6px 0px 30px 4px rgba(0, 0, 0, 0.42)' })),
  state('leave', style({ transform: 'translateX(100%)' })),
  transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const sideDialogFormAnimations = trigger('slideFormState', [
  state('void', style({ transform: 'translateY(100%)' })),
  state('enter', style({ transform: 'translateX(0)' })),
  state('leave', style({ transform: 'translateX(100%)' })),
  transition('* => *', animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
]);

export const triggerSlideState = trigger('slideFromTop', [
  transition('void => *', [
    style({
      transform: 'translateY(-100%)',
    }),
    animate(
      '225ms ease-out',
      style({
        transform: 'translateY(0%)',
      })
    ),
  ]),
  transition('* => void', [
    style({
      transform: 'translateY(0%)',
    }),
    animate(
      '200ms ease-in',
      style({
        transform: 'translateY(-100%)',
      })
    ),
  ]),
]);
