import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'raf-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {

  @Input()
  text = 'GLOBAL.NO_DATA';

  image: SafeUrl = this.sanitizer.bypassSecurityTrustUrl('assets/images/error-motive.svg');

  constructor(private sanitizer: DomSanitizer) {
  }

}
