import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breadcrumb } from '../../shared/model/breadcrumb.model';

const BREADCRUMBS_KEY = makeStateKey<Breadcrumb[]>('breadcrumbs');

@Injectable()
export class BreadcrumbsService {
  private breadcrumbsSubject$: BehaviorSubject<Breadcrumb[]>;

  constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: object) {
    const initialValue = isPlatformBrowser(this.platformId) ? this.transferState.get(BREADCRUMBS_KEY, []) : [];
    this.breadcrumbsSubject$ = new BehaviorSubject<Breadcrumb[]>(initialValue)
  }

  get breadcrumbs$(): Observable<Breadcrumb[]> {
    return this.breadcrumbsSubject$.asObservable();
  }

  /**
   * Sets breadcrumbs
   * @param breadcrumbs breadcrumbs which will be set
   */
  setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.breadcrumbsSubject$.next(breadcrumbs);
    if (isPlatformServer(this.platformId)) {
      this.transferState.set(BREADCRUMBS_KEY, breadcrumbs);
    }
  }

  /**
   * Adds breadcrumb to the last place
   * @param breadcrumb breadcrumb which will be added
   */
  pushBreadcrumb(breadcrumb: Breadcrumb): void {
    this.breadcrumbsSubject$.next(this.breadcrumbsSubject$.value.concat(breadcrumb));
  }

  /**
   * Replaces last breadcrumb
   * @param breadcrumb breadcrumb which will replace the last one
   */
  replaceLastBreadcrumb(breadcrumb: Breadcrumb): void {
    const newBreadcrumbs = [...this.breadcrumbsSubject$.value];
    newBreadcrumbs[newBreadcrumbs.length - 1] = breadcrumb;

    this.breadcrumbsSubject$.next(newBreadcrumbs);
  }
}
