import { Component, Input } from '@angular/core';

@Component({
  selector: 'raf-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent {
  @Input()
  size: 'large' | 'medium' | 'small' | 'form' = 'large';
}
