<ng-container *transloco="let t">
  <div
    class="product-card"
    [class.no-padding]="noPadding"
    [class.product-card-small]="size === 'small'"
    [class.product-card-center]="contentAlign === 'center'"
  >
    <!-- LABELS -->
     <div class="product-labels stack stack-vertical stack-4" *ngIf="product?.labels">
       <raf-product-label *ngFor="let label of product?.labels" [label]="label"></raf-product-label>
     </div>

    <div class="product-card_image">
      <a class="link" [routerLink]="product?.productUrl">
        <raf-image [fixedContainerSize]="false" data-cy="image" [withBorder]="true" class="product-card_image_component"
                   [fromB2c]="product?.image" targetQuality="preview" [alternativeText]="product?.name" (click)="onClicked($event)"></raf-image>
      </a>
    </div>


    <div class="product-card_content">

      <div class="product-card_title" *ngIf="nameVisible">
        <a class="link" [routerLink]="product?.productUrl" data-cy="product-name-link">
          <raf-text data-cy="heading" variant="h4" color="primary-color" (click)="onClicked($event)">{{ product?.name }}</raf-text>
        </a>
      </div>

      <div class="stack stock-price-inline">
        <div *ngIf="stockVisible && product?.stock">
          <raf-stock data-cy="stock" [stock]="product?.stock" [productId]="product?.code"></raf-stock>
        </div>

        <div class="product-card_price" *ngIf="priceVisible">
          <raf-price [productPrices]="product?.prices"
                     [unitPriceVisible]="unitPriceVisible"
                     [unit]="product?.baseUnit?.symbol"
                      [showTooltip]="true"
                     data-cy="price"></raf-price>
        </div>
      </div>

    </div>

    <div class="product-card_action" *ngIf="actionVisible">
      <raf-quantity data-cy="quantity"
                    [size]="size"
                    [moq]="product?.moq || 1"
                    [debounce]="0"
                    [unit]="product?.sellUnit"
                    (valueChange)="onQuantityChange($event)"></raf-quantity>

      <button
        data-cy="add-to-cart-btn-small"
        *ngIf="size === 'normal'"
        class="mat-button-small product-buy-button"
        mat-flat-button
        color="primary"
        [rafLoading]="(addingToCart$ | async) === true"
        (click)="onAddToCart()"
      >
        {{ t("PRODUCT.ADD_TO_CART") }}
      </button>

      <button
        data-cy="add-to-cart-btn-extra-small"
        *ngIf="size === 'small'"
        class="mat-button-extra-small product-buy-button"
        mat-flat-button
        color="primary"
        (click)="onAddToCart()"
      >
        {{ t("PRODUCT.ADD_TO_CART_SHORT") }}
      </button>
    </div>
  </div>
</ng-container>
