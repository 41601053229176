import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ErrorVariant } from '../../model/error-variant.model';
import { map, Observable } from 'rxjs';
import { Layout } from '../../model/layout.model';
import { CmsService } from '../../../core/services/cms.service';
import { ProductCategory } from '../../model/product-category.model';
import { CatalogService } from '../../../core/services/catalog.service';

interface ErrorMap {
  [key: number]: string;
}

@Component({
  selector: 'raf-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnChanges {
  @Input()
  variant: ErrorVariant = 404;

  @Input()
  fullPage = false;

  titles: ErrorMap = {
    404: 'ERROR.NOT_FOUND.TITLE',
    500: 'ERROR.SERVER_ERROR.TITLE',
    400: 'ERROR.DEFAULT.TITLE',
  };

  subtitles: ErrorMap = {
    404: 'ERROR.NOT_FOUND.SUBTITLE',
    500: 'ERROR.SERVER_ERROR.SUBTITLE',
    400: 'ERROR.DEFAULT.SUBTITLE',
  };

  layout$: Observable<Layout | null> | null = null;
  productCategoriesLoading$: Observable<boolean> | null = null;
  productCategories$: Observable<ProductCategory[] | null> | null = null;

  constructor(private cmsService: CmsService, private catalogService: CatalogService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['fullPage']?.currentValue) {
      this.layout$ = this.cmsService.layout$.pipe(map((resource) => resource.data));
      this.productCategoriesLoading$ = this.catalogService.categories$.pipe(map((resource) => resource.loading));
      this.productCategories$ = this.catalogService.categories$.pipe(map((resource) => resource.data));
    }
  }
}
