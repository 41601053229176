
  <div class="loading-block" [class.loading-position-sticky]="position === 'sticky'" [class.loading-align-center]="align === 'center'" [class.loading-block-light]="color === 'light'" [class.loading-small]="size === 'small'" [class.loading-inline]="variant === 'inline'">
    <div class="loading-wrap">
      <div class="loading">
        <span class="square"></span>
        <span class="square"></span>
        <span class="square"></span>
        <span class="square"></span>
      </div>
    </div>
  </div>

