<ng-container *transloco="let t">

  <div class="no-data-block">

    <div class="no-data-block-image">
      <img [src]="image" alt="image">
    </div>

    <raf-text data-cy="no-data-text" *ngIf="text" class="no-data-block-title" variant="h3"
              color="primary-color">{{t(text)}}</raf-text>

  </div>

</ng-container>
