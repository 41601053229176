import { Injectable } from '@angular/core';
import {
  HttpContextToken,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { EMPTY, mergeMap, Observable, take, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertService } from '../services/alert.service';
import { ServerStatusService } from '../services/server-status.service';
import { environment } from '../../../environments/environment';

export const SKIP_HTTP_ERROR_ALERT = new HttpContextToken<boolean>(() => false);

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService,
              private serverStatusService: ServerStatusService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (this.isServerApiRequest(req)) {
      return this.serverStatusService.serverError$.pipe(
        take(1),
        mergeMap(serverError => {
          if (serverError) {
            return EMPTY; // kill b2c or cms api requests if either of those are unavailable
          }

          return next.handle(req).pipe(
            // error catching
            catchError((error: HttpErrorResponse) => {
              // 503 redirect
              this.handleServerUnavailable(req, error);
              // alerts
              if (!this.shouldSkipErrorHandler(req)) {
                this.handleError(error);
              }
              return throwError(() => error);
            })
          );
        })
      );
    }

    return next.handle(req).pipe(
      // error catching
      catchError((error: HttpErrorResponse) => {
        this.handleServerUnavailable(req, error);
        if (!this.shouldSkipErrorHandler(req)) {
          this.handleError(error);
        }
        return throwError(() => error);
      })
    );
  }

  private handleError(error: HttpErrorResponse): void {
    this.alertService.parseErrorsFromResponse(error);
  }

  private handleServerUnavailable(req: HttpRequest<unknown>, error: HttpErrorResponse): void {
    if (error.status === 503) {
      if (req.url.includes(environment.b2cEndpoint)) {
        this.serverStatusService.setB2xServerError(true);
      }
      if (req.url.includes(environment.cmsEndpoint)) {
        this.serverStatusService.setCmsServerError(true);
      }
    }
  }

  private isServerApiRequest(req: HttpRequest<unknown>): boolean {
    return req.url.includes(environment.b2cEndpoint) || req.url.includes(environment.cmsEndpoint);
  }

  private shouldSkipErrorHandler(req: HttpRequest<unknown>): boolean {
    return req.context.get(SKIP_HTTP_ERROR_ALERT);
  }
}
