import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { AppConfig } from '../../shared/model/app-config.model';
import { Logger } from './logger.service';

@Injectable()
export class AppConfigService {
  private appConfig: AppConfig | null = null;

  constructor(private http: HttpClient, private logger: Logger) {}

  async loadAppConfig(): Promise<AppConfig> {
    // this.logger.log('app config starting');
    this.appConfig = await firstValueFrom(this.http.get<AppConfig>('assets/configs/app-config.json'));
    // this.logger.log('app config loaded');
    return this.appConfig;
  }

  getConfig(): AppConfig | null {
    return this.appConfig;
  }
}
