<ng-container *transloco="let t">
  <div class="footer" [class.cart-layout]="cartLayout" [class.mobile-layout]="mobile$ | async">
    <raf-container>
      <ng-content></ng-content>

      <div class="row justify-content-between">
        <ng-container *ngFor="let navGroup of navGroups; let first = first; let last = last">

          <mat-divider *ngIf="!first" class="mat-divider-light d-none d-md-block" [vertical]="true"></mat-divider>
          <div class="col-12 col-md-auto" [ngClass]="{'d-none d-md-block' : last}" data-cy="footer-nav-group">
            <div class="categories-container">


              <div class="stack stack-vertical" *ngIf="expandableNavigation && (mobile$ | async)">
                <button (click)="toggleNavGroup(navGroup.id)"
                        class="mat-button-extra-small arrow-down edge-left edge-right full-width-icon-button"
                        mat-button
                        type="button"
                >
                  <raf-text variant="h4" color="inherit">{{ navGroup.title }}</raf-text>
                  <raf-icon [@rotate180]="navGroupContentVisible[navGroup.id] | async" class="end-icon"
                            icon="arrow-down" size="12px"></raf-icon>
                </button>
              </div>

              <raf-text variant="h4" color="inherit"
                        *ngIf="!expandableNavigation || (mobile$ | async) === false">{{ navGroup.title }}
              </raf-text>
              <div @autoHeightState *ngIf="!expandableNavigation || (navGroupContentVisible[navGroup.id] | async)">
                <ul class="category-list category-list-col-2" [class.myclass]="mobile$ | async">
                  <li *ngFor="let node of navGroup.nodes"><a [routerLink]="node.url" (click)="onNav(node)"
                                                             data-cy="footer-nav-link">{{ node.text }}</a></li>
                </ul>
              </div>
            </div>
          </div>

        </ng-container>
      </div>

      <div class="row justify-content-between justify-content-tablet footer-bottom-info">
        <div class="col-12 col-md-auto d-flex flex-column justify-content-center">
          <raf-conditional-link [url]="logo?.url">
            <raf-image data-cy="footer-logo" [fromCms]="logo?.image"></raf-image>
          </raf-conditional-link>

          <raf-conditional-link [url]="logo?.url">
            <div class="footer-address" *ngIf="address" data-cy="footer-address">
              <raf-text variant="body-large" color="inherit">{{ address.street }} {{ address.number }}</raf-text>
              <raf-text variant="body-large" color="inherit">{{ address.zip }} {{ address.city }}</raf-text>
              <raf-text variant="body-large" color="inherit">{{ t('GLOBAL.TAX_ID') }}: {{ address.taxId }}</raf-text>
            </div>
          </raf-conditional-link>

          <div class="footer-social-links" data-cy="footer-socials">
            <a mat-fab color="white" *ngIf="socials?.facebook as link" title="Facebook" [href]="link" target="_blank">
              <raf-icon icon="social-facebook"></raf-icon>
            </a>
            <a mat-fab color="white" *ngIf="socials?.instagram as link" title="Instagram" [href]="link" target="_blank">
              <raf-icon icon="social-instagram"></raf-icon>
            </a>
            <!--<a mat-fab color="white" *ngIf="socials?.linkedIn as link" [href]="link">
              <raf-icon icon="social-linkedIn"></raf-icon>
            </a>
            <a mat-fab color="white" *ngIf="socials?.pinterest as link" [href]="link">
              <raf-icon icon="social-pinterest"></raf-icon>
            </a>
            <a mat-fab color="white" *ngIf="socials?.twitter as link" [href]="link">
              <raf-icon icon="social-twitter"></raf-icon>
            </a>-->
            <a mat-fab color="white" *ngIf="socials?.youtube as link" title="YouTube" [href]="link" target="_blank">
              <raf-icon icon="social-youtube"></raf-icon>
            </a>
          </div>

        </div>

        <ng-container *ngIf="!cartLayout || (mobile$ | async) === false">
          <div class="footer-lowest-images col-12 col-md-auto d-flex flex-column justify-content-center"
               *ngFor="let imgLink of images">
            <raf-conditional-link [url]="getUrl(imgLink)">
              <raf-image data-cy="footer-image" [fromCms]="getImage(imgLink)"></raf-image>
            </raf-conditional-link>
          </div>
        </ng-container>


      </div>
    </raf-container>
  </div>
</ng-container>



