import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Product, ProductDetail } from '../../shared/model/product.model';
import { Cart, CartEntry } from '../../shared/model/cart.model';
import { Order } from '../../shared/model/order.model';

interface WindowWithDataLayer extends Window {
  dataLayer: unknown[];
}

@Injectable()
export class GtmService {
  private window: WindowWithDataLayer;

  constructor(@Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: object) {
    this.window = (isPlatformBrowser(this.platformId)
      ? this.document.defaultView
      : { dataLayer: [] }) as unknown as WindowWithDataLayer;
  }

  private logEvent(eventName: string, payload?: object, consents?: boolean) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const dataLayer = this.window.dataLayer || [];
    payload = payload ?? {};
    if (consents) {
      dataLayer.push(
        [
          eventName,
          'default',
          payload,
        ]
      );
    } else {
      dataLayer.push({
        event: eventName,
        ...payload,
      });
    }
  }

  private clearEcommerceObject() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const dataLayer = this.window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
  }

  /* CART RELATED */

  addToCart(items: { product: Product; quantity: number }[]): void {
    this.clearEcommerceObject();
    this.logEvent('add_to_cart', {
      ecommerce: {
        currency: items[0].product.prices.price?.currency?.code || 'CZK',
        value: items.reduce((acc, item) => acc + (item.product.prices.price?.value || 0) * item.quantity, 0),
        items: items.map((item) => ({
          item_id: item.product.code,
          item_name: item.product.name,
          quantity: item.quantity,
          price: item.product.prices.price?.value || 0,
        })),
      },
    });
  }

  removeFromCart(entry: CartEntry, quantity?: number): void {
    const pricePerItem = entry.product.prices.price?.value || 0;
    this.clearEcommerceObject();
    this.logEvent('remove_from_cart', {
      ecommerce: {
        currency: entry.priceCalculation?.price?.currency?.code || 'CZK',
        value: quantity ? quantity * pricePerItem : entry.priceCalculation?.price?.value || 0,
        items: [
          {
            item_id: entry.product.code,
            item_name: entry.product.name,
            quantity: quantity || entry.quantity,
            price: pricePerItem,
          },
        ],
      },
    });
  }

  updateQuantity(entry: CartEntry, quantity: number, cart: Cart): void {
    const oldQuantity = cart.entries.find((item) => item.entryNumber === entry.entryNumber)?.quantity || 0;
    const diff = oldQuantity - quantity;
    if (diff > 0) {
      // user decreased the quantity
      this.removeFromCart(entry, diff);
    } else if (diff === 0) {
      // user removed everything
      this.removeFromCart(entry);
    } else {
      this.addToCart([{ product: entry.product, quantity: -diff }]);
    }
  }

  viewCart(cart: Cart): void {
    this.clearEcommerceObject();
    this.logEvent('view_cart', {
      ecommerce: {
        currency: cart.priceCalculation?.price?.currency?.code || 'CZK',
        value: cart.priceCalculation?.price?.value || 0,
        items: cart.entries?.map((entry) => ({
          item_id: entry.product.code,
          item_name: entry.product.name,
          quantity: entry.quantity,
          price: entry.product.prices?.price?.value || 0,
        })),
      },
    });
  }

  beginCheckout(cart: Cart): void {
    this.clearEcommerceObject();
    this.logEvent('begin_checkout', {
      ecommerce: {
        currency: cart.priceCalculation?.price?.currency?.code || 'CZK',
        value: cart.priceCalculation?.price?.value || 0,
        items: cart.entries?.map((entry) => ({
          item_id: entry.product.code,
          item_name: entry.product.name,
          quantity: entry.quantity,
          price: entry.product.prices?.price?.value || 0,
        })),
      },
    });
  }

  purchase(order: Order): void {
    this.clearEcommerceObject();
    this.logEvent('purchase', {
      ecommerce: {
        transaction_id: order.id,
        currency: order.priceCalculation?.price?.currency?.code || 'CZK',
        value: order.priceCalculation?.price?.value || 0,
        items: order.entries?.map((entry) => ({
          item_id: entry.product?.code || '',
          item_name: entry.product?.name || '',
          quantity: entry.quantity,
          price: entry.product?.prices?.price?.value || 0,
        })),
        shipping: order.priceCalculation?.deliveryPrice?.value || 0,
        customer_email: order.invoiceContact?.email || "",
        name: `${order.invoiceContact?.lastName || ""} ${order.invoiceContact?.firstName || ""}`
      },
    });
  }

  /* USER RELATED */

  login(): void {
    this.logEvent('login');
  }

  signUp(): void {
    this.logEvent('sign_up');
  }

  /* SEARCH */

  search(query: string): void {
    this.logEvent('search', {
      search_term: query,
    });
  }

  /* PRODUCTS */

  viewItem(product: ProductDetail): void {
    const item: { [key: string]: string | number | undefined } = {
      item_id: product.code,
      item_name: product.name,
      item_brand: product.brand,
      price: product.prices.oldPrice?.value || undefined,
      currency: product.prices.oldPrice?.currency?.code || 'CZK',
    };

    if (product.categories?.length) {
      product.categories.forEach((category, index) => {
        if (index === 0) {
          item['item_category'] = category.name;
        } else {
          item['item_category' + (index + 1)] = category.name;
        }
      });
    }

    this.clearEcommerceObject();
    this.logEvent('view_item', {
      ecommerce: {
        currency: product.prices.price?.currency?.code || 'CZK',
        value: product.prices.price?.value || 0,
        items: [item],
      },
    });
  }

  viewItemList(itemListId: string, itemListName: string, items: Product[]): void {
    this.clearEcommerceObject();
    this.logEvent('view_item_list', {
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        items: items.map((item, index) => ({
          item_id: item.code,
          item_name: item.name,
          index,
        })),
      },
    });
  }

  selectItem(itemListId: string, itemListName: string, item: Product, index: number): void {
    this.clearEcommerceObject();
    this.logEvent('select_item', {
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        items: [
          {
            item_id: item.code,
            item_name: item.name,
            index,
          },
        ],
      },
    });
  }

  /* COMPARATOR */

  addToComparator(product: Product): void {
    this.clearEcommerceObject();
    this.logEvent('add_to_comparator', {
      ecommerce: {
        items: [
          {
            item_id: product.code,
            item_name: product.name,
            price: product.prices?.price?.value || 0,
          },
        ],
      },
    });
  }

  /* LINKS */

  selectContent(contentType: 'header' | 'footer', itemId: string): void {
    this.logEvent('select_content', {
      content_type: contentType,
      itemId,
    });
  }

  initConsents() {
    this.logEvent('consent', {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "denied",
        ad_personalization: "denied",
        ad_user_data: "denied",
        wait_for_update: 1000
      }, true
    );
  }
}
