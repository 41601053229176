import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { NavigationNode } from '../../../model/navigation-node.model';
import { GtmService } from '../../../../core/services/gtm.service';

@Component({
  selector: 'raf-menu-strip',
  templateUrl: './menu-strip.component.html',
  styleUrls: ['./menu-strip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuStripComponent {
  @Input()
  menuItems?: NavigationNode[] | null;

  @Input()
  anonymous: boolean | null = true;

  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  constructor(private gtm: GtmService) {}

  onLoginClick(): void {
    this.login.emit();
  }

  onLogoutClick(): void {
    this.logout.emit();
  }

  onNav(item: NavigationNode) {
    this.logGtm(item);
  }

  private logGtm(item: NavigationNode): void {
    this.gtm.selectContent('header', item.text || '');
  }
}
