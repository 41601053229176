<ng-container *transloco="let t">

  <div class="label" *ngIf="label"
       [class.label-sale]="label.type === 'SALE'"
       [class.label-action]="label.type === 'ACTION'"
       [class.label-discount]="label.type === 'DISCOUNT'"
       [class.label-recommended]="label.type === 'RECOMMENDED'"
       [class.label-bestseller]="label.type === 'BEST_SELLER'"
       [ngStyle]="allowCustomColor ? colorStyle : null">
    {{label.title}}
    <div class="discount-title" *ngIf="label?.type === 'DISCOUNT'">
      {{ t('PRODUCT_DETAIL.LABELS.DISCOUNT_DESC') }}
    </div>
  </div>
</ng-container>
