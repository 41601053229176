<ng-container>
  <ng-container *transloco="let t">
    <mat-form-field [class.fullwidth]="fullWidth" [class.no-padding]="noPadding" appearance="fill" [class.mat-form-field-medium]="size === 'medium'">
      <mat-label *ngIf="label">{{ t(label) }}</mat-label>
      <raf-password-strength-indicator *ngIf="type === 'password' && !hidePasswordStrengthIndicator"
                                       [password]="control.value"></raf-password-strength-indicator>
      <input matInput (focusin)="setFocus(true)" (focusout)="setFocus(false)" [autocomplete]="autocomplete"
             [type]="type" [placeholder]="placeholder ? t(placeholder) : ''" [formControl]="control" [name]="name" />
      <raf-text *ngIf="suffixText" matSuffix color="grey-text">{{t(suffixText)}}</raf-text>
      <button *ngIf="suffixIcon" (click)="onSuffixClicked()" matSuffix mat-icon-button [title]="suffixTitle">
        <raf-icon [icon]="suffixIcon"></raf-icon>
      </button>
      <mat-hint *ngIf="hint">{{ t(hint) }}</mat-hint>
      <mat-error>
        <raf-form-field-error [control]="control" [includeGroupErr]="includeGroupErr"></raf-form-field-error>
      </mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>

