import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'raf-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  @Input()
  variant: 'inline' | 'full-page' = 'full-page';

  @Input()
  position: 'default' | 'sticky' = 'default';

  @Input()
  align: 'left' | 'center' | 'right' = 'center';

  @Input()
  size: 'small' | 'normal' = 'normal';

  @Input()
  color: 'white' | 'light' = 'white';
}
