import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
  }

  transform(value: string | number | undefined | null, decimals: number = 2): string {
    return this.formatValue(value, decimals);
  }

  formatValue(value: string | number | undefined | null, decimals: number) {
    value = value ?? 0;

    if (typeof value === 'string') {
      value = value.replace(',', '.');
      value = parseFloat(value);
    }

    return value.toLocaleString(this.locale, {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
  }
}
