import {
  ControlContainer,
  FormArrayName,
  UntypedFormGroup,
  FormGroupDirective,
  FormGroupName,
  AbstractControl,
  FormGroup,
} from '@angular/forms';

export class FormUtils {
  static extractFormGroup(
    parentContainer: ControlContainer,
    formGroupName?: string
  ): { formGroup: UntypedFormGroup | null; formGroupDirective: FormGroupDirective | null } {
    let formGroup;
    let formGroupDirective;

    if (parentContainer instanceof FormGroupDirective) {
      formGroup = parentContainer.form;
      formGroupDirective = parentContainer;
    } else if (parentContainer instanceof FormArrayName) {
      formGroupDirective = parentContainer.formDirective;
      formGroup = parentContainer.formDirective?.form;
    } else if (parentContainer instanceof FormGroupName) {
      formGroup = parentContainer.control;
      formGroupDirective = parentContainer.formDirective as FormGroupDirective;
    } else if (formGroupName) {
      formGroup = ((parentContainer as FormGroupName).formDirective as FormGroupDirective).form.get(
        formGroupName
      ) as UntypedFormGroup;
      formGroupDirective = (parentContainer as FormGroupName).formDirective as FormGroupDirective;
    }

    return { formGroup: formGroup || null, formGroupDirective: formGroupDirective || null };
  }

  static focusInvalidInput(selector?: string) {
    if (!selector) {
      selector = 'form .mat-form-field.ng-invalid';
    }

    const invalidInput = document.querySelector(selector);
    if (invalidInput) {
      invalidInput.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }

  static getInvalidControls(formGroup: FormGroup): { name: string; control: AbstractControl }[] {
    return Object.keys(formGroup.controls)
      .map((key) => {
        return { name: key, control: formGroup.controls[key] };
      })
      .filter((control) => control.control.invalid);
  }

  /**
   * Enables or disables form controls
   * @param form
   * @param controlNames
   * @param enable
   */
  static toggleControls(form: FormGroup, controlNames: string | string[], enable = true) {
    if (Array.isArray(controlNames)) {
      controlNames.forEach((controlName) => {
        const control = form.get(controlName);
        if (enable) control?.enable({ emitEvent: false });
        else control?.disable({ emitEvent: false });
      });
    } else {
      const control = form.get(controlNames);
      if (enable) control?.enable({ emitEvent: false });
      else control?.disable({ emitEvent: false });
    }
  }
}
