import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { AlertMessage } from '../../../shared/model/alert-message.model';
import { Destroyed } from '../../../shared/decorators/destroyed';

interface SnackbarData {
  messages$: Observable<AlertMessage[]>;
  closeCallback: (messages: AlertMessage[]) => void;
}

interface MessageGroup {
  type: 'error' | 'warn' | 'success' | 'info' | 'debug';
  messages: AlertMessage[];
  alertClass?: string;
}

@Component({
  selector: 'raf-alert-snack-bar',
  templateUrl: './alert-snack-bar.component.html',
  styleUrls: ['./alert-snack-bar.component.scss'],
})
export class AlertSnackBarComponent {

  messages$: Observable<AlertMessage[]>;
  messageGroups$: Observable<{ [key: string]: MessageGroup }>;
  messageGroupKeys = ['error', 'warn', 'success', 'info', 'debug'];

  @Destroyed
  destroyed$!: Subject<void>;

  constructor(private snackBarRef: MatSnackBarRef<AlertSnackBarComponent>,
              @Inject(MAT_SNACK_BAR_DATA) private data: SnackbarData) {
    this.messages$ = this.data.messages$;
    this.messageGroups$ = this.getMessageGroups();

    // if there are no messages, close the snackbar
    this.messages$.pipe(takeUntil(this.destroyed$)).subscribe(messages => {
      if (!messages.length) {
        this.snackBarRef.dismiss();
      }
    });
  }

  onCloseGroup(messageGroup: MessageGroup): void {
    if (this.data.closeCallback) {
      this.data.closeCallback(messageGroup.messages);
    }
  }

  private getMessageGroups(): Observable<{ [key: string]: MessageGroup }> {
    return this.messages$.pipe(
      map(messages => {
        return messages.reduce((acc, msg) => {
          if (msg?.type) {
            const messageTypeKey: string = msg.type.toLowerCase();
            // add message to correct category by message type
            return {...acc, [messageTypeKey]: {...acc[messageTypeKey], messages: [...acc[messageTypeKey].messages, msg]}};
          }
          return acc;
        }, this.createMessageGroups());
      })
    );
  }

  private createMessageGroups(): { [key: string]: MessageGroup } {
    return {
      error: {
        type: 'error',
        alertClass: 'alertWidget--danger',
        messages: []
      },
      warn: {
        type: 'warn',
        alertClass: 'alertWidget--warning',
        messages: []
      },
      success: {
        type: 'success',
        alertClass: 'alertWidget--success',
        messages: []
      },
      info: {
        type: 'info',
        alertClass: 'alertWidget--info',
        messages: []
      },
      debug: {
        type: 'debug',
        alertClass: 'alertWidget--info',
        messages: []
      },
    };
  }
}
