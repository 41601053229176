import { animate, style, transition, trigger } from '@angular/animations';

export const triggerShowSearchDrop = trigger('showSearchDrop', [
  transition('void => *', [
    style({
      opacity: 0,
      height: '6px',
      minHeight: '0',
      overflow: 'hidden',
    }),
    animate(
      '140ms ease-in',
      style({
        height: '*',
        opacity: 1,
      })
    ),
  ]),
  transition('* => void', [
    style({
      height: '*',
      opacity: 1,
      overflow: 'hidden',
    }),
    animate(
      '140ms ease-out',
      style({
        height: '6px',
        opacity: 0,
        overflow: 'hidden',
      })
    ),
  ]),
]);
