<ng-container *transloco="let t">
  <ng-container *ngFor="let errorKey of errorKeys">
    <mat-error *ngIf="mergedErrors[errorKey]">
      <ng-container
        *ngIf="isKnownError(errorKey) && customMessages[errorKey] as customErrorMessage; else defaultMessage"
      >
        {{ t(customErrorMessage) }}
      </ng-container>
      <ng-template #defaultMessage>{{ t(defaultErrorMessages[errorKey]) }}</ng-template>
    </mat-error>
  </ng-container>

  <ng-container *ngIf="customErrors">
    <ng-container *ngFor="let customError of customErrors">
      <mat-error *ngIf="mergedErrors[customError.errName]">{{ t(customError.errMessage) }}</mat-error>
    </ng-container>
  </ng-container>
</ng-container>
