import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { listAnimation, mobileMenuAnimation } from './animations';
import { NavigationNode } from '../../model/navigation-node.model';
import { ProductCategory } from '../../model/product-category.model';
import { GtmService } from '../../../core/services/gtm.service';

@Component({
  selector: 'raf-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
  animations: [mobileMenuAnimation, listAnimation],
})
export class MobileMenuComponent {
  @Input()
  menuItems?: NavigationNode[] | null;

  @Input()
  categories?: ProductCategory[] | null;
  selectedCategory$: BehaviorSubject<ProductCategory | null> = new BehaviorSubject<ProductCategory | null>(null);

  @Input()
  open?: boolean | null = false;

  @Input()
  anonymous: boolean | null = true;

  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  closed: EventEmitter<void> = new EventEmitter();

  menuOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  animationTrigger$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private gtm: GtmService) {
  }

  closeMenu() {
    this.closed.emit();
  }

  onLoginClick(): void {
    this.login.emit();
  }

  onLogoutClick(): void {
    this.logout.emit();
  }

  onNav(item: ProductCategory | NavigationNode): void {
    this.logGtm(item);
    this.closed.emit();
  }

  onSelectCategory(category: ProductCategory) {
    this.animationTrigger$.next(this.animationTrigger$.value + 1);
    category.parent = this.selectedCategory$.value || undefined;
    this.selectedCategory$.next(category);
  }

  onPrevCategory() {
    this.animationTrigger$.next(this.animationTrigger$.value - 1);
    this.selectedCategory$.next(this.selectedCategory$.value?.parent || null);
  }

  private logGtm(item: ProductCategory | NavigationNode): void {
    const itemId = 'name' in item ? item.name : item.text || '';
    this.gtm.selectContent('header', itemId);
  }
}
