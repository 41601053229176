import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Product } from '../../shared/model/product.model';
import {
  ContinueShoppingConfirmationDialogComponent
} from '../../shared/components/continue-shopping-confirmation-dialog/continue-shopping-confirmation-dialog.component';
import { DIALOG_SIZE } from '../../shared/const/dialog-sizes.const';
import { DialogService } from './dialog.service';
import { Router } from '@angular/router';
import { GtmService } from './gtm.service';


@Injectable()
export class ComparatorService {
  private readonly COMPARED_PRODUCTS_KEY = 'x-raf-web-ui-compared-products';
  private productsSubject$: BehaviorSubject<string[]>;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private dialog: DialogService,
              private router: Router,
              private gtm: GtmService) {
    this.productsSubject$ = new BehaviorSubject<string[]>(this.getFromLocalStorage());
  }

  get productIds$(): Observable<string[]> {
    return this.productsSubject$.asObservable();
  }

  get count$(): Observable<number> {
    return this.productsSubject$.pipe(map(val => val.length));
  }

  add(product: Product): void {
    if (this.getFromLocalStorage().some(item => item === product.code)) {
      this.openConfirmationDialog([{product, quantity: 0}]);
      return;
    }
    this.logGtm(product);
    this.save([...this.getFromLocalStorage(), product.code]);
    this.openConfirmationDialog([{product, quantity: 1}]);
  }

  remove(productId: string): void {
    this.save(this.getFromLocalStorage().filter(val => val !== productId));
  }

  removeAll(): void {
   this.save([]);
  }

  private save(productIds: string[]): void {
    this.saveToLocalStorage(productIds);
    this.productsSubject$.next(productIds);
  }

  private saveToLocalStorage(productIds: string[]): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    localStorage.setItem(this.COMPARED_PRODUCTS_KEY, JSON.stringify(productIds));
  }

  private getFromLocalStorage(): string[] {
    if (isPlatformServer(this.platformId)) {
      return [];
    }

    const val = localStorage.getItem(this.COMPARED_PRODUCTS_KEY);
    return val ? JSON.parse(val) : [];
  }

  private openConfirmationDialog(items: { product: Product; quantity: number }[]): void {
    const dialogRef = this.dialog.open(ContinueShoppingConfirmationDialogComponent, {
      maxWidth: DIALOG_SIZE.small,
      data: { items, variant: 'comparator' },
    });
    dialogRef.afterClosed().subscribe((navRoute) => {
      if (navRoute) {
        this.router.navigate([navRoute]);
      }
    });
  }

  private logGtm(product: Product): void {
    this.gtm.addToComparator(product);
  }
}
