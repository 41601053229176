import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProductService } from '../../../core/services/product.service';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../../model/product.model';
import { CartEntry } from '../../model/cart.model';

interface DialogData {
  variant: 'cart' | 'comparator';
  items: { product: Product | string; quantity: number }[]
}

interface DialogVariant {
  title: string;
  navText: string;
  navPath: string;
  showQuantity: boolean;
}

@Component({
  selector: 'raf-continue-shopping-confirmation-dialog',
  templateUrl: './continue-shopping-confirmation-dialog.component.html',
  styleUrls: ['./continue-shopping-confirmation-dialog.component.scss'],
})
export class ContinueShoppingConfirmationDialogComponent {
  products$: BehaviorSubject<CartEntry[] | null> = new BehaviorSubject<CartEntry[] | null>(null);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  variants = {
    cart: {
      title: 'CONTINUE_SHOPPING_DIALOG.CART.TITLE',
      navText: 'CONTINUE_SHOPPING_DIALOG.CART.NAV',
      navPath: '/kosik',
      showQuantity: true
    },
    comparator: {
      title: 'CONTINUE_SHOPPING_DIALOG.COMPARATOR.TITLE',
      navText: 'CONTINUE_SHOPPING_DIALOG.COMPARATOR.NAV',
      navPath: '/porovnavac',
      showQuantity: false
    }
  };

  variant$: BehaviorSubject<DialogVariant> = new BehaviorSubject<DialogVariant>(this.variants.cart);

  constructor(
    private dialogRef: MatDialogRef<ContinueShoppingConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private productService: ProductService
  ) {
    if (data?.items?.length) {
      if (typeof data.items[0].product === 'string') {
        this.getProductDetails( data.items as { product: string; quantity: number }[]);
      } else {
        this.products$.next( data.items.map(item => ({ product: item.product as Product, quantity: item.quantity })));
        this.loading$.next(false);
      }
    }

    if (data.variant) {
      this.variant$.next(this.variants[data.variant]);
    }
  }

  /** Search product by its ID, not great but does the job */
  getProductDetails(products: {product: string; quantity: number;}[]): void {
    this.productService
      .getProducts({ product: products.map(item => item.product) })
      .subscribe((searchResult) => {
        if (searchResult.items?.length) {
          const entries = searchResult.items.map(item => {
            const quantity = products.find(srcItem => srcItem.product === item.code)?.quantity || 1;
            return {product: item, quantity};
          });
          this.products$.next(entries);
        }
      })
      .add(() => this.loading$.next(false));
  }

  close(navRoute?: string) {
    this.dialogRef.close(navRoute || null);
  }

  onNav($event: {product: Product | CartEntry; index: number}) {
    if ('productUrl' in $event.product) {
      this.close($event.product.productUrl);
    } else if ('product' in $event.product && 'productUrl' in $event.product.product) {
      this.close($event.product.product.productUrl);
    }
  }
}
