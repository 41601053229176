<ng-container *transloco="let t">
  <div class="header">
    <raf-container [size]="size">
      <div class="header-container">
        <div class="header-top">
          <div class="header-top-left">
            <button *ngIf="tabletLayout && (categories || menuItems)" (click)="toggleMenu()" class="mobile-menu" color="secondary" mat-icon-button>
              <raf-icon icon="hamburger-menu" size="16px" color="primary"></raf-icon>
              <span class="mobile-menu-text">MENU</span>
            </button>

            <!-- LOGO -->
            <a class="logo" href="/">
              <raf-image *ngIf="logo" [fromCms]="logo" data-cy="logo"></raf-image>
            </a>

            <!-- HEADER IMAGE -->
            <div *ngIf="imageLink.image && (headerImageVisible$ | async)">
              <raf-conditional-link [url]="imageLink.url">
                <raf-image class="header-promo-text" [fromCms]="imageLink.image" data-cy="promo-img"></raf-image>
              </raf-conditional-link>
            </div>
          </div>

          <div class="header-top-right">
            <raf-heureka-widget></raf-heureka-widget>

            <!-- SEARCH -->
            <ng-container *ngIf="!tabletLayout">
              <ng-container *ngTemplateOutlet="search"></ng-container>
            </ng-container>

            <!-- ICON BUTTONS -->
            <div class="header-actions stack stack-8">
              <a mat-icon-button color="secondary" data-cy="comparator-button" routerLink="/porovnavac" [title]="t('COMPARATOR.HEADING')">
                <raf-icon
                  icon="compare"
                  size="32px"
                  [matBadgeHidden]="(comparatorCountVisible$ | async) === false"
                  [matBadge]="comparatorCount$ | async"
                  matBadgePosition="below after"
                  matBadgeColor="accent"
                ></raf-icon>
              </a>

              <div class="icon-button-helper">
                <button
                  *ngIf="anonymous"
                  mat-icon-button
                  color="secondary"
                  @toggleShowBtn
                  (click)="onLoginClick()"
                  data-cy="user-button-anon"
                  [title]="t('LAYOUT.LOGIN')"
                >
                  <raf-icon icon="user" size="32px"></raf-icon>
                </button>

                <a
                  *ngIf="!anonymous"
                  class="logged-button"
                  @toggleShowBtn
                  routerLink="/muj-ucet"
                  mat-icon-button
                  data-cy="user-button-logged"
                  [title]="t('LAYOUT.PROFILE')"
                >
                  {{ user?.name | initials }}
                </a>
              </div>
              <!--<button mat-icon-button color="secondary"  >
                <raf-icon icon="hearth" size="32px"></raf-icon>
              </button>-->

              <!-- cart - desktop variant with hover -->
              <button
                *ngIf="(mobile$ | async) === false && (cartCountVisible$ | async)"
                routerLink="/kosik"
                mat-icon-button
                color="secondary"
                [satPopoverAnchor]="popover"
                (mouseenter)="openSmallCart(popover)"
                (mousemove)="onSmallCartMouseMove()"
                (mouseleave)="onSmallCartMouseLeave(popover)"
                data-cy="cart-button"
                [title]="t('CART.CART')"
              >
                <raf-icon
                  icon="cart"
                  size="32px"
                  [matBadgeHidden]="(cartCountVisible$ | async) === false"
                  [matBadge]="cartCount$ | async"
                  matBadgePosition="below after"
                  matBadgeColor="accent"
                ></raf-icon>
              </button>
              <!-- cart - mobile variant without hover or desktop variant with empty cart -->
              <a *ngIf="(mobile$ | async) || (cartCountVisible$ | async) === false"
                 routerLink="/kosik"
                 mat-icon-button
                 color="secondary"
                 data-cy="cart-button"
                 [title]="t('CART.CART')">
                <raf-icon
                  icon="cart"
                  size="32px"
                  [matBadgeHidden]="(cartCountVisible$ | async) === false"
                  [matBadge]="cartCount$ | async"
                  matBadgePosition="below after"
                  matBadgeColor="accent"
                ></raf-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="header-bottom" *ngIf="tabletLayout">
          <ng-container *ngTemplateOutlet="search"></ng-container>
          <raf-mobile-menu [menuItems]="menuItems"
                           [categories]="categories"
                           [anonymous]="anonymous"
                           [open]="menuOpen$ | async"
                           (login)="onLoginClick()"
                           (logout)="onLogoutClick()"
                           (closed)="closeMenu()"></raf-mobile-menu>
        </div>
      </div>
    </raf-container>
  </div>
</ng-container>

<ng-template #search>
  <raf-search class="header-search suffix-icon-primary"></raf-search>
</ng-template>

<sat-popover panelClass="mini-cart-popover" #popover verticalAlign="below" [autoFocus]="false" [restoreFocus]="false">
  <raf-small-cart (mousemove)="onSmallCartMouseMove()"
                  (mouseleave)="onSmallCartMouseLeave(popover)"></raf-small-cart>
</sat-popover>
