import { Pipe, PipeTransform } from '@angular/core';

import { Price } from '../../model/price.model';
import { AmountPipe } from '../amount/amount.pipe';
import { RafCurrencyPipe } from '../currency/rafCurrency.pipe';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  constructor(private amountPipe: AmountPipe, private currencyPipe: RafCurrencyPipe) {}

  transform(price: Price | undefined | null): string | null {
    // TODO: add withVat / withoutVat
    return this.amountPipe.transform(price?.value) + this.currencyPipe.transform(price?.currency);
  }
}
