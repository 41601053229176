import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { CustomErrorMessages } from '../custom-error-messages.model';

@Component({
  selector: 'raf-error-list',
  templateUrl: './error-list.component.html',
  styleUrls: ['./error-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorListComponent implements OnChanges {
  @Input()
  errors: ValidationErrors = {};

  @Input()
  groupErrors?: ValidationErrors | null;

  @Input()
  customErrors: { errName: string; errMessage: string }[] = [];

  @Input()
  customMessages: CustomErrorMessages = {};

  defaultErrorMessages: { [key: string]: string } = {
    required: 'ERROR.REQUIRED',
    anyRequired: 'ERROR.ANY_REQUIRED',
    pattern: 'ERROR.PATTERN',
    dateMismatch: 'ERROR.DATE_MISMATCH',
    passwordPattern: 'ERROR.PASSWORD_PATTERN',
    passwordMismatch: 'ERROR.PASSWORD_MISMATCH',
    email: 'ERROR.EMAIL',
    date: 'ERROR.DATE',
    minlength: 'ERROR.MIN_LENGTH',
    maxlength: 'ERROR.MAX_LENGTH',
    min: 'ERROR.MIN',
    max: 'ERROR.MAX',
    phoneNumber: 'ERROR.PHONE_NUMBER',
    tiedRequired: 'ERROR.TIED_REQUIRED',
    zipCode: 'ERROR.ZIP_CODE',
  };

  mergedErrors: ValidationErrors = {};

  ngOnChanges(changes: SimpleChanges) {
    if (changes['errors'] || changes['groupErrors']) {
      this.mergedErrors = this.mergeErrors(this.errors, this.groupErrors);
    }
  }

  mergeErrors(errors?: ValidationErrors, groupErrors?: ValidationErrors | null): ValidationErrors {
    let result = {};
    if (errors) {
      result = { ...errors };
    }
    if (groupErrors) {
      result = { ...result, ...groupErrors };
    }
    return result;
  }

  get errorKeys(): string[] {
    return this.mergedErrors ? Object.keys(this.mergedErrors) : [];
  }

  isKnownError(errorKey: string): errorKey is keyof CustomErrorMessages {
    return errorKey in this.customMessages;
  }
}
