import { Component, Input } from '@angular/core';
import { Layout } from '../../../model/layout.model';

@Component({
  selector: 'raf-floating-block',
  templateUrl: './floating-block.component.html',
  styleUrls: ['./floating-block.component.scss'],
})
export class FloatingBlockComponent {
  @Input()
  layout: Layout | null = null;
}
