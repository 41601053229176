<ng-container *transloco="let t">

  <div class="mat-dialog-header mat-typography">
    <!-- CROSS BUTTON (CLOSE)  -->
    <button class="mat-dialog-close-button mat-small" mat-icon-button (click)="close()">
      <raf-icon icon="close" size="16px"></raf-icon>
    </button>

    <!-- MAIN CONFIRMATION TEXT  -->
    <raf-text class="mb-3" variant="h3" color="primary-color">{{ t("PRODUCT.STOCK_DIALOG.TITLE") }}</raf-text>
  </div>

  <mat-dialog-content class="mat-typography">

    <ng-container *ngIf="(loading$ | async) === false; else loading">
      <ng-container *ngIf="(onlineStocks$ | async) as onlineStocks">
        <raf-paper variant="stroked" background="primary" *ngFor="let onlineStock of onlineStocks">
          <div class="atp-list-item">
            <raf-text variant="body-medium" weight="bold">{{onlineStock.site.name}}</raf-text>
            <raf-text variant="body-medium" [weight]="siteStockStyleWeight(onlineStock)" [color]="siteStockColor(onlineStock)">{{t(onlineStock.text)}}</raf-text>
          </div>
        </raf-paper>
      </ng-container>
      <raf-text variant="h4" weight="bold" color="primary-color" class="mt-4 mb-2">
        Pobočky:
      </raf-text>
      <raf-input [noPadding]="true" [placeholder]="'PRODUCT.STOCK_DIALOG.SEARCH_PLACEHOLDER'" suffixIcon="search" [formControl]="searchFC"
                 autocomplete="off" [suffixTitle]="t('PRODUCT.STOCK_DIALOG.SEARCH_PLACEHOLDER')" class="mb-4"></raf-input>
      <raf-paper variant="secondary" overflow="y-auto" class="atp-sites" data-cy="atp-sites">
        <div class="atp-list">
          <ng-container *ngIf="visibleSiteStocks$ | async as siteStocks; else noData">
            <raf-paper variant="stroked" background="primary" [fillSpace]="true" data-cy="site-item" *ngFor="let siteStock of siteStocks">
              <div class="atp-list-item">
                <div>
                  <raf-text variant="body-medium" weight="bold">{{siteStock.site.name}}</raf-text>
                  <raf-text variant="body-small" color="light-text">{{siteStock.site.address.street + ', ' + siteStock.site.address.city}}</raf-text>
                </div>
                <raf-text variant="body-medium" [weight]="siteStockStyleWeight(siteStock)" [color]="siteStockColor(siteStock)">{{t(siteStock.text)}}</raf-text>
              </div>
            </raf-paper>
            <raf-paper variant="stroked" background="primary" *ngIf="siteStocks.length === 0">
              <raf-text variant="body-medium" color="grey-text">{{ t("PRODUCT.STOCK_DIALOG.EMPTY_QUERY") }}</raf-text>
            </raf-paper>
          </ng-container>
        </div>
      </raf-paper>
    </ng-container>

    <ng-template #loading>
      <raf-loading variant="inline"></raf-loading>
    </ng-template>

    <ng-template #noData>
      <raf-text data-cy="no-data">{{t('PRODUCT.STOCK_NO_DATA')}}</raf-text>
    </ng-template>
  </mat-dialog-content>
</ng-container>
