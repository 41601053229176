import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductCategory } from '../../model/product-category.model';
import { GtmService } from '../../../core/services/gtm.service';
import { Observable } from 'rxjs';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
  selector: 'raf-catalog-navigation',
  templateUrl: './catalog-navigation.component.html',
  styleUrls: ['./catalog-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogNavigationComponent {
  @Input()
  label?: string;

  @Input()
  categories?: ProductCategory[];

  @Input()
  gtmEnabled = false;

  isTablet$: Observable<boolean>;

  constructor(private gtm: GtmService, private layoutService: LayoutService) {
    this.isTablet$ = this.layoutService.lgDown$;
  }

  onNav(category: ProductCategory) {
    this.logGtm(category);
  }

  private logGtm(item: ProductCategory): void {
    if (this.gtmEnabled) {
      this.gtm.selectContent('header', item.name);
    }
  }
}
