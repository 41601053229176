import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { BehaviorSubject, delay, map, Observable, Subject, takeUntil } from 'rxjs';
import { Layout } from '../../../model/layout.model';
import { Image, ImageLink } from '../../../model/image.model';
import { ComparatorService } from '../../../../core/services/comparator.service';
import { CartService } from '../../../../core/services/cart.service';
import { User } from '../../../model/user.model';
import { LayoutService } from '../../../../core/services/layout.service';
import { triggerLoggedBtn } from './animations';
import { NavigationNode } from '../../../model/navigation-node.model';
import { ProductCategory } from '../../../model/product-category.model';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
  selector: 'raf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [triggerLoggedBtn],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input()
  layout: Layout | null = null;

  @Input()
  user: User | null = null;

  @Input()
  anonymous: boolean | null = true;

  @Input()
  tabletLayout?: boolean | null = false;

  @Input()
  size: 'large' | 'medium' | 'small' | 'form' = 'large';

  @Input()
  menuItems?: NavigationNode[] | null;

  @Input()
  categories?: ProductCategory[] | null;

  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  logout: EventEmitter<void> = new EventEmitter<void>();

  @HostBinding('class.header-component')
  headerComponentClass = true;

  @HostBinding('class.tabletBreakpoint')
  get hostClass() {
    return this.tabletLayout;
  }

  cartCount$: Observable<number>;
  cartCountVisible$: Observable<boolean>;
  comparatorCount$: Observable<number>;
  comparatorCountVisible$: Observable<boolean>;
  headerImageVisible$: Observable<boolean>;
  mobile$: Observable<boolean>;
  menuOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  smallCartClose$: Subject<void> = new Subject<void>();
  smallCartHovering$: Subject<void> = new Subject<void>();

  constructor(
    private comparatorService: ComparatorService,
    private cartService: CartService,
    private layoutService: LayoutService
  ) {
    this.cartCount$ = this.cartService.count$;
    this.cartCountVisible$ = this.cartCount$.pipe(map((count) => !!count));
    this.comparatorCount$ = this.comparatorService.count$;
    this.comparatorCountVisible$ = this.comparatorCount$.pipe(map((count) => !!count));
    this.headerImageVisible$ = this.layoutService.observe('(min-width: 1380px)');
    this.mobile$ = this.layoutService.mdDown$;
  }

  get logo(): Image | null {
    return this.layout?.mainLogo?.data?.attributes || null;
  }

  get imageLink(): ImageLink {
      return {
        image: this.layout?.headerImage?.image?.data?.attributes,
        url: this.layout?.headerImage?.url
      };
  }

  onLoginClick(): void {
    this.login.emit();
  }

  onLogoutClick(): void {
    this.logout.emit();
  }

  toggleMenu(): void {
    this.menuOpen$.next(!this.menuOpen$.value);
  }

  closeMenu(): void {
    this.menuOpen$.next(false);
  }

  openSmallCart(popover: SatPopover): void {
    if (!popover._open) {
      popover.open();
    }
    this.smallCartHovering$.next();
  }

  onSmallCartMouseMove(): void {
    this.smallCartHovering$.next();
  }

  onSmallCartMouseLeave(popover: SatPopover): void {
    this.smallCartClose$.pipe(
      delay(1000),
      takeUntil(this.smallCartHovering$)
    ).subscribe(() => {
      if (popover.opened) {
        popover.close();
      }
    });

    this.smallCartClose$.next();
  }
}
