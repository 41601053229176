import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Payment } from '../../shared/model/payment.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  createPayment(orderId: string, cartId?: string): Observable<Payment> {
    let params = new HttpParams();
    if (cartId) {
      params = params.append('cartId', cartId);
    }
    return this.http.post<Payment>(`${environment.b2cEndpoint}/orders/${orderId}/payments`, null, { params });
  }

  checkPayment(orderId: string, paymentId: number | string): Observable<Payment> {
    return this.http.get<Payment>(`${environment.b2cEndpoint}/orders/${orderId}/payments/${paymentId}`);
  }
}
