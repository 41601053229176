import { Inject, Injectable, LOCALE_ID, Optional, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { BehaviorSubject, filter, take } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Article } from '../../shared/model/article.model';
import { CmsService } from './cms.service';
import { ProductCategory } from '../../shared/model/product-category.model';
import { ProductDetail } from '../../shared/model/product.model';
import { TextUtils } from '../../shared/utils/text-utils';
import { environment } from '../../../environments/environment';

@Injectable()
export class SeoService {
  private baseUrl: string;
  private title$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private metaTags$: BehaviorSubject<MetaDefinition[]> = new BehaviorSubject<MetaDefinition[]>([]);

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translocoService: TranslocoService,
    private cmsService: CmsService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    @Optional() @Inject('PUBLIC_URL') private publicUrl: string,
    private location: Location
  ) {
    this.baseUrl = isPlatformBrowser(this.platformId) ? this.document.location.origin : this.publicUrl;
  }

  setTitle(title?: string): void {
    title = title ? title + ' ⋅ PLANO' : 'PLANO';
    this.titleService.setTitle(title);
    this.title$.next(title);
  }

  /** Add or update meta tag in document head */
  setMetaTag(tag: MetaDefinition): void {
    if (this.metaService.getTag(`property='${tag.property}'`) || this.metaService.getTag(`name='${tag.name}'`)) {
      this.metaService.updateTag(tag);
      return;
    }

    this.metaService.addTag(tag);
    this.metaTags$.next([...this.metaTags$.value, tag]);
  }

  private setCanonicalUrl(url: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    this.document.head.appendChild(link);
  }

  /** Remove every meta tag added by this service */
  removeAllMetaTags(): void {
    this.metaTags$.value.forEach((tag) => {
      if (tag.property) {
        this.metaService.removeTag(`property='${tag.property}'`);
      } else if (tag.name) {
        this.metaService.removeTag(`name='${tag.name}'`);
      }
    });
    this.metaTags$.next([]);
  }

  /** Remove meta tags by property attribute */
  removeMetaTagsByProperty(propertyValues: string[]): void {
    propertyValues.forEach((val) => this.metaService.removeTag(`property='${val}'`));
    this.metaTags$.next(this.metaTags$.value.filter((tag) => !tag.property || !propertyValues.includes(tag.property)));
  }

  /** Remove meta tags by name attribute */
  removeMetaTagsByName(nameValues: string[]): void {
    nameValues.forEach((val) => this.metaService.removeTag(`name='${val}'`));
    this.metaTags$.next(this.metaTags$.value.filter((tag) => !tag.name || !nameValues.includes(tag.name)));
  }

  /** Set general meta tags for every page */
  setGeneralMetaTags(): void {
    const currentUrl = this.getCurrentUrl();
    this.setMetaTag({ property: 'og:locale', content: this.localeId });
    this.setMetaTag({ property: 'og:url', content: currentUrl });
    this.setCanonicalUrl(currentUrl);
    this.translocoService.selectTranslate(['GLOBAL.SITE_NAME', 'GLOBAL.SITE_DESCRIPTION']).subscribe((val) => {
      this.setMetaTag({ property: 'og:site_name', content: val[0] });
      this.setMetaTag({ property: 'og:description', content: val[1] });
      this.setMetaTag({ name: 'description', content: val[1] });
    });
  }

  clearGeneralMetaTags(): void {
    this.removeMetaTagsByProperty(['og:site_name', 'og:locale', 'og:url', 'og:description']);
    this.removeMetaTagsByName(['description']);
  }

  /** Set meta tags for article page */
  setMetaTagsForArticle(article: Article): void {
    this.setGeneralMetaTags();
    this.setMetaTag({ property: 'og:type', content: 'og:article' });
    this.cmsService.layout$
      .pipe(
        filter((layout) => !!layout.data),
        take(1)
      )
      .subscribe((layout) => {
        if (layout.data?.mainLogo?.data?.attributes?.url) {
          this.setMetaTag({ property: 'og:image', content: this.baseUrl + layout.data.mainLogo.data.attributes.url }); // todo find first image in content instead of logo?
        }
      });
    if (article.pageTitle) {
      this.setMetaTag({ property: 'og:title', content: article.pageTitle });
    }
    this.setMetaTag({ property: 'og:description', content: '' });
    this.setMetaTag({ name: 'description', content: '' });
  }

  clearMetaTagsForArticle(): void {
    this.clearGeneralMetaTags();
    this.removeMetaTagsByProperty(['og:type', 'og:image', 'og:title', 'og:description']);
    this.removeMetaTagsByName(['description']);
  }

  /** Set meta tags for category detail page (catalog) */
  setMetaTagsForCategory(category: ProductCategory): void {
    this.clearMetaTagsForCategory();
    this.setGeneralMetaTags();
    this.setMetaTag({ property: 'og:type', content: 'og:website' });
    if (category.name) {
      this.setMetaTag({ property: 'og:title', content: category.name });
    }
    if (category.image?.previewUrl) {
      const content: string = this.baseUrl + category.image.previewUrl;
      this.setMetaTag({ property: 'og:image', content });
    }
    if (category.description) {
      this.setMetaTag({ property: 'og:description', content: TextUtils.stripHtml(category.description) });
      this.setMetaTag({ name: 'description', content: TextUtils.stripHtml(category.description) });
    }
  }

  clearMetaTagsForCategory(): void {
    this.clearGeneralMetaTags();
    this.removeMetaTagsByProperty(['og:type', 'og:title', 'og:url', 'og:description', 'og:image']);
    this.removeMetaTagsByName(['description']);
  }

  /* Set meta tags for product detail page */
  setMetaTagsForProduct(product: ProductDetail): void {
    this.clearMetaTagsForProduct();
    this.setGeneralMetaTags();
    this.setMetaTag({ property: 'og:type', content: 'og:product' });
    if (product.name) {
      this.setMetaTag({ property: 'og:title', content: product.name });
    }
    if (product.description) {
      this.setMetaTag({ property: 'og:description', content: TextUtils.stripHtml(product.description) });
      this.setMetaTag({ name: 'description', content: TextUtils.stripHtml(product.description) });
    }
    if (product.images?.length) {
      const content: string = this.baseUrl + product.images[0].largeUrl;
      this.setMetaTag({ property: 'og:image', content });
    }
  }

  clearMetaTagsForProduct(): void {
    this.clearGeneralMetaTags();
    this.removeMetaTagsByProperty(['og:type', 'og:title', 'og:description', 'og:image']);
    this.removeMetaTagsByName(['description']);
  }

  setNoIndex(): void {
    this.setMetaTag({ name: 'robots', content: 'noindex' });
  }

  setFacebookDomainVerificationTag(): void {
    this.setMetaTag({ name: 'facebook-domain-verification', content: environment.facebookDomainVerificationCode });
  }

  setSeznamMetaTag(): void {
    this.setMetaTag({ name:'seznam-wmt', content: environment.seznamWmt });
  }


  private getCurrentUrl(): string {
    return this.baseUrl + this.location.path();
  }
}
