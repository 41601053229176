import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PromoText } from '../../../model/promo-text.model';
import { Image } from '../../../model/image.model';

@Component({
  selector: 'raf-promo-text',
  templateUrl: './promo-text.component.html',
  styleUrls: ['./promo-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoTextComponent {
  @Input()
  content?: PromoText;

  get icon(): Image | null {
    return this.content?.icon?.data?.attributes || null;
  }
}
