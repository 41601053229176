import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'atpFilter'
})
export class AtpFilterPipe implements PipeTransform {

  transform(items: any[] | null, query: string): any[] {
    if (!items) {
      return [];
    }
    if (!query) {
      return items;
    }
    return items.filter((item: any) => {
      return item.site?.name?.toLowerCase().includes(query?.toLowerCase()) || this.foundInAdress(item, query);
    });
  }

  private foundInAdress(item: any, query: string): boolean {
    let found = false;
    let address = item.address;
    if (Object.keys(item.site).length) {
      address = item.site.address;
    }
    if (address) {
      Object.keys(address).forEach(key => {
        if (address[key]?.toLowerCase().includes(query?.toLowerCase())) {
          found = true;
        }
      });
    }
    return found;
  }

}
