import { Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PickupSite } from '../../shared/model/cart.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class SiteService {

  constructor(private logger: Logger, private http: HttpClient) {
  }

  getSites(): Observable<PickupSite[]> {
    return this.http.get<PickupSite[]>(`${environment.b2cEndpoint}/sites`);
  }

}
