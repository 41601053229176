export type BreakpointsType = {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
};

export const BreakpointsDown: BreakpointsType = {
  xs: '0',
  sm: '(max-width: 575.98px)',
  md: '(max-width: 767.98px)',
  lg: '(max-width: 991.98px)',
  xl: '(max-width: 1199.98px)',
};
export const BreakpointsUp: BreakpointsType = {
  xs: '0',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(max-width: 1200px)',
};
