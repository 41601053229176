import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Product } from '../../../model/product.model';
import { BehaviorSubject, take } from 'rxjs';
import { CartService } from '../../../../core/services/cart.service';

@Component({
  selector: 'raf-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnChanges {
  @Input()
  product?: Product;

  @Input()
  contentAlign: 'default' | 'center' = 'default';

  @Input()
  priceVisible = true;

  @Input()
  unitPriceVisible = true;

  @Input()
  stockVisible = true;

  @Input()
  nameVisible = true;

  @Input()
  quantityVisible = true;

  @Input()
  actionVisible = true;

  @Input()
  size: 'normal' | 'small' = 'normal';

  @Input()
  noPadding = false;

  @Input()
  navDisabled = false;

  @Output()
  clicked: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  quantityChange: EventEmitter<{ product: Product; quantity: number }> = new EventEmitter<{
    product: Product;
    quantity: number;
  }>();

  selectedQuantity$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  addingToCart$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private cartService: CartService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['product']?.currentValue) {
      this.selectedQuantity$.next(this.product?.moq || 1);
    }
  }

  onQuantityChange(quantity: number) {
    this.selectedQuantity$.next(quantity);
    if (this.product) {
      this.quantityChange.emit({ product: this.product, quantity });
    }
  }

  onAddToCart(): void {
    this.selectedQuantity$.pipe(take(1)).subscribe((quantity) => {
      if (this.product) {
        this.addingToCart$.next(true);
        const cb = () => {
          this.addingToCart$.next(false);
        };
        this.cartService.addToCart(this.product, quantity, true, cb);
      }
    });
  }

  onClicked(event: MouseEvent) {
    this.clicked.emit(this.product);
    if (this.navDisabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
