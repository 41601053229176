import { ChangeDetectionStrategy, Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { IconVariant } from '../icon/icon.variants';

@Component({
  selector: 'raf-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor {
  @Input()
  label?: string;

  @Input()
  hint?: string;

  @Input()
  placeholder?: string;

  @Input()
  autocomplete?: 'off' | 'on' | 'current-password' | 'new-password' = 'on';

  @Input()
  name = '';

  @Input()
  suffixIcon?: IconVariant;

  @Input()
  suffixText?: string;

  @Input()
  suffixTitle?: string;

  @Input()
  type: 'text' | 'password' = 'text';

  @Input()
  size: 'medium' | 'small' = 'small';

  @Input()
  noPadding?: boolean;

  @Input()
  fullWidth?: boolean;

  @Input()
  includeGroupErr = false;

  @Input()
  hidePasswordStrengthIndicator = false;

  @Output()
  suffixClick: EventEmitter<string> = new EventEmitter();

  @Output()
  focusChange: EventEmitter<boolean> = new EventEmitter();

  private readonly fallbackFC?: UntypedFormControl;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl !== null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    } else {
      this.fallbackFC = new UntypedFormControl();
    }
  }

  get control(): UntypedFormControl {
    return (this.ngControl?.control as UntypedFormControl) || this.fallbackFC;
  }

  propagateChange = () => {
    // empty just to satisfy ControlValueAccessor
  };

  propagateTouched = () => {
    // empty just to satisfy ControlValueAccessor
  };

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn;
  }

  setDisabledState(): void {
    // empty just to satisfy ControlValueAccessor
  }

  writeValue(): void {
    // empty just to satisfy ControlValueAccessor
  }

  setFocus(value: boolean) {
    this.focusChange.emit(value);
  }

  onSuffixClicked() {
    this.suffixClick.emit(this.control.value);
  }
}
