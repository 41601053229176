<ng-container *transloco="let t">

  <raf-section  variant="light" gutterBottom="normal">
    <raf-paper variant="secondary">
      <raf-status-block motive="error" [mainTitle]="t(titles[variant])" [subTitle]="t(subtitles[variant])">
        <a mat-flat-button color="primary" href="/">{{t('ERROR.RETURN_BUTTON')}}</a>
      </raf-status-block>
    </raf-paper>
  </raf-section>
</ng-container>

<ng-container *ngIf="fullPage">
  <raf-section *ngIf="productCategories$ | async as categories">
    <raf-catalog-navigation
      [categories]="categories"
      label="HOMEPAGE.CATALOG_NAVIGATION_LABEL"></raf-catalog-navigation>
  </raf-section>

  <ng-container *ngIf="layout$ | async as layout">
    <raf-section>
      <raf-promo-block label="PROMO_BLOCK.LABEL" [content]="layout.shoppingTips"></raf-promo-block>
    </raf-section>

    <raf-section>
      <raf-partners [data]="layout.manufacturerList"></raf-partners>
    </raf-section>
  </ng-container>
</ng-container>



