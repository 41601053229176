import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductCategory } from '../../shared/model/product-category.model';
import { ApiResource } from '../../shared/model/api-resource.model';
import { loadedOrLoading } from '../../shared/utils/api-utils';
import { Logger } from './logger.service';
import { SKIP_HTTP_ERROR_ALERT } from '../interceptors/http-error.interceptor';

interface CategoryStore {
  [categoryId: string]: BehaviorSubject<ApiResource<ProductCategory>>;
}

@Injectable()
export class CatalogService {
  private categoriesSubject$: BehaviorSubject<ApiResource<ProductCategory[]>> = new BehaviorSubject<
    ApiResource<ProductCategory[]>
  >({
    loading: false,
    data: null,
  });

  private categoryStore: CategoryStore = {};

  constructor(private logger: Logger, private http: HttpClient) {}

  get categories$(): Observable<ApiResource<ProductCategory[]>> {
    if (!loadedOrLoading(this.categoriesSubject$.value)) {
      this.loadCategories();
    }

    return this.categoriesSubject$.asObservable();
  }

  loadCategories(): void {
    this.categoriesSubject$.next({ loading: true, data: null, error: false });

    this.http
      .get<ProductCategory[]>(`${environment.b2cEndpoint}/categories`)
      .pipe(
        catchError((err) => {
          this.categoriesSubject$.next({ loading: false, data: null, error: true });
          this.logger.error(err);
          return throwError(err);
        })
      )
      .subscribe((res: ProductCategory[]) => {
        this.categoriesSubject$.next({ loading: false, data: res, error: false });
      });
  }

  getCategoriesById(categoryIds: string[]): Observable<ProductCategory[]> {
    const params = new HttpParams({ fromObject: { categoryId: categoryIds, fields: 'BASIC' } });
    return this.http.get<ProductCategory[]>(`${environment.b2cEndpoint}/categories`, { params });
  }

  getCategory(categoryId: string): Observable<ApiResource<ProductCategory>> {
    // if category is not cached yet, initialize it
    if (!this.categoryStore[categoryId]) {
      this.categoryStore[categoryId] = new BehaviorSubject<ApiResource<ProductCategory>>({
        data: null,
        loading: false,
      });
    }

    // if it has no data and is not loading, fetch it
    if (!this.categoryStore[categoryId].value.data && !this.categoryStore[categoryId].value.loading) {
      this.loadCategory(categoryId);
    }

    return this.categoryStore[categoryId];
  }

  loadCategory(categoryId: string): void {
    this.categoryStore[categoryId].next({ loading: true, data: null, error: false });
    const context = new HttpContext().set(SKIP_HTTP_ERROR_ALERT, true);
    this.http
      .get<ProductCategory>(`${environment.b2cEndpoint}/categories/${categoryId}`, {context})
      .pipe(
        catchError((err) => {
          this.categoryStore[categoryId].next({ loading: false, data: null, error: true });
          this.logger.error(err);
          return throwError(err);
        })
      )
      .subscribe((res: ProductCategory) => {
        this.categoryStore[categoryId].next({ loading: false, data: res, error: false });
      });
  }
}
