import { animate, group, query, sequence, style, transition, trigger } from '@angular/animations';

export const mobileMenuAnimation = trigger('mobileMenu', [
  transition(':enter', [
    group([
      query('.mobile-menu-container', [
        style({
          transformOrigin: 'top left',
          opacity: 0,
          transform: 'scale(0.8)',
        }),
        animate(
          '0.2s ease-in',
          style({
            opacity: 1,
            transform: 'scale(1)',
          })
        ),
      ]),
      query('.menu-container-backdrop', [
        style({ opacity: 0 }),
        animate(
          '0.2s ease-in',
          style({
            opacity: 1,
          })
        ),
      ]),
    ]),
  ]),
  transition(':leave', [
    group([
      query('.mobile-menu-container', [
        style({
          transformOrigin: 'top left',
          opacity: 1,
        }),
        animate(
          '0.12s ease-out',
          style({
            opacity: 0,
            transform: 'scale(0.8)',
          })
        ),
      ]),
      query('.menu-container-backdrop', [
        style({ opacity: 1 }),
        animate(
          '0.12s ease-in',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ]),
]);

export const listAnimation = trigger('listAnimation', [
  transition(':increment', [
    query(':enter', style({ position: 'absolute' }), { optional: true }),
    sequence([
      query(
        ':leave .mat-list-text raf-text',
        [
          style({ transform: 'translateX(0%)', opacity: '1' }),
          animate('0.2s ease-out', style({ transform: 'translateX(-100%)', opacity: '0' })),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]),
  transition(':decrement', [
    query(':enter', style({ position: 'absolute' }), { optional: true }),
    group([
      query(
        ':leave .mat-list-text raf-text',
        [
          style({ transform: 'translateX(0%)', opacity: '1' }),
          animate('0.2s ease-out', style({ transform: 'translateX(100%)', opacity: '0', position: 'absolute' })),
        ],
        {
          optional: true,
        }
      ),
    ]),
  ]),
]);
