import { ChangeDetectionStrategy, Component, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { SatPopover } from '@ncstate/sat-popover';
import { CartService } from '../../../../core/services/cart.service';
import { Cart, CartEntry } from '../../../model/cart.model';
import { Product } from '../../../model/product.model';


@Component({
  selector: 'raf-small-cart',
  templateUrl: './small-cart.component.html',
  styleUrls: ['./small-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmallCartComponent {
  cart$: Observable<Cart | null>;
  loading$: Observable<boolean>;

  constructor(private cartService: CartService, @Optional() private popover: SatPopover) {
    this.cart$ = cartService.cart$;
    this.loading$ = cartService.loading$;
  }

  onQuantityChange({ entry, quantity }: { entry?: CartEntry; product: Product; quantity: number }) {
    if (entry) {
      this.cartService.updateQuantity(entry, quantity);
    }
  }

  onRemove(entry: CartEntry | Product) {
    this.cartService.removeFromCart(entry as CartEntry);
  }

  onNav() {
    this.popover?.close();
  }
}
