<ng-container *transloco="let t">
  <raf-text data-cy="heading-text" variant="body-medium" weight="medium" [color]="stockColor">{{t(stockTextKey)}}</raf-text>
  <span data-cy="stock-detail-pop-link" (click)="openStockDetail()">
<!--    <raf-text data-cy="availability-text" [link]="true" variant="body-medium" color="grey-text">{{t('PRODUCT.STOCK_PER_SITE_DYNAMIC', {count: totalSiteCount})}}</raf-text>-->
    <raf-text *ngIf="inStockEverywhere" data-cy="availability-text" [link]="true" variant="body-medium" color="grey-text">{{t('PRODUCT.STOCK_PER_SITE.IN_STOCK_EVERYWHERE')}}</raf-text>
    <raf-text *ngIf="inStockSomewhere" data-cy="availability-text" [link]="true" variant="body-medium" color="grey-text">{{t('PRODUCT.STOCK_PER_SITE.IN_STOCK_SOMEWHERE')}}</raf-text>
    <raf-text *ngIf="outOfStock" data-cy="availability-text" [link]="true" variant="body-medium" color="grey-text">{{t('PRODUCT.STOCK_PER_SITE.OUT_OF_STOCK')}}</raf-text>
    <raf-text *ngIf="onOrder" data-cy="availability-text" [link]="true" variant="body-medium" color="grey-text">{{t('PRODUCT.STOCK_PER_SITE.ON_ORDER')}}</raf-text>
  </span>
</ng-container>
