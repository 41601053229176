import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breadcrumb } from '../../../model/breadcrumb.model';

@Component({
  selector: 'raf-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input()
  breadcrumbs?: Breadcrumb[] | null;
}
