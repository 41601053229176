<div class="paper"
     [ngClass]="{
     'paper-primary': variant === 'primary',
     'paper-secondary': variant === 'secondary',
     'paper-stroked': variant === 'stroked',
     'paper-success': variant === 'success',
     'paper-transparent': variant === 'transparent',
     'paper-background-primary': background === 'primary',
     'paper-background-transparent': background === 'transparent',
     'paper-border-radius-small': borderRadius === 'small',
     'paper-border-radius-medium': borderRadius === 'medium',
     'fill-space' : fillSpace,
     'overflow-hidden' : overflow === 'hidden',
     'overflow-auto' : overflow === 'auto',
     'overflow-y-auto' : overflow === 'y-auto',
     'overflow-x-auto' : overflow === 'x-auto',
     'paper-null-sm' : nullBreakpoint === 'sm',
     'paper-null-md' : nullBreakpoint === 'md',
     'paper-null-lg' : nullBreakpoint === 'lg',
     'paper-null-all' : nullBreakpoint === 'all',
     'paper-spacing-none' : spacing === 'none',
     'paper-error': error
     }">
  <ng-content></ng-content>
</div>
