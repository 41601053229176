import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { iconProvider } from './providers/icon-provider';
import { localeProvider } from './providers/locale-provider';
import { matPaginatorIntlCsProvider } from './providers/mat-paginator-intl-cs-provider';
import { AppConfigService } from './services/app-config.service';
import { SentryService } from './services/sentry.service';
import { Logger } from './services/logger.service';
import { CmsService } from './services/cms.service';
import { ssrAbsolutePathInterceptorProvider } from './providers/ssr-absolute-path-interceptor-provider';
import { CatalogService } from './services/catalog.service';
import { ComparatorService } from './services/comparator.service';
import { CartService } from './services/cart.service';
import { UserService } from './services/user.service';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { LayoutService } from './services/layout.service';
import { DialogService } from './services/dialog.service';
import { ProductService } from './services/product.service';
import { AlertService } from './services/alert.service';
import { httpErrorInterceptorProvider } from './providers/http-error-interceptor-provider';
import { SeoService } from './services/seo.service';
import { OrderService } from './services/order.service';
import { ssrCookieInterceptorProvider } from './providers/ssr-cookie-interceptor-provider';
import { PriceSummaryService } from './services/price-summary.service';
import { DeliveryPaymentService } from './services/delivery-payment.service';
import { PaymentService } from './services/payment.service';
import { ServerStatusService } from './services/server-status.service';
import { SsrService } from './services/ssr.service';
import { GtmService } from './services/gtm.service';
import { SiteService } from './services/site.service';
import { LoadingService } from './services/loading.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, MatDialogModule, MatSnackBarModule],
  providers: [
    AppConfigService,
    SentryService,
    Logger,
    AlertService,
    LayoutService,
    CmsService,
    CartService,
    CatalogService,
    ComparatorService,
    UserService,
    BreadcrumbsService,
    DialogService,
    OrderService,
    ProductService,
    SeoService,
    PriceSummaryService,
    DeliveryPaymentService,
    PaymentService,
    ServerStatusService,
    SsrService,
    GtmService,
    SiteService,
    LoadingService,
    iconProvider,
    localeProvider,
    matPaginatorIntlCsProvider,
    ssrAbsolutePathInterceptorProvider,
    ssrCookieInterceptorProvider,
    httpErrorInterceptorProvider,
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule
    // force instantiate Tracing
    // trace: Sentry.TraceService // eslint-disable-line
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
