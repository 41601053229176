import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, Observable, Subject, takeUntil } from 'rxjs';
import { Destroyed } from '../../decorators/destroyed';
import { moqValidator } from '../../validators/moq.validator';
import { Unit } from '../../model/unit.model';
import { CartService } from '../../../core/services/cart.service';

@Component({
  selector: 'raf-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityComponent implements OnInit, OnChanges {
  private readonly DEFAULT_VALUE = 1;

  @Input()
  set value(val: number | undefined) {
    if (typeof val !== 'number') {
      val = 0;
    }
    this.fc.setValue(val);
  }

  // minimum order quantity, 0 to turn it off
  @Input()
  moq = 1;

  @Input()
  size: 'normal' | 'small' = 'normal';

  @Input()
  debounce = 500;

  @Input()
  unit?: Unit;

  @Input()
  maxQuantity = CartService.MAX_QUANTITY;

  @Output()
  valueChange: EventEmitter<number> = new EventEmitter<number>();

  @Destroyed
  destroyed$!: Subject<void>;

  fc: FormControl;
  valueChanges$: Observable<number | null>;

  constructor() {
    this.fc = new FormControl(this.DEFAULT_VALUE);
    this.valueChanges$ = this.fc.valueChanges.pipe(takeUntil(this.destroyed$));
  }

  ngOnInit(): void {
    this.bindValueChange();
  }

  // when MOQ changes, update form control validator and re-validate form control value
  ngOnChanges(changes: SimpleChanges) {
    if (changes['moq']) {
      const newMoq = changes['moq'].currentValue;
      if (newMoq) {
        this.fc.setValidators(moqValidator(newMoq));
        // if value is default and MOQ changes, use new MOQ as default value
        if (this.fc.value === this.DEFAULT_VALUE) {
          this.fc.setValue(newMoq);
        }
        this.validateMoq(this.fc, newMoq);
      } else {
        this.fc.setValidators(null);
      }
    }
  }

  validateMoq(fc: FormControl, moq: number): void {
    // fix negative or 0 to MOQ
    if (fc.value <= 0) {
      fc.setValue(moq);
    }

    // fix to max allowed number if exceeded
    if (fc.value > this.maxQuantity) {
      const maxVal = this.maxQuantity;
      if (!this.valueMatchesMoq(maxVal, moq)) { // max value doesn't match MOQ
        const floor = maxVal - (maxVal % moq); // closest lower value
        fc.setValue(this.round(floor));
      } else {
        fc.setValue(this.round(maxVal)); // maximum value matches MOQ
      }
    }

    // if form control value doesn't match MOQ, update its value to closest higher matching value
    if (!this.valueMatchesMoq(fc.value, moq)) {
      const floor = fc.value - (fc.value % moq); // closest lower value
      fc.setValue(this.round(floor + moq)); // closest higher value
    }
  }

  // detect if provided value matches MOQ
  valueMatchesMoq(val: number | null, moq: number | undefined): boolean {
    if (!moq) {
      return true;
    }

    if (val === null) {
      return false;
    }

    return this.scale(val) % this.scale(moq) === 0;
  }

  // subscribe to form control valueChanges, emit valid values
  bindValueChange(): void {
    this.valueChanges$
      .pipe(
        filter((val) => val !== null && val > 0), // filter only valid values
        filter((val) => this.valueMatchesMoq(val, this.moq)), // filter only valid values
        debounceTime(this.debounce || 0)
      )
      .subscribe((val) => {
        // thx to filter above 'val' is never null
        this.valueChange.emit(val as number);
      });
  }

  // update form control value if needed (MOQ)
  onBlur(): void {
    this.validateMoq(this.fc, this.moq);
  }

  onDecrease(): void {
    let newVal = this.round(this.fc.value - this.moq);
    if (newVal < this.moq) {
      newVal = this.moq;
    }
    this.fc.setValue(newVal);
  }

  onIncrease(): void {
    const newVal = this.round(this.fc.value + this.moq);
    if (newVal <= this.maxQuantity) {
      this.fc.setValue(newVal);
    }
  }

  private round(src: number): number {
    return this.scale((this.scale(src) / 1000), 100) / 100;
  }

  private scale(src: number, multiplier = 1000): number {
    return Math.round(src * multiplier);
  }
}
