import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
class MatPaginatorIntlCs extends MatPaginatorIntl {
  override itemsPerPageLabel = 'Počet výsledků na stránku';
  override firstPageLabel = 'První stránka';
  override lastPageLabel = 'Poslední stránka';
  override nextPageLabel = 'Další stránka';
  override previousPageLabel = 'Předchozí stránka';

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return '0 z ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' z ' + length;
  }
}

export const matPaginatorIntlCsProvider = {
  provide: MatPaginatorIntl,
  useClass: MatPaginatorIntlCs
};
