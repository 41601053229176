import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Logger } from '../services/logger.service';


// case-insensitive check if tested value starts with any provided substring
// function factory
const startsWithAny = (arr: string[] = []) => (value = '') => {
  return arr.some((test) => value.toLowerCase().startsWith(test.toLowerCase()));
};

// http, https, protocol relative
const isAbsoluteURL = startsWithAny(['http', '//']);

@Injectable()
export class SsrAbsolutePathInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject('SERVER_URL') protected serverUrl: string, private logger: Logger) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    if (this.serverUrl && !isAbsoluteURL(req.url)) {
      const protocolHost = `${this.serverUrl}`;
      const pathSeparator = !req.url.startsWith('/') ? '/' : '';
      const url = protocolHost + pathSeparator + req.url;
      const serverRequest = req.clone({ url });
      this.logger.log('relative path resolved to', url);

      const startTime: Date = new Date();
      return next.handle(serverRequest).pipe(
        tap(() => {
            this.logger.log(
              `[SSR Interceptor] api call to '${url}' done in ${new Date().getTime() - startTime.getTime()} ms`
            );
          }
        ));
    } else {
      return next.handle(req);
    }
  }
}
