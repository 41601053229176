import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'raf-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductGridComponent {
  @Input()
  layout: 'page' | 'with-facets' = 'page';
}
