import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, mergeMap, Observable, Subject, switchMap, take, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RegistrationData } from '../../shared/model/registration-data.model';
import { Logger } from './logger.service';
import { environment } from '../../../environments/environment';
import { User } from '../../shared/model/user.model';
import { B2cAddress } from '../../shared/model/address.model';
import { ContactDetails } from '../../shared/model/contact-details.model';
import { CartService } from './cart.service';
import { GtmService } from './gtm.service';

@Injectable()
export class UserService {
  private anonymousSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private userSubject$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private temporaryPasswordForRegistration: string | null = null;
  private logoutSubject$: Subject<void> = new Subject();
  private ordersDisabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private checkoutRegistrationEnabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private logger: Logger, private http: HttpClient, private cartService: CartService, private gtm: GtmService) {
    this.cartService.setAnonymous(this.anonymous$);
    this.getCurrentUser().subscribe((user) => {
      this.cartService.loadUsersLastActiveCart(user);
    });
  }

  get anonymous$(): Observable<boolean> {
    return this.anonymousSubject$.asObservable();
  }

  get user$(): Observable<User | null> {
    return this.userSubject$.asObservable();
  }

  get logout$(): Observable<void> {
    return this.logoutSubject$.asObservable();
  }

  // #66246 whether are orders globally disabled in web config
  get ordersDisabled$(): Observable<boolean> {
    return this.ordersDisabledSubject$.asObservable();
  }

  // #70565
  get checkoutRegistrationEnabled$(): Observable<boolean> {
    return this.checkoutRegistrationEnabledSubject$.asObservable();
  }

  register(formData: RegistrationData): Observable<RegistrationData> {
    return this.http.post(`${environment.b2cEndpoint}/users`, formData).pipe(
      catchError((err) => {
        this.logger.error(err);
        return throwError(err);
      })
    );
  }

  login(username: string, password: string): Observable<unknown> {
    return this.http
      .post(`${environment.b2cEndpoint}/auth/login`, { username, password })
      .pipe(
        tap(() => this.gtm.login()),
        mergeMap(() => this.cartService.assignCart()),
        mergeMap(() => this.getCurrentUser()),
        tap((user) => this.cartService.loadUsersLastActiveCart(user))
      );
  }

  logout(): Observable<unknown> {
    return this.http.delete(`${environment.b2cEndpoint}/auth`).pipe(
      tap(() => {
        this.cartService.removeActiveCart();
        this.logoutSubject$.next();
      }),
      mergeMap(() => this.getCurrentUser())
    );
  }

  withActiveUser<T>(fn: (user: User) => Observable<T | null>): Observable<T | null> {
    return this.user$.pipe(
      filter((user) => !!user),
      take(1),
      switchMap((user) => {
        return fn(user as User);
      })
    );
  }

  isUserAnonymous(user: User) {
    return user.login === 'ANONYMOUS';
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${environment.b2cEndpoint}/session`).pipe(
      tap((user) => {
        this.userSubject$.next(user);
        this.anonymousSubject$.next(this.isUserAnonymous(user));
        this.ordersDisabledSubject$.next(user.sendOrderDisabled);
        this.checkoutRegistrationEnabledSubject$.next(user.checkoutRegistrationEnabled)
      })
    );
  }

  getCurrentUserContactDetails(userId: string): Observable<ContactDetails | null> {
    return this.http.get<ContactDetails | null>(`${environment.b2cEndpoint}/users/${userId}`);
  }

  setCurrentUserContactDetails(formData: ContactDetails | null, userId: string): Observable<ContactDetails | null> {
    return this.http.put<ContactDetails>(`${environment.b2cEndpoint}/users/${userId}`, formData);
  }

  forgottenPassword(username: string): Observable<unknown> {
    const params = new HttpParams({ fromObject: { userId: username } });
    return this.http.post(`${environment.b2cEndpoint}/password/reset-token`, null, { params });
  }

  setPassword(userId: string, oldPassword: string, newPassword: string): Observable<unknown> {
    return this.http.put(`${environment.b2cEndpoint}/users/${userId}/password`, { oldPassword, newPassword });
  }

  setPasswordByToken(newPassword: string, token: string): Observable<unknown> {
    return this.http.put(`${environment.b2cEndpoint}/password/by-token`, { newPassword, token });
  }

  getUserDeliveryAddresses(userId: string): Observable<B2cAddress[] | null> {
    return this.http.get<B2cAddress[]>(`${environment.b2cEndpoint}/users/${userId}/delivery-addresses`);
  }

  addUserDeliveryAddress(b2cAddress: B2cAddress, userId: string): Observable<B2cAddress> {
    return this.http.post<B2cAddress>(`${environment.b2cEndpoint}/users/${userId}/delivery-addresses`, b2cAddress).pipe(
      catchError((err) => {
        this.logger.error(err);
        return throwError(err);
      })
    );
  }

  setUserDeliveryAddress(b2cAddress: B2cAddress | null, userId: string, addressId: number): Observable<B2cAddress | null> {
    return this.http.put<B2cAddress>(`${environment.b2cEndpoint}/users/${userId}/delivery-addresses/${addressId}`, b2cAddress);
  }

  deleteUserDeliveryAddress(addressId: number, userId: string): Observable<unknown> {
    return this.http.delete(`${environment.b2cEndpoint}/users/${userId}/delivery-addresses/${addressId}`);
  }

  setTemporaryPasswordForCheckoutRegistration(password: string): void {
    this.temporaryPasswordForRegistration = password;
  }

  getTemporaryPasswordForCheckoutRegistration(): string | null {
    return this.temporaryPasswordForRegistration;
  }
}
