import { Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { map, Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

import { Currency } from '../../model/price.model';

@Pipe({
  name: 'rafCurrency',
})
export class RafCurrencyPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService, private asyncPipe: AsyncPipe) {}

  transform(currency: Currency | undefined | null): string | null {
    if (!currency) {
      return '';
    }

    if (currency.symbol) {
      return ' ' + currency.symbol;
    }

    if (currency.code) {
      return this.asyncPipe.transform(this.getTranslatedCurrencyCode(currency.code));
    }

    return '';
  }

  getTranslatedCurrencyCode(code: string): Observable<string> {
    return this.translocoService.selectTranslate(`GLOBAL.CURRENCY.${code}`).pipe(map((val) => ' ' + val));
  }
}
