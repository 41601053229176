import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';

import { BasicLayoutComponent } from './shared/components/layout/basic-layout/basic-layout.component';

const routes: Routes = [
  {
    path: 'kosik',
    loadChildren: () => import('./modules/cart/cart.module').then((m) => m.CartModule),
  },
  {
    path: '',
    component: BasicLayoutComponent,
    children: [
      {
        path: 'sandbox',
        loadChildren: () => import('./modules/sandbox/sandbox.module').then((m) => m.SandboxModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./modules/homepage/homepage.module').then((m) => m.HomepageModule),
      },
      {
        path: 'muj-ucet',
        loadChildren: () => import('./modules/my-account/my-account.module').then((m) => m.MyAccountModule),
      },
      {
        matcher: (url) => {
          if (url.length !== 1) {
            return null;
          }

          const urlSuffix = url[0].path.match(/-c([\d]+)$/gm);

          if (urlSuffix && urlSuffix[0]) {
            return {
              consumed: url,
              posParams: {
                category: new UrlSegment(urlSuffix[0].substring(2), {}),
              },
            };
          }

          return null;
        },
        loadChildren: () => import('./modules/catalog/catalog.module').then((m) => m.CatalogModule),
      },
      {
        matcher: (url) => {
          if (url.length !== 1) {
            return null;
          }

          const urlSuffix = url[0].path.match(/-p([\d]+)$/gm);

          if (urlSuffix && urlSuffix[0]) {
            return {
              consumed: url,
              posParams: {
                product: new UrlSegment(urlSuffix[0].substring(2), {}),
              },
            };
          }

          return null;
        },
        loadChildren: () => import('./modules/product-detail/product-detail.module').then((m) => m.ProductDetailModule),
      },
      {
        path: 'registrace',
        loadChildren: () => import('./modules/registration/registration.module').then((m) => m.RegistrationModule),
      },
      {
        path: 'vyhledavani',
        loadChildren: () => import('./modules/search-results/search-results.module').then((m) => m.SearchResultsModule),
      },
      {
        path: 'zapomenute-heslo',
        loadChildren: () =>
          import('./modules/forgotten-password/forgotten-password.module').then((m) => m.ForgottenPasswordModule),
      },
      {
        path: 'porovnavac',
        loadChildren: () => import('./modules/comparator/comparator.module').then((m) => m.ComparatorModule),
      },
      {
        // THIS ROUTE MUST BE ALWAYS LAST
        // fallback route for unknown "nice" article URLs such as "/kontakt" or "/nase-prodejny"
        path: '',
        loadChildren: () => import('./modules/article/article.module').then((m) => m.ArticleModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      anchorScrolling: 'disabled',
      scrollPositionRestoration: 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
