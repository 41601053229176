import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {

  transform(value?: string | null): unknown {
    if (!value) {
      return "";
    }

    let parts = value.split(" ");
    parts = parts
      .filter(part => !!part)
      .map(part => part.charAt(0));

    if (parts.length > 2) {
      parts.length = 2;
    }

    return parts.join("");
  }

}
