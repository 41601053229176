<ng-container *ngIf="content">

  <div class="promo-block-item stack stack-16">
    <raf-image class="promo-block-icon" *ngIf="icon" [fromCms]="icon"></raf-image>
    <div class="promo-block-content stack stack-8 stack-vertical">
      <raf-text variant="h4" color="black-text">{{content.title}}</raf-text>
      <raf-text>{{content.description}}</raf-text>
    </div>
  </div>

</ng-container>
