<ng-container *transloco="let t">
  <div class="stack stack-vertical stack-4">
    <!-- OLD PRICE -->
    <raf-text [wrap]="wrap" [align]="align" color="grey-text"
              [variant]="size === 'small' ? 'body-small' : 'body-medium'" class="old-price"
              *ngIf="productPrices?.oldPrice?.value" data-cy="old-price">
      <div class="price-label" *ngIf="showTooltip">{{t('GLOBAL.PRICE_INFO.CATALOG_PRICE')}}:</div>
      {{ productPrices?.oldPrice | price }}
      {{ t("GLOBAL.PRICE.WITH_VAT") }}
    </raf-text>

    <!-- OLD PRICE WITH VAT -->
    <raf-text [wrap]="wrap" [align]="align" color="grey-text"
              [variant]="size === 'small' ? 'body-small' : 'body-medium'" class="old-price"
              *ngIf="productPrices?.oldPriceWithVat?.value" data-cy="old-price">
      <div class="price-label" *ngIf="showTooltip">{{t('GLOBAL.PRICE_INFO.CATALOG_PRICE_WITH_VAT')}}:</div>
      {{ productPrices?.oldPriceWithVat | price }}
      {{ t("GLOBAL.PRICE.WITH_VAT") }}
    </raf-text>

    <!-- MAIN PRICE WITH VAT > 0 -->
    <raf-text weight="heavy" class="main-price mt-3" [wrap]="wrap" [align]="align"
              [variant]="forcedSize || (size === 'small' ? 'h4' : 'h3')" *ngIf="productPrices?.priceWithVat?.value"
              [color]="color" data-cy="price-with-vat">
      <div class="price-label" *ngIf="showTooltip">{{t('GLOBAL.PRICE_INFO.SELL_PRICE_WITH_VAT')}}:</div>
      <span
        [innerHTML]="forcedSize ? (productPrices?.priceWithVat?.value | amount) : (productPrices?.priceWithVat?.value | amount | smallDecimals)"></span>
      <ng-container *ngTemplateOutlet="currencyTmpl"></ng-container>
      {{ t("GLOBAL.PRICE.WITH_VAT") }}
    </raf-text>

    <!-- FREE WITH VAT -->
    <raf-text [wrap]="wrap" [align]="align" [variant]="forcedSize || (size === 'small' ? 'h4' : 'h3')"
              *ngIf="productPrices?.priceWithVat?.value === 0" [color]="freeColor" data-cy="free">
      {{ t("GLOBAL.PRICE.FREE") }}
    </raf-text>

    <!-- PRICE WITHOUT VAT -->
    <raf-text [wrap]="wrap"
              [align]="align" color="grey-text" [variant]="size === 'small' ? 'body-small' : 'body-medium'"
              *ngIf="productPrices?.price?.value" data-cy="price-without-vat">
      {{ productPrices?.price | price }}
      {{ t("GLOBAL.PRICE.WITHOUT_VAT") }}
    </raf-text>

    <!-- FREE WITHOUT VAT -->
    <raf-text [wrap]="wrap" [align]="align" color="grey-text"
              [variant]="size === 'small' ? 'body-small' : 'body-medium'" *ngIf="productPrices?.price?.value === 0"
              data-cy="free-without-vat">
      {{ t("GLOBAL.PRICE.FREE") }}
    </raf-text>

    <!-- UNIT PRICE -->
    <raf-text [wrap]="wrap" [align]="align" color="grey-text" [variant]="size === 'small' ? 'body-small' : 'body-medium'"
              *ngIf="unitPriceVisible && productPrices?.unitPriceWithVat?.value" data-cy="unit-price">
      ({{ productPrices?.unitPriceWithVat | price}}/{{ unit }} {{ t("GLOBAL.PRICE.WITH_VAT") }})
    </raf-text>
  </div>

  <ng-template
    #currencyTmpl>{{ productPrices?.priceWithVat?.currency | rafCurrency }}</ng-template>
</ng-container>
