import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PromoText } from '../../model/promo-text.model';
import { LayoutService } from '../../../core/services/layout.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'raf-promo-block',
  templateUrl: './promo-block.component.html',
  styleUrls: ['./promo-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoBlockComponent {
  @Input()
  label?: string;

  @Input()
  content?: PromoText[];

  isMobile$: Observable<boolean>;

  constructor(private layoutService: LayoutService) {
    this.isMobile$ = this.layoutService.mdDown$;
  }
}
