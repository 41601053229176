import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductLabel } from '../../../model/product-label.model';

@Component({
  selector: 'raf-product-label',
  templateUrl: './product-label.component.html',
  styleUrls: ['./product-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductLabelComponent {

  @Input()
  label?: ProductLabel | null;

  get allowCustomColor(): boolean {
    return this.label?.type === 'CUSTOM' ||
      this.label?.type === 'BEST_SELLER' || (!this.label?.color && !this.label?.fontColor);
  }

  get colorStyle(): any {
    const style: any = {};
    if (!this.label?.color) {
      style.color = this.label?.color
    }

    if (!this.label?.fontColor) {
      style.color = this.label?.fontColor
    }

    return style
  }
}
