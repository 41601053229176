import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'raf-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent {
  @Input()
  variant: TextVariant = 'body-medium';

  @Input()
  link = false;

  @Input()
  @HostBinding('class.native-underline')
  nativeUnderline = false;

  @Input()
  weight: 'normal' | 'medium' | 'bold' | 'heavy' = 'normal';

  @Input()
  gutterBottom = false; // normalized spacing under the text

  @Input()
  wrap = true; // normalized spacing under the text

  @Input()
  color?: TextColor = 'default';

  @Input()
  align: TextAlign = 'left';

  @Input()
  @HostBinding('class.absoluteEllipsisWrapper')
  absoluteEllipsis = false;

  @Input()
  @HostBinding('class')
  get classes(): string {
    return this.variant;
  }
}

export type TextVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'body-large' | 'body-medium' | 'body-small' | 'caption';
export type TextAlign = 'left' | 'center' | 'right';
export type TextColor =
  | 'primary-color'
  | 'accent-color'
  | 'warn-color'
  | 'success-color'
  | 'primary-text'
  | 'black-text'
  | 'grey-text'
  | 'red-text'
  | 'light-text'
  | 'default'
  | 'inherit';
