<ng-container [ngSwitch]="variant">
  <h1 class="text-component  {{color === 'default' ? 'primary-color' : color}}" [class.weight-heavy]="weight === 'heavy'" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'"  [class.gutterBottom]="gutterBottom" *ngSwitchCase="'h1'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h1>
  <h2 class="text-component {{color === 'default' ? 'primary-text' : color}}" [class.weight-heavy]="weight === 'heavy'" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" *ngSwitchCase="'h2'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h2>
  <h3 class="text-component {{color === 'default' ? 'primary-text' : color}}" [class.weight-heavy]="weight === 'heavy'" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" *ngSwitchCase="'h3'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h3>
  <h4 class="text-component {{color === 'default' ? 'primary-text' : color}}" [class.weight-heavy]="weight === 'heavy'" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" *ngSwitchCase="'h4'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h4>
  <h5 class="text-component {{color === 'default' ? 'primary-text' : color}}" [class.weight-heavy]="weight === 'heavy'"  [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" *ngSwitchCase="'h5'">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h5>
  <p class="text-component mat-body-2 {{'weight-'+weight}} {{color === 'default' ? 'primary-text' : color}}" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" [class.link]="link" *ngSwitchCase="'body-large'">
    <ng-container *ngTemplateOutlet="inlineContent"></ng-container>
  </p>
  <p class="text-component mat-body-1 {{'weight-'+weight}} {{color === 'default' ? 'primary-text' : color}}" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" [class.link]="link" *ngSwitchCase="'body-medium'">
    <ng-container *ngTemplateOutlet="inlineContent"></ng-container>
  </p>
  <p class="text-component mat-body-0 {{'weight-'+weight}} {{color === 'default' ? 'primary-text' : color}}" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'" [class.gutterBottom]="gutterBottom" [class.link]="link" *ngSwitchCase="'body-small'">
    <ng-container *ngTemplateOutlet="inlineContent"></ng-container>
  </p>
  <p class="text-component mat-body-1 {{'weight-'+weight}} {{color === 'default' ? 'primary-text' : color}}" [class.text-right]="align === 'right'" [class.text-wrap]="wrap" [class.text-center]="align === 'center'"[class.gutterBottom]="gutterBottom" [class.link]="link" *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="inlineContent"></ng-container>
  </p>
</ng-container>

<ng-template #inlineContent>
  <span class="text-component-inline">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
