export class ObjectUtils {
  static isValueEmpty(value?: object | string | number | boolean | null): boolean {
    if (value === undefined || value === null) {
      return true;
    }
    if (typeof value === 'object') {
      return !Object.values(value).some((val) => val === undefined || val === null);
    }
    return false;
  }

  static areValuesSame<T>(value1?: T | null, value2?: T | null): boolean {
    let str1 = '';
    if (value1 && typeof value1 === 'object' && !Array.isArray(value1)) {
      str1 = JSON.stringify(value1, Object.keys(value1).sort());
    } else {
      str1 = JSON.stringify(value1);
    }

    let str2 = '';
    if (value2 && typeof value2 === 'object' && !Array.isArray(value2)) {
      str2 = JSON.stringify(value2, Object.keys(value2).sort());
    } else {
      str2 = JSON.stringify(value2);
    }

    return str1 === str2;
  }

  static removeEmptyProperties<T>(obj?: T | null): T | object | undefined {
    if (typeof obj !== 'object' || Array.isArray(obj)) {
      return obj;
    }
    if (!obj) {
      return;
    }
    return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined && value !== null));
  }
}
