import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable()
export class LoadingService {

  private loadingFinishedSubject$: Subject<void> = new Subject<void>();

  loadingFinished(): void {
    this.loadingFinishedSubject$.next();
  }

  get loadingFinished$(): Observable<void> {
    return this.loadingFinishedSubject$.asObservable();
  }
}
