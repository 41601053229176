import { APP_INITIALIZER, isDevMode, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger } from '../services/logger.service';
import { environment } from '../../../environments/environment';

/**
 * Create a script element in DOM and initiate Facebook tracking.
 */
export function FacebookPixelInitializer(document: Document, logger: Logger) {
  return async () => {
    if (!environment.facebookPixelCodes || environment.facebookPixelCodes.length === 0) {
      if (!isDevMode()) {
        logger.log('Empty tracking codes for Facebook Pixel. Skipping initialization.');
      }
      return;
    }

    if (!document) {
      logger.error('Document interface unavailable.');
      return;
    }

    const fbPixelScript: HTMLScriptElement = document.createElement('script');
    fbPixelScript.innerHTML = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
${environment.facebookPixelCodes.map(code => `fbq('init', '${code}');`).join('\n')}
fbq('track', 'PageView');`;

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    if (!head || !head.append) {
      logger.error('Head element unavailable.');
      return;
    }
    head.append(fbPixelScript);

    environment.facebookPixelCodes.forEach(code => {
      const fbPixelNoScript: HTMLElement = document.createElement('noscript');
      fbPixelNoScript.innerHTML = `<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=${code}&ev=PageView&noscript=1"
/>`;
      head.append(fbPixelNoScript);
    });
  };
}

export const facebookPixelInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: FacebookPixelInitializer,
  deps: [DOCUMENT, Logger],
};
