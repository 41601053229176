import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BehaviorSubject, delay, map, Observable, Subject, takeUntil } from 'rxjs';

import { LoginDialogComponent } from '../../login-dialog/login-dialog.component';
import { CmsService } from '../../../../core/services/cms.service';
import { ApiResource } from '../../../model/api-resource.model';
import { Layout } from '../../../model/layout.model';
import { NavigationNode } from '../../../model/navigation-node.model';
import { ProductCategory } from '../../../model/product-category.model';
import { CatalogService } from '../../../../core/services/catalog.service';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs.service';
import { Breadcrumb } from '../../../model/breadcrumb.model';
import { DialogService } from '../../../../core/services/dialog.service';
import { UserService } from '../../../../core/services/user.service';
import { LogoutDialogComponent } from '../../logout-dialog/logout-dialog.component';
import { DIALOG_SIZE } from '../../../const/dialog-sizes.const';
import { User } from '../../../model/user.model';
import { Destroyed } from '../../../decorators/destroyed';
import { LayoutService } from '../../../../core/services/layout.service';

@Component({
  selector: 'raf-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicLayoutComponent {
  layoutResource$: Observable<ApiResource<Layout>>;
  layoutLoading$: Observable<boolean>;
  layout$: Observable<Layout | null>;
  topMenu$: Observable<NavigationNode[]>;
  productCategoriesLoading$: Observable<boolean>;
  productCategories$: Observable<ProductCategory[] | null>;
  breadcrumbs$: Observable<Breadcrumb[]>;
  layoutLight$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  anonymous$: Observable<boolean>;
  user$: Observable<User | null>;
  tablet$: Observable<boolean>;

  @Destroyed
  destroyed$!: Subject<void>;

  constructor(
    private cmsService: CmsService,
    private dialog: DialogService,
    private catalogService: CatalogService,
    private breadcrumbService: BreadcrumbsService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService
  ) {
    this.productCategoriesLoading$ = this.catalogService.categories$.pipe(map((resource) => resource.loading));
    this.productCategories$ = this.catalogService.categories$.pipe(map((resource) => resource.data));
    this.layoutResource$ = this.cmsService.layout$;
    this.layoutLoading$ = this.layoutResource$.pipe(map((resource) => resource.loading));
    this.layout$ = this.layoutResource$.pipe(map((resource) => resource.data));
    this.topMenu$ = this.layoutResource$.pipe(map((resource) => resource.data?.upperNav || []));
    this.tablet$ = this.layoutService.lgDown$;
    this.anonymous$ = this.userService.anonymous$;
    this.user$ = this.userService.user$;
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
    this.breadcrumbs$.pipe(takeUntil(this.destroyed$), delay(0)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  openLoginForm(): void {
    this.dialog.open(LoginDialogComponent, {});
  }

  onLogout() {
    this.dialog
      .open(LogoutDialogComponent, {
        maxWidth: DIALOG_SIZE.confirm,
      })
      .afterClosed()
      .subscribe((shouldLogout) => {
        if (shouldLogout) {
          this.userService.logout().subscribe();
        }
      });
  }
}
