import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TransferHttpCacheModule } from '@nguniversal/common';

import { CoreModule } from './core/core.module';
import { TranslocoRootModule } from './transloco-root.module';
import { AppComponent } from './app.component';
import { configRelatedInitializerProvider } from './core/providers/config-related-initializer-provider';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { SsrTransferStateService } from './core/services/ssr-transfer-state.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule,
  ],
  providers: [configRelatedInitializerProvider, SsrTransferStateService],
})
export class AppModule {}
