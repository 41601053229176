import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'raf-conditional-link',
  templateUrl: './conditional-link.component.html',
  styleUrls: ['./conditional-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionalLinkComponent {
  @Input() url?: string;
  @Input() target: '_blank' | '_self' = '_blank';
}
