import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { ProductSearchResult } from '../../shared/model/product-search-result.model';
import { environment } from '../../../environments/environment';
import { ProductSearchFilters } from '../../shared/model/product-search-filters.model';
import { ProductDetail } from '../../shared/model/product.model';
import { ApiResource } from '../../shared/model/api-resource.model';
import { Logger } from './logger.service';
import { ProductStock } from '../../shared/model/stock.model';
import { SKIP_HTTP_ERROR_ALERT } from '../interceptors/http-error.interceptor';

interface ProductStore {
  [productId: string]: BehaviorSubject<ApiResource<ProductDetail>>;
}

@Injectable()
export class ProductService {

  private DEFAULT_PAGE = 1;
  private DEFAULT_PAGE_SIZE = 24;

  private productStore: ProductStore = {};

  constructor(private logger: Logger, private http: HttpClient) {}

  getProducts(filters: ProductSearchFilters, basic = false): Observable<ProductSearchResult> {
    const params = new HttpParams({fromObject: {
        ...filters,
        currentPage: filters.currentPage || this.DEFAULT_PAGE,
        pageSize: filters.pageSize || this.DEFAULT_PAGE_SIZE,
        fields: basic ? 'BASIC' : 'SEARCH'
    }})
    return this.http.get<ProductSearchResult>(`${environment.b2cEndpoint}/products`, { params });
  }

  getProductDetail(productId: string): Observable<ApiResource<ProductDetail>> {

    // if product is not cached yet, initialize it
    if (!this.productStore[productId]) {
      this.productStore[productId] = new BehaviorSubject<ApiResource<ProductDetail>>({
        data: null,
        loading: false
      });
    }

    // if it has no data and is not loading, fetch it
    if (!this.productStore[productId].value.data && !this.productStore[productId].value.loading) {
      this.loadProductDetail(productId);
    }

    return this.productStore[productId];
  }

  private loadProductDetail(productId: string): void {
    this.productStore[productId].next({ loading: true, data: null, error: false });
    const context = new HttpContext().set(SKIP_HTTP_ERROR_ALERT, true);
    this.http
      .get<ProductDetail>(`${environment.b2cEndpoint}/products/${productId}`, {context})
      .pipe(
        catchError((err) => {
          this.productStore[productId].next({ loading: false, data: null, error: true });
          this.logger.error(err);
          return throwError(err);
        })
      )
      .subscribe((res: ProductDetail) => {
        this.productStore[productId].next({ loading: false, data: res, error: false });
      });
  }

  getProductDetails(productIds: string[]): Observable<ApiResource<ProductDetail>>[] {
    // filter only uncached products, the rest doesn't need to be fetched from server
    const uncachedIds = productIds.filter(productId => !this.productStore[productId]);

    // if product is not cached yet, initialize it
    uncachedIds.forEach(productId => {
      this.productStore[productId] = new BehaviorSubject<ApiResource<ProductDetail>>({
        data: null,
        loading: false
      });
    })

    // if it has no data and is not loading, fetch it
    const productsToFetch = uncachedIds.filter(productId =>
      !this.productStore[productId].value.data && !this.productStore[productId].value.loading);

    if (productsToFetch?.length) {
      this.loadProductDetails(productsToFetch);
    }

    return productIds.map(productId => this.productStore[productId]);
  }

  private loadProductDetails(productIds: string[]): void {

    productIds.forEach(productId => {
      if (!this.productStore[productId]) {
        this.productStore[productId] = new BehaviorSubject<ApiResource<ProductDetail>>({
          data: null,
          loading: true,
          error: false
        });
      } else {
        this.productStore[productId].next({ loading: true, data: null, error: false });
      }
    });

    const params = new HttpParams({fromObject: {productIds}});
    this.http
      .get<ProductDetail[]>(`${environment.b2cEndpoint}/products/set`, {params})
      .pipe(
        catchError((err) => {
          productIds.forEach(productId => {
            this.productStore[productId].next({ loading: false, data: null, error: true });
          });
          this.logger.error(err);
          return throwError(err);
        })
      )
      .subscribe((res: ProductDetail[]) => {
        res?.forEach(productDetail => {
          this.productStore[productDetail.code].next({ loading: false, data: productDetail, error: false });
        });
        // if requested products did not arrive in response (not found?), disable loading for them
        productIds?.filter(productId => {
          return this.productStore[productId].value.loading;
        }).forEach(productId => {
          this.productStore[productId].next({ loading: false, data: null, error: true });
        });
      });
  }

  getProductStock(productId: string): Observable<ProductStock> {
    return this.http
      .get<ProductStock>(`${environment.b2cEndpoint}/products/${productId}/dispo`);
  }
}
