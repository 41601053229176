import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiResource } from '../../shared/model/api-resource.model';
import { Logger } from './logger.service';
import { Article } from '../../shared/model/article.model';
import { Layout, LayoutResource } from '../../shared/model/layout.model';
import { Homepage, HomepageResource } from '../../shared/model/homepage.model';
import { loadedOrLoading } from '../../shared/utils/api-utils';
import { SKIP_HTTP_ERROR_ALERT } from '../interceptors/http-error.interceptor';

@Injectable()
export class CmsService {
  private layoutSubject$: BehaviorSubject<ApiResource<Layout>> = new BehaviorSubject<ApiResource<Layout>>({
    loading: false,
    data: null,
  });

  constructor(private http: HttpClient, private logger: Logger) {}

  get layout$(): Observable<ApiResource<Layout>> {
    if (!loadedOrLoading(this.layoutSubject$.value)) {
      this.loadLayout();
    }

    return this.layoutSubject$.asObservable();
  }

  loadLayout(): void {
    this.layoutSubject$.next({ loading: true, data: null, error: false });

    this.http
      .get<LayoutResource>(`${environment.cmsEndpoint}/layout?populate=*`)
      .pipe(
        catchError((err) => {
          this.layoutSubject$.next({ loading: false, data: null, error: true });
          this.logger.error(err);
          return throwError(err);
        })
      )
      .subscribe((res: LayoutResource) => {
        this.processLayout(res);
        this.layoutSubject$.next({ loading: false, data: res.data?.attributes, error: false });
      });
  }

  getArticle(articleId: string): Observable<Article> {
    const context = new HttpContext().set(SKIP_HTTP_ERROR_ALERT, true);
    return this.http.get<Article>(`${environment.cmsEndpoint}/article/${articleId}`, {context});
  }

  getHomepage(): Observable<Homepage> {
    return this.http
      .get<HomepageResource>(`${environment.cmsEndpoint}/homepage`)
      .pipe(map((resource) => resource?.data?.attributes));
  }

  getPasswordHint(): Observable<string> {
    return this.http.get(`${environment.cmsEndpoint}/cms-text/password-hint`, { responseType: 'text' });
  }

  private processLayout(res: LayoutResource) {
    // sort images
    res.data?.attributes?.footerImages?.sort((a, b) => a.id - b.id);

    // make sure that each navigation url starts with '/'
    res.data?.attributes?.footerNav?.forEach(navGroup => navGroup.nodes?.forEach(node => {
      if (!node.url?.startsWith('/')) {
        node.url = `/${node.url || ''}`;
      }
    }))
  }
}
