import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TextColor } from '../text/text.component';
import { ProductPrices } from '../../model/price.model';

@Component({
  selector: 'raf-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceComponent {
  @Input()
  productPrices?: ProductPrices | null;

  @Input()
  unitPriceVisible = true;

  @Input()
  unit?: string; // for unit price, e.g. 'kg' for 100 Kč/kg

  @Input()
  color: TextColor = 'red-text'; // for special cases where we want to show main prices with or without VAT with specific color

  @Input()
  wrap = true;

  @Input()
  showTooltip = false;

  @Input()
  freeColor: TextColor = 'success-color';

  @Input()
  size?: 'normal' | 'small' = 'normal';

  @Input()
  forcedSize?: 'h4' | 'h3' | 'body-small' | 'body-medium'; // for special cases where we want to show main prices with or without VAT with specific font size

  @Input()
  align: 'left' | 'right' = 'right';
}
