import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

type StatusBlockMotive = 'success-with-document' | 'success' | 'error';

@Component({
  selector: 'raf-status-block',
  templateUrl: './status-block.component.html',
  styleUrls: ['./status-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBlockComponent {
  @Input()
  mainTitle?: string;

  @Input()
  subTitle?: string;

  @Input()
  set motive(motive: StatusBlockMotive) {
    let url = '';
    switch (motive) {
      case 'success-with-document': {
        url = 'assets/images/success-document-motive.svg';
        break;
      }
      case 'success': {
        url = 'assets/images/success-motive.svg';
        break;
      }
      case 'error': {
        url = 'assets/images/error-motive.svg';
        break;
      }
    }

    this.motiveName = motive;
    this.motivePath = this.sanitizer.bypassSecurityTrustUrl(url);
  }

  motiveName?: StatusBlockMotive;
  motivePath?: SafeUrl;

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  constructor(private sanitizer: DomSanitizer) {}
}
