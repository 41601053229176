<ng-container *transloco="let t">
  <div class="menu-strip">
    <raf-container>
      <div class="menu-strip-container">
        <nav class="stack stack-4" *ngIf="menuItems">
          <a *ngFor="let item of menuItems" [routerLink]="item.url" (click)="onNav(item)" class="mat-button-extra-small"
             mat-button color="secondary" data-cy="menu-link">
            {{ item.text }}</a>
        </nav>
        <span class="login">
        <ng-container *ngIf="anonymous">
          <button class="mat-button-extra-small login-button" mat-button color="secondary"
                  (click)="onLoginClick()" data-cy="login-btn">
            <raf-icon    class="start-icon"  size="16px" icon="lock"></raf-icon>
            {{ t("LAYOUT.LOGIN") }}
          </button>
        </ng-container>
          <ng-container *ngIf="!anonymous">
          <button class="mat-button-extra-small login-button" mat-button color="secondary"
                  (click)="onLogoutClick()" data-cy="logout-btn">
            <raf-icon    class="start-icon"  size="16px" icon="lock"></raf-icon>
            {{ t("LAYOUT.LOGOUT") }}
          </button>
        </ng-container>
      </span>
      </div>
    </raf-container>
  </div>
</ng-container>
