<div class="image-container-wrap"
     [class.with-border]="withBorder"
     [class.small-size]="size==='small'"
     [class.img-loaded]="(placeholderVisible$ | async) === false">
  <div
    #imageContainer
    class="image-container"
    [class.withTitle]="image?.caption"
  >
    <img
      *ngIf="imageVisible$ | async"
      [class]="imgCssClass"
      [src]="image?.url"
      (load)="onImageLoaded()"
      (error)="onImageError()"
      [alt]="alternativeText || image?.alternativeText"
      [class.d-none]="placeholderVisible$ | async"
      [attr.fetchpriority]="fetchPriority"
    />
    <div *ngIf="placeholderVisible$ | async" class="image-placeholder"></div>
    <div class="image-title" *ngIf="showCaption && image?.caption">{{ image?.caption }}</div>
  </div>
</div>


