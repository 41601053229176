import { APP_INITIALIZER, InjectionToken, isDevMode, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger } from '../services/logger.service';
import { GtmService } from '../services/gtm.service';

export const RAF_GTM_TRACKING_CODE = new InjectionToken<string>('RAF_GTM_TRACKING_CODE');

/**
 * Create a script element in DOM and initiate GTM tracking.
 */
export function GtmInitializer(gtmTrackingCode: string, document: Document, logger: Logger, gtmService: GtmService) {
  return async () => {
    if (!gtmTrackingCode) {
      if (!isDevMode()) {
        logger.log('Empty tracking code for Google Tag Manager. Make sure to provide one when using GtmModule.');
      }
      return;
    }

    if (!document) {
      if (!isDevMode()) {
        logger.error('Document interface unavailable. GtmModule works in browser only.');
      }
      return;
    }

    const gtmScript: HTMLScriptElement = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmTrackingCode}');`

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.prepend(gtmScript);

    gtmService.initConsents();
  };
}

export const gtmInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: GtmInitializer,
  deps: [
    RAF_GTM_TRACKING_CODE,
    DOCUMENT,
    Logger,
    GtmService
  ]
};
