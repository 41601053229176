<ng-container *transloco="let t">
  <div class="search-container-backdrop" (click)="closeSearch()" *ngIf="searchOpen$ | async"></div>
  <div class="search-container" [class.search-open]="searchOpen$ | async">
    <raf-input (focusChange)="onFocusChange($event)" [noPadding]="true" placeholder="SEARCH.PLACEHOLDER"
               (keyup.enter)="onNavToSearchResults()" suffixIcon="search" [formControl]="searchFC"
               (suffixClick)="onNavToSearchResults()" autocomplete="off" [suffixTitle]="t('SEARCH.ICON_TITLE')"></raf-input>

    <div class="search-dropdown" @showSearchDrop *ngIf="searchOpen$ | async">
      <div class="search-dropdown-in">
        <div class="search-results" *ngIf="resultsVisible$ | async" data-cy="search-results">
          <raf-loading variant="inline" *ngIf="productsLoading$ | async; else resultsTmpl"></raf-loading>

          <ng-template #resultsTmpl>

            <div class="search-results-products">
            <!-- PRODUCTS -->
            <raf-text variant="h5" [gutterBottom]="true">{{ t("SEARCH.PRODUCTS") }}</raf-text>
            <raf-products
              *ngIf="products$ | async as products"
              variant="list"
              [readOnly]="true"
              [stockVisible]="false"
              [quantityVisible]="false"
              [products]="products.slice(0, 5)"
              (clicked)="reset()"
            ></raf-products>
            <div *ngIf="!(products$ | async)?.length">{{ t("SEARCH.NO_PRODUCTS") }}</div>

            </div>

            <mat-divider class="mt-4 mb-4"></mat-divider>

            <!-- CATEGORIES -->
            <raf-text variant="h5" [gutterBottom]="true">{{ t("SEARCH.CATEGORIES") }}</raf-text>


            <div class="subcategories-container">
              <div class="subcategories-rows">
                <ng-container *ngFor="let category of (categories$ | async)?.slice(0, 10)">
                  <div class="subcategories-item">
                    <a class="link" [routerLink]="category.url"  (click)="reset()" data-cy="category-result">
                      <raf-text variant="body-small" color="grey-text">{{ category.name }} </raf-text>
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>


            <div *ngIf="!(categories$ | async)?.length">{{ t("SEARCH.NO_CATEGORIES") }}</div>

            <!-- SHOW MORE BUTTON -->
            <div class="d-flex justify-content-center mt-3">
              <a mat-button
                 class="mat-button-extra-small"
                 color="secondary"
                 *ngIf="(categories$ | async)?.length || (products$ | async)?.length"
                 (click)="reset()"
                 [routerLink]="['/vyhledavani']"
                 [queryParams]="{ query: searchFC.value }"
                 data-cy="show-more-btn">{{
                t("SEARCH.SHOW_MORE", { count: (productSearchResult$ | async)?.pagination?.totalItems })
                }}</a>
            </div>

          </ng-template>
        </div>
      </div>
    </div>
  </div>

</ng-container>
