import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product.model';
import { CartEntry } from '../../../model/cart.model';
import { LayoutService } from '../../../../core/services/layout.service';

@Component({
  selector: 'raf-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent {
  @Input()
  variant: 'grid' | 'list' = 'grid';

  @Input()
  readOnly = false;

  @Input()
  priceVisible = true;

  @Input()
  unitPriceVisible = true;

  @Input()
  stockVisible = true;

  @Input()
  quantityVisible = true;

  @Input()
  listItemSize: 'normal' | 'small' = 'small';

  @Input()
  listItemSpace: 'normal' | 'table' = 'normal';

  @Input()
  listVariant: 'simple' | 'paper' = 'simple';

  @Input()
  listDividers?: boolean;

  @Input()
  layout: 'page' | 'with-facets' = 'page';

  @Input()
  products?: Product[] | null;

  @Input()
  entries?: CartEntry[] | null;

  @Input()
  label?: string;

  @Input()
  navDisabled = false;

  @Output()
  quantityChange: EventEmitter<{ entry?: CartEntry; product: Product; quantity: number }> = new EventEmitter<{
    entry?: CartEntry;
    product: Product;
    quantity: number;
  }>();

  @Output()
  remove: EventEmitter<CartEntry | Product> = new EventEmitter<CartEntry | Product>();

  @Output()
  clicked: EventEmitter<{ product: Product | CartEntry, index: number }> =
    new EventEmitter<{ product: Product | CartEntry, index: number }>();

  mobile$: Observable<boolean>;

  constructor(private layoutService: LayoutService) {
    this.mobile$ = this.layoutService.mdDown$;
  }

  onQuantityChange($event: { product: Product; quantity: number }, entry?: CartEntry) {
    this.quantityChange.emit({ entry, product: $event.product, quantity: $event.quantity });
  }

  onRemove(entry: CartEntry | Product) {
    this.remove.emit(entry);
  }

  onClick(product: Product | CartEntry, index: number) {
    this.clicked.emit({ product, index });
  }

  entryTrackByFn(index: number, item: CartEntry) {
    return item.entryNumber;
  }

  productTrackByFn(index: number, item: Product) {
    return item.code;
  }
}
