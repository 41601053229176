import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, map, Observable, Subject, takeUntil } from 'rxjs';
import { Destroyed } from '../../shared/decorators/destroyed';
import { BreakpointsDown } from '../../shared/const/breakpoints.const';

@Injectable()
export class LayoutService {
  @Destroyed
  destroyed$!: Subject<void>;

  renderer: Renderer2;

  smDown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  mdDown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  lgDown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  xlDown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private documentRef: Document,
    private viewportScroller: ViewportScroller
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    breakpointObserver
      .observe(BreakpointsDown.sm)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.smDown$.next(result.matches);
      });

    breakpointObserver
      .observe(BreakpointsDown.md)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.mdDown$.next(result.matches);
      });

    breakpointObserver
      .observe(BreakpointsDown.lg)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.lgDown$.next(result.matches);
      });

    breakpointObserver
      .observe(BreakpointsDown.xl)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.xlDown$.next(result.matches);
      });
  }

  isMatched(value: string): boolean {
    return this.breakpointObserver.isMatched(value);
  }

  /** For special cases only - use breakpoints when possible */
  observe(value: string): Observable<boolean> {
    return this.breakpointObserver.observe(value).pipe(
      takeUntil(this.destroyed$),
      map((result) => result.matches)
    );
  }

  /** Add smooth-scroll class to html element to enable global scroll-behavior: smooth  */
  enableSmoothScroll(): void {
    this.renderer.addClass(this.documentRef.body.parentElement, 'smooth-scroll');
  }

  /** Remove smooth-scroll class from html element to disable global scroll-behavior: smooth  */
  disableSmoothScroll(): void {
    this.renderer.removeClass(this.documentRef.body.parentElement, 'smooth-scroll');
  }

  scrollTo(elementId: string): void {
    this.documentRef.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
