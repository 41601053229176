import { Component, Input } from '@angular/core';

@Component({
  selector: 'raf-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.scss'],
})
export class PaperComponent {
  @Input()
  variant: 'primary' | 'secondary' | 'stroked' | 'success' | 'transparent' = 'primary';

  @Input()
  background?: 'primary' | 'transparent';

  @Input()
  borderRadius: 'medium' | 'small' = 'medium';

  @Input()
  nullBreakpoint?: 'sm' | 'md' | 'lg' | 'all';

  @Input()
  spacing?: 'normal' | 'none';

  @Input()
  fillSpace?: boolean;

  @Input()
  overflow?: 'hidden' | 'auto' | 'y-auto' | 'x-auto';

  @Input()
  error = false;
}
