import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, map, Observable, Subscription } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { AlertMessage, AlertType } from '../../shared/model/alert-message.model';
import { AlertSnackBarComponent } from '../../shared/components/alert-snack-bar/alert-snack-bar.component';
import { ErrorMessage } from '../../shared/model/error-message.model';

@Injectable()
export class AlertService {
  private readonly SUCCESS_DEFAULT_DURATION = 5000;
  private readonly ERROR_DEFAULT_DURATION = 5000;

  messages$: BehaviorSubject<AlertMessage[]> = new BehaviorSubject<AlertMessage[]>([]);
  snackBarRef: MatSnackBarRef<AlertSnackBarComponent> | null = null;
  snackBarSubscription: Subscription | null = null;

  constructor(private snackBar: MatSnackBar, private translocoService: TranslocoService) {}

  show(message: string, type: AlertType = 'ERROR', duration?: number): void {
    const alertMessage: AlertMessage = {
      message,
      type,
      duration,
    };
    this.showMultiple([alertMessage]);
  }

  showTranslated(translocoKey: string, alertType: AlertType = 'ERROR', duration?: number): void {
    this.translocoService.selectTranslate(translocoKey).subscribe((value) => {
      this.show(value, alertType, duration);
    });
  }

  showMultipleTranslated(messages: AlertMessage[]): void {
    this.translocoService.selectTranslate(messages.map(m => m.message)).subscribe(values => {
      const translatedMessages = messages.map((m, idx) => ({...m, message: values[idx]}));
      this.showMultiple(translatedMessages);
    });
  }

  showMultiple(messages: AlertMessage[]): void {
    if (this.snackBarRef) {
      this.messages$.next([...this.messages$.value, ...messages]);
    } else {
      if (this.snackBarSubscription) {
        this.snackBarSubscription.unsubscribe();
      }
      this.messages$.next(messages);
      this.snackBarRef = this.snackBar.openFromComponent(AlertSnackBarComponent, {
        horizontalPosition: "center",
        verticalPosition: "bottom",
        data: {
          messages$: this.messages$,
          closeCallback: this.clearMessages,
        },
        panelClass: 'snackBarAlert',
      });
      this.snackBarSubscription = this.snackBarRef.afterDismissed().subscribe(() => (this.snackBarRef = null));
    }

    // for each message set the timer if duration is defined
    messages.forEach((message) => {
      // set default duration for success messages
      if (!message.duration && message.type === 'SUCCESS') {
        message.duration = this.SUCCESS_DEFAULT_DURATION;
      }

      if (!message.duration && message.type === 'ERROR') {
        message.duration = this.ERROR_DEFAULT_DURATION;
      }

      if (message.duration) {
        setTimeout(() => this.clearMessages([message]), message.duration);
      }
    });
  }

  parseErrorsFromResponse(error: HttpErrorResponse) {
    if (error.status === 503) {
      this.translocoService.selectTranslate('ERROR.SERVICE_UNAVAILABLE').subscribe((val) => {
        this.show(val, 'ERROR');
      });
    } else if (error.status === 500) {
      this.translocoService.selectTranslate('ERROR.UNKNOWN').subscribe((val) => {
        this.show(val, 'ERROR');
      });
    } else if (error.error) {
      // ErrorMessage[] or string
      if (Array.isArray(error.error)) {
        // to keep relevant params for each message, we can't merge them
        // instead we translate them one by one
        const alerts$: Observable<AlertMessage>[] = (error.error as ErrorMessage[]).map((err) =>
          this.translocoService
            .selectTranslate(err.message, err.params)
            .pipe(map((val) => ({ message: val, type: err.type })))
        );

        combineLatest(alerts$).subscribe((alerts) => {
          this.showMultiple(alerts);
        });
      } else {
        this.translocoService.selectTranslate(error.error).subscribe((val) => {
          this.show(val, 'ERROR');
        });
      }
    } else {
      this.translocoService.selectTranslate('ERROR.UNKNOWN').subscribe((val) => {
        this.show(val, 'ERROR');
      });
    }
  }

  clearMessages(messages: AlertMessage[]): void {
    this.messages$.next(this.messages$.value.filter((message) => !messages.includes(message)));
  }

  clearAll(): void {
    this.closeSnackbar();
    this.messages$.next([]);
  }

  private closeSnackbar(): void {
    this.snackBarRef?.dismiss();
    this.snackBarRef = null;
  }
}
