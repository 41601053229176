<raf-loading *ngIf="layoutLoading$ | async; else tmpl"></raf-loading>
<ng-template #tmpl>
  <div class="layout" [class.layout-light]="layoutLight$ | async">
    <raf-menu-strip
      *ngIf="(tablet$ | async) === false"
      [menuItems]="topMenu$ | async"
      [anonymous]="anonymous$ | async"
      (login)="openLoginForm()"
      (logout)="onLogout()"
    ></raf-menu-strip>
    <raf-header [tabletLayout]="tablet$ | async"
                [layout]="layout$ | async"
                [user]="user$ | async"
                [anonymous]="anonymous$ | async"
                [menuItems]="topMenu$ | async"
                [categories]="productCategories$ | async"
                (login)="openLoginForm()"
                (logout)="onLogout()"></raf-header>

    <ng-container *ngIf="(tablet$ | async) === false">
      <raf-catalog-navigation-menu
        *ngIf="productCategories$ | async as categories"
        [categories]="categories"
      ></raf-catalog-navigation-menu>
    </ng-container>

    <div class="layout-container">
      <div class="layout-content">
        <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
          <raf-breadcrumbs *ngIf="breadcrumbs.length" [breadcrumbs]="breadcrumbs"></raf-breadcrumbs>
        </ng-container>
        <router-outlet></router-outlet>
      </div>
    </div>
    <raf-floating-block [layout]="layout$ | async"></raf-floating-block>
    <raf-footer [layout]="layout$ | async"></raf-footer>
  </div>
</ng-template>
