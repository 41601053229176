import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Stock } from '../../model/stock.model';
import { ProductService } from '../../../core/services/product.service';
import { FormControl } from '@angular/forms';
import { Destroyed } from '../../decorators/destroyed';
import { TextColor } from '../text/text.component';
import { AtpFilterPipe } from '../../pipes/atp-filter/atp-filter.pipe';

@Component({
  selector: 'raf-site-stock-dialog',
  templateUrl: './site-stock-dialog.component.html',
  styleUrls: ['./site-stock-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteStockDialogComponent implements OnInit {

  searchFC: FormControl<string | null> = new FormControl<string>('');
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  siteStocks$: BehaviorSubject<Stock[] | null> = new BehaviorSubject<Stock[] | null>(null);
  onlineStocks$: BehaviorSubject<Stock[] | null> = new BehaviorSubject<Stock[] | null>(null);
  visibleSiteStocks$: Observable<Stock[] | null> | null = null;

  @Destroyed
  destroyed$!: Subject<void>;

  constructor(
    private dialogRef: MatDialogRef<SiteStockDialogComponent>,
    private productService: ProductService,
    private atpFilter: AtpFilterPipe,
    @Inject(MAT_DIALOG_DATA) public productId: string
  ) {
  }

  ngOnInit() {
    if (!this.productId) {
      return;
    }

    this.bindSearch();
    this.loadProducts()
  }

  loadProducts(): void {
    this.loading$.next(true);
    this.productService
      .getProductStock(this.productId)
      .subscribe((res) => {
        this.siteStocks$.next(res.siteStock);
        this.onlineStocks$.next(res.onlineSiteStock);
        this.loading$.next(false);
      });
  }

  bindSearch(): void {
    // @ts-ignore
    const search$: Observable<string | null> = this.searchFC.valueChanges.pipe(
      takeUntil(this.destroyed$), debounceTime(250), startWith(''));
    this.visibleSiteStocks$ = combineLatest([this.siteStocks$, search$]).pipe(
      map(([options, query]) => {
        if (!query) {
          return options;
        }

        return this.atpFilter.transform(options, query);
      })
    );
  }

  siteStockColor(stock: Stock): TextColor {
    if (stock.stockLevel > 0) {
      return 'success-color';
    } else if (stock?.status === "ON_ORDER") {
      return 'grey-text';
    }

    return 'warn-color';
  }

  siteStockStyleWeight(stock: Stock): 'normal' | 'medium' | 'bold' | 'heavy' {
    if (stock.stockLevel > 0) {
      return 'bold';
    }

    return 'normal';
  }

  close() {
    this.dialogRef.close();
  }
}
