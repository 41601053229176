<div class="status-block">
  <div class="status-block_motive {{motiveName}}" ><img [src]="motivePath" alt="image"></div>

  <raf-text data-cy="title" *ngIf="mainTitle" class="status-block_title" variant="h2" color="primary-color">{{mainTitle}}</raf-text>

  <raf-text data-cy="sub-title" *ngIf="subTitle" class="status-block_subtitle" variant="body-large">{{subTitle}}</raf-text>


  <ng-content></ng-content>
</div>
