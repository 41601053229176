import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';

import { BehaviorSubject, catchError, EMPTY, map, mergeMap, Observable, of, share, switchMap, take } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Cart, CartEntry, UpdateCartParams } from '../../shared/model/cart.model';
import { environment } from '../../../environments/environment';
import { Logger } from './logger.service';
import { DialogService } from './dialog.service';
import {
  ContinueShoppingConfirmationDialogComponent
} from '../../shared/components/continue-shopping-confirmation-dialog/continue-shopping-confirmation-dialog.component';
import { User } from '../../shared/model/user.model';
import { DIALOG_SIZE } from '../../shared/const/dialog-sizes.const';
import { SKIP_HTTP_ERROR_ALERT } from '../interceptors/http-error.interceptor';
import { Product } from '../../shared/model/product.model';
import { ProductPrices } from '../../shared/model/price.model';
import { ObjectUtils } from '../../shared/utils/object-utils';
import { GtmService } from './gtm.service';
import { AlertService } from './alert.service';

@Injectable()
export class CartService {
  static readonly MAX_QUANTITY = 999999;
  private readonly ACTIVE_CART_KEY = 'x-raf-web-ui-active-cart-id';


  private cartSubject$: BehaviorSubject<Cart | null> = new BehaviorSubject<Cart | null>(null);
  private pricesSubject$: BehaviorSubject<ProductPrices | null> = new BehaviorSubject<ProductPrices | null>(null);
  private createCart$: Observable<Cart> | null = null;
  private cartLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private loadingPricesSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private anonymous$: Observable<boolean> | null = null;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private http: HttpClient,
    private logger: Logger,
    private dialog: DialogService,
    private router: Router,
    private gtm: GtmService,
    private alertService: AlertService
  ) {
  }

  /** Amount of entries in cart */
  get count$(): Observable<number> {
    return this.cart$.pipe(map((cart) => cart?.entries?.length || 0));
  }

  /** Whether is cart loading */
  get loading$(): Observable<boolean> {
    return this.cartLoading$.asObservable();
  }

  /** Get cart as observable */
  get cart$(): Observable<Cart | null> {
    return this.cartSubject$.asObservable();
  }

  /** Get cart as observable */
  get prices$(): Observable<ProductPrices | null> {
    return this.pricesSubject$.asObservable();
  }

  /** Get cart as observable */
  get loadingPrices$(): Observable<boolean> {
    return this.loadingPricesSubject$.asObservable();
  }

  /** Get or create cart */
  getOrCreate(): Observable<Cart | null> {
    if (!this.cartSubject$.value && !this.cartLoading$.value) {
      // there's no active cart, make one
      return this.createCart().pipe(switchMap(() => this.cart$));
    }

    return this.cart$;
  }

  /** Resets current cart and removes it from local storage */
  removeActiveCart(): void {
    this.removeActiveCartId();
    this.cartSubject$.next(null);
  }

  /** Fetch cart by id, return null if request fails */
  getCart(cartId: string): Observable<Cart | null> {
    const context = new HttpContext().set(SKIP_HTTP_ERROR_ALERT, true);
    return this.http.get<Cart>(`${environment.b2cEndpoint}/carts/${cartId}`, {context}).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }

  checkDeliveryAddress(): Observable<Cart | null> {
    return this.withActiveCart((cart) => {
      return this.http.get<Cart | null>(`${environment.b2cEndpoint}/carts/${cart.id}/check-delivery-address`);
    }, false);
  }

  /** Get active cart id and fetch the cart */
  reloadActiveCart(): Observable<Cart | null> {
    return this.withActiveCart((cart) => {
      return this.getCart(cart.id);
    }, true);
  }

  /** Create new cart, returns single shared observable to avoid multiple requests */
  createCart(): Observable<Cart> {
    if (this.createCart$) {
      return this.createCart$;
    }

    // this.logger.log('new cart created');
    this.createCart$ = this.http.post<Cart>(`${environment.b2cEndpoint}/carts`, {}).pipe(
      tap((cart) => {
        this.saveActiveCartId(cart.id);
        this.cartSubject$.next(cart);
        this.createCart$ = null;
      }),
      share()
    );
    return this.createCart$;
  }

  /** Add item to cart */
  addToCart(product: Product, quantity: number, openConfirmDialog = true, doneFn?: () => void): void {
    this.addMultipleToCart([{product, quantity}], openConfirmDialog, doneFn);
  }

  /** Add multiple items to cart */
  addMultipleToCart(
    items: { product: Product; quantity: number }[],
    openConfirmDialog = true,
    doneFn?: () => void
  ): void {
    // skip when quantity is 0
    items = items.filter((item) => item.quantity > 0);
    if (items.length === 0) {
      if (doneFn) {
        doneFn();
      }
      return;
    }

    this.getOrCreate()
      .pipe(
        take(1),
        switchMap((cart) => {
          if (cart === null) {
            // this.logger.warn('cart not initialised');
            return EMPTY;
          }

          // make sure we don't exceed maximum quantity
          items = this.ensureMaxQuantity(cart, items);
          // items might have been updated or removed, better check if there's anything left to add
          if (items.length === 0) {
            if (doneFn) {
              doneFn();
            }
            return EMPTY;
          }

          this.gtm.addToCart(items);
          const body = items.map((item) => ({productId: item.product.code, quantity: item.quantity}));
          return this.http.post<{ cart: Cart }>(`${environment.b2cEndpoint}/carts/${cart.id}/entries`, body);
        })
      )
      .subscribe((val) => {
        this.cartSubject$.next(val.cart);
        if (openConfirmDialog) {
          this.openConfirmationDialog(items);
        }
        if (doneFn) {
          doneFn();
        }
      });
  }

  /** Update entry quantity */
  updateQuantity(entry: CartEntry, quantity: number): void {

    if (quantity > CartService.MAX_QUANTITY) {
      this.showMaxQuantityReachedAlerts(0);
      quantity = CartService.MAX_QUANTITY;
    }

    if (entry.quantity === quantity) {
      return;
    }
    this.withActiveCart((cart) => {
      const params = new HttpParams({
        fromObject: {
          quantity,
        },
      });
      this.gtm.updateQuantity(entry, quantity, cart);
      return this.http
        .put<{ cart: Cart }>(`${environment.b2cEndpoint}/carts/${cart.id}/entries/${entry.entryNumber}`, {}, {params})
        .pipe(map((res) => res.cart));
    }, true).subscribe();
  }

  /** Update entry quantity */
  removeFromCart(entry: CartEntry): void {
    entry.deleting = true;
    this.withActiveCart((cart) => {
      this.gtm.removeFromCart(entry);
      return this.http.delete<null>(`${environment.b2cEndpoint}/carts/${cart.id}/entries/${entry.entryNumber}`);
    })
      .pipe(switchMap(() => this.reloadActiveCart()))
      .subscribe()
      .add(() => (entry.deleting = false));
  }

  /**
   * Removes empty or same update cart params
   * @param formData
   * @param cart
   * @param forceUpdate
   */
  getUpdateParams(formData: UpdateCartParams, cart: Cart, forceUpdate: boolean = false): UpdateCartParams {
    const params: { [key: string]: unknown } = {};
    Object.keys(formData).forEach((key) => {
      const paramKey = key as keyof UpdateCartParams;
      const cartKey = key as keyof Cart;

      const newValue = ObjectUtils.removeEmptyProperties(formData[paramKey]);
      const oldValue = ObjectUtils.removeEmptyProperties(cart[cartKey]);

      const changed = !ObjectUtils.areValuesSame(newValue, oldValue);

      // set param only if it's different from old value
      if (changed || forceUpdate) {
        if (formData[paramKey] !== null) {
          params[paramKey] = formData[paramKey];
        }
      }
    });

    return params;
  }

  /**
   * Updates carts properties
   * @param params - partial cart with changed properties
   */
  updateCart(params: UpdateCartParams): Observable<Cart | null> {
    return this.withActiveCart((cart) => {
      const mappedParams = this.getUpdateParams(params, cart);
      if (!Object.keys(mappedParams).length) {
        // this.logger.log('No params to update');
        return EMPTY;
      }

      return this.http.put<Cart | null>(`${environment.b2cEndpoint}/carts/${cart.id}`, mappedParams);
    }, true);
  }

  /**
   * Simulates order confirmation
   */
  simulate(): Observable<Cart | null> {
    return this.withActiveCart((res) => {
      return this.http.get<Cart>(`${environment.b2cEndpoint}/carts/${res.id}/simulate`);
    }, false);
  }

  /**
   * Simulates order confirmation
   */
  simulatePrices(): Observable<ProductPrices> {
    return this.cart$.pipe(
      take(1),
      switchMap((cart) => {
        if (cart === null) {
          // this.logger.warn('cart not initialised');
          return EMPTY;
        }
        this.loadingPricesSubject$.next(true);
        return this.http.get<ProductPrices>(`${environment.b2cEndpoint}/carts/${cart.id}/prices`);
      }),
      tap((res) => {
        this.pricesSubject$.next(res);
        this.loadingPricesSubject$.next(false);
      })
    );
  }

  /** Assign given or active anon cart to logged user, then reload the cart */
  assignCart(cartToAssign?: Cart): Observable<Cart | null> {
    if (!cartToAssign && !this.cartSubject$.value) {
      return of(null);
    }

    this.cartLoading$.next(true);

    const assignFn = (cart: Cart) =>
      this.http
        .post<Cart>(`${environment.b2cEndpoint}/carts/${cart.id}/assign`, null)
        .pipe(tap(() => this.cartLoading$.next(false)));

    // if cart is provided, assign it
    if (cartToAssign) {
      return assignFn(cartToAssign);
    }

    // else use current active cart
    return this.withActiveCart(assignFn, true);
  }

  /** Load users last active cart, save it to local storage */
  loadUsersLastActiveCart(user: User): void {
    if (!user.lastActiveCart || user.lastActiveCart === this.getActiveCartId()) {
      this.initializeCart();
      return;
    }

    this.cartLoading$.next(true);
    // set users cart as last active
    this.saveActiveCartId(user.lastActiveCart);
    this.getCart(user.lastActiveCart)
      .subscribe((cart) => {
        this.cartSubject$.next(cart);
      })
      .add(() => {
        this.cartLoading$.next(false);
      });
  }

  /** Save anon flag to avoid circular dependency between UserSerivce and CartService */
  setAnonymous(anonymous$: Observable<boolean>): void {
    this.anonymous$ = anonymous$;
  }

  /** On init, read last active cart id from local storage and fetch the cart */
  private initializeCart(): void {
    const lastActiveCartId = this.getActiveCartId();
    if (lastActiveCartId) {
      this.cartLoading$.next(true);
      this.getCart(lastActiveCartId)
        .pipe(
          mergeMap(this.secureUnfinishedCart), // if requested cart is FINISHED for some reason, drop it and return null
          mergeMap(this.assignCartIfNeeded) // assign cart if user is logged in but the cart is anon
        )
        .subscribe((cart) => {
          this.cartSubject$.next(cart);
        })
        .add(() => {
          this.cartLoading$.next(false);
        });
    } else {
      this.cartLoading$.next(false);
    }

    // if there is no active cart id, skip init and do it when needed (first add)
  }

  /** Calls function with currently active cart provided as first argument */
  private withActiveCart(fn: (cart: Cart) => Observable<Cart | null>, updateCart = false): Observable<Cart | null> {
    return this.cart$.pipe(
      take(1),
      switchMap((cart) => {
        if (cart === null) {
          // this.logger.warn('cart not initialised');
          return EMPTY;
        }
        return fn(cart);
      }),
      tap((res) => updateCart && this.cartSubject$.next(res))
    );
  }

  /** If provided cart is finished, drop it, else just return the original object.
   * This method assumes that the provided cart is always the active one!
   * */
  private secureUnfinishedCart = (cart: Cart | null): Observable<Cart | null> => {
    if (cart && cart.status === 'FINISHED') {
      this.removeActiveCart();
      return of(null);
    }
    return of(cart);
  };

  /** Assign given cart if current user is logged in but the cart is flagged as anonymous */
  private assignCartIfNeeded = (cart: Cart | null): Observable<Cart | null> => {
    if (!cart || !this.anonymous$) {
      return of(cart);
    }

    return this.anonymous$.pipe(
      take(1),
      mergeMap((anon) => {
        if (cart.user === 'ANONYMOUS' && !anon) {
          return this.assignCart(cart);
        }
        return of(cart);
      })
    );
  };

  /** Get active cart id from localstorage, works in browser only */
  private getActiveCartId(): string | null {
    if (isPlatformServer(this.platformId)) {
      return null;
    }
    return localStorage.getItem(this.ACTIVE_CART_KEY);
  }

  /** Set active cart id to localstorage, works in browser only */
  private saveActiveCartId(cartId: string): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    localStorage.setItem(this.ACTIVE_CART_KEY, cartId);
  }

  /** Remove active cart id from localstorage, works in browser only */
  private removeActiveCartId(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    localStorage.removeItem(this.ACTIVE_CART_KEY);
  }

  private openConfirmationDialog(items: { product: Product; quantity: number }[]): void {
    const dialogRef = this.dialog.open(ContinueShoppingConfirmationDialogComponent, {
      maxWidth: DIALOG_SIZE.small,
      data: {items, variant: 'cart'},
    });
    dialogRef.afterClosed().subscribe((navRoute) => {
      if (navRoute) {
        this.router.navigate([navRoute]);
      }
    });
  }

  /** For each added item check its current quantity in cart and make sure we don't exceed the maximum allowed value.
   * Fix the quantity to the max value or remove the item completely if the maximum is already reached */
  private ensureMaxQuantity(cart: Cart, items: { product: Product; quantity: number }[]):
    { product: Product; quantity: number }[] {

    return items.map(item => {

      // if added product doesn't exist in cart, skip it
      const existingEntry = cart.entries?.find(entry => entry.product.code === item.product.code);
      if (!existingEntry) {
        return item;
      }

      // calculate max addable amount
      const currentQuantity = existingEntry.quantity;
      const maxQuantityToAdd = CartService.MAX_QUANTITY - currentQuantity;
      // if new quantity doesn't exceed the max, skip
      if (item.quantity <= maxQuantityToAdd) {
        return item;
      }
      // if new quantity exceeds the max, fix the value to the max
      this.showMaxQuantityReachedAlerts(maxQuantityToAdd);
      return {...item, quantity: maxQuantityToAdd};
    }).filter(item => item.quantity > 0); // filter items with invalid result
  }

  private showMaxQuantityReachedAlerts(newAmount: number): void {
    this.alertService.showTranslated('ALERTS.CART.MAX_QUANTITY_REACHED', 'WARN', 15000);
    if (newAmount > 0) {
      this.alertService.showTranslated('ALERTS.CART.MAX_QUANTITY_REACHED_ITEM_UPDATED', 'WARN', 15000);
    } else {
      this.alertService.showTranslated('ALERTS.CART.MAX_QUANTITY_REACHED_ITEM_REMOVED', 'WARN', 15000);
    }
  }
}
