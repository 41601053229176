import { APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import {AppConfigService} from "../services/app-config.service";
import {SentryService} from "../services/sentry.service";
import { isPlatformBrowser } from '@angular/common';

export const configRelatedInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: (appConfigService: AppConfigService, sentryService: SentryService, platformId: object) => {
    return () => {
      return appConfigService.loadAppConfig().then((appConfig) => {
        if (isPlatformBrowser(platformId)) {
          sentryService.initSentry(appConfig);
        }
        // gtmService.initGtm(appConfig);
      });
    };
  },
  multi: true,
  deps: [AppConfigService, SentryService, PLATFORM_ID],
};
