import { Subject } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Destroyed(target: { ngOnDestroy?: (...args: any[]) => void; [key: string]: any }, key: string) {
  // console.log('creating Destroyed for new target', target);

  const secretKey = `_${key}`;

  Object.defineProperty(target, key, {
    get: function() {
      if (this[secretKey]) {
        return this[secretKey];
      }
      // console.log('creating new destroyed$ subject for', target.constructor.name);
      this[secretKey] = new Subject();
      return this[secretKey];
    },
    set: function() {
      throw new Error('You cannot set this property in the Component if @Destroyed decorator is used.');
    }
  });


  const original = target.ngOnDestroy;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target.ngOnDestroy = function (...args: any[]) {
    // console.log('calling ngOnDestroy on target', target.constructor.name, 'stopped: ' + this[key].isStopped);
    this[key].next();
    this[key].complete();
    original && typeof original === 'function' && original.apply(this, args);
  };
}

