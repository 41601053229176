<section class="section" [ngClass]="{'section-light': variant==='light',
'gutter-top': gutterTop === 'normal',
'fill-space': fillSpace,
'gutter-top-null-content': gutterTop === 'nullContent',
'gutter-top-negative': gutterTop === 'negative',
'gutter-bottom': gutterBottom === 'normal',
'gutter-bottom-null-content': gutterBottom === 'nullContent',
'gutter-bottom-negative':  gutterBottom === 'negative'}"
>
  <raf-container [size]="size">
    <ng-content></ng-content>
  </raf-container>
</section>
