<ng-container *transloco="let t">

  <div class="mat-dialog-header mat-typography">
    <button class="mat-dialog-close-button mat-small" mat-icon-button (click)="close()">
      <raf-icon icon="close" size="16px"></raf-icon>
    </button>
  </div>

  <mat-dialog-content class="mat-typography">
    <raf-text class="mb-3" variant="h3" color="primary-color">{{ t("LOGOUT_DIALOG.TEXT") }}</raf-text>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" mat-raised-button color="primary" (click)="onConfirm()">{{ t("LOGOUT_DIALOG.CONFIRM") }}</button>
    <button type="button" mat-button (click)="close()">{{ t("GLOBAL.CLOSE") }}</button>
  </mat-dialog-actions>

</ng-container>
