import { animate, style, transition, trigger } from '@angular/animations';

export const triggerLoggedBtn = trigger('toggleShowBtn', [
  transition('void => *', [
    style({
      opacity: 0,
      transform: 'scale(0.6)',
      position: 'absolute',
    }),
    animate(
      '200ms ease-in',
      style({
        opacity: 1,
        transform: 'scale(1)',
      })
    ),
  ]),
  transition('* => void', [
    style({
      opacity: 1,
      transform: 'scale(1)',
      position: 'absolute',
    }),
    animate(
      '200ms ease-out',
      style({
        opacity: 0,
        transform: 'scale(0.6)',
      })
    ),
  ]),
]);
