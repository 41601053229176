import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, of, pairwise, startWith, switchMap, take } from 'rxjs';
import { Cart, ModeData, PickupSite } from '../../shared/model/cart.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CartService } from './cart.service';
import { tap } from 'rxjs/operators';
import { ApiResource } from '../../shared/model/api-resource.model';

interface ModeStore {
  [cartId: string]: BehaviorSubject<ApiResource<ModeData[]>>;
}

interface SiteStore {
  [cartId: string]: BehaviorSubject<ApiResource<PickupSite[]>>;
}

@Injectable()
export class DeliveryPaymentService {
  private _deliveryModes: ModeStore = {};
  private _paymentModes: ModeStore = {};
  private _pickupSites: SiteStore = {};

  constructor(private cartService: CartService, private http: HttpClient) {
    this.bindCacheReset();
  }

  getFromCache<T>(
    store: { [cartId: string]: BehaviorSubject<ApiResource<T>> },
    fn: (cart: Cart) => Observable<T>,
    useSimpleKey = false
  ): Observable<ApiResource<T>> {

    return this.cartService.cart$.pipe(
      take(1),
      filter(cart => !!cart),
      switchMap((cart) => {

        // should never happen thx to filter above
        if (!cart) {
          return of({loading: false, data: null, error: true});
        }

        // store key
        const key = useSimpleKey ? cart.id : cart.id + '~' + cart.deliveryMode?.code;

        // get data from store if it's available
        if (store[key]) {
          return store[key].asObservable();
        }

        // initialize data in store, fetch it, update the store and return directly from store
        store[key] = new BehaviorSubject<ApiResource<T>>({loading: true, data: null, error: false});
        return fn(cart).pipe(
          tap(data => store[key].next({loading: false, data: data, error: false})),
          switchMap(() => store[key].asObservable())
        );

      })
    );
  }

  /**
   * Gets possible delivery methods for cart
   * @returns { Observable(ModeData[]) } -- array of delivery modes
   */
  getDeliveryModes(): Observable<ApiResource<ModeData[]>> {
    return this.getFromCache(this._deliveryModes, (cart) => {
      return this.http.get<ModeData[]>(`${environment.b2cEndpoint}/carts/${cart.id}/delivery-modes`);
    }, true);
  }

  /**
   * Gets pickup sites for delivery mode
   * @param deliveryModeId - id of delivery mode
   * @returns { Observable(ModeData[]) } -- array of payment modes
   */
  getPickupSites(deliveryModeId: string): Observable<ApiResource<PickupSite[]>> {
    return this.getFromCache(this._pickupSites, (cart) => {
      return this.http.get<PickupSite[]>(
        `${environment.b2cEndpoint}/carts/${cart.id}/delivery-modes/${deliveryModeId}/pickup-locations`
      );
    });
  }

  /**
   * Gets possible payment methods for cart
   * @returns { Observable(ModeData[]) } -- array of payment modes
   */
  getPaymentModes(): Observable<ApiResource<ModeData[]>> {
    return this.getFromCache(this._paymentModes, (cart) => {
      return this.http.get<ModeData[]>(`${environment.b2cEndpoint}/carts/${cart.id}/payment-modes`);
    });
  }

  resetDeliveryModeCache(): void {
    this._deliveryModes = {};
  }

  resetPaymentModeCache(): void {
    this._paymentModes = {};
  }

  /** When entries or their quantity change, reset delivery modes cache */
  bindCacheReset(): void {
    this.cartService.cart$.pipe(startWith(undefined), pairwise()).subscribe(([prevVal, newVal]) => {
      if (!prevVal || !newVal) {
        return;
      }
      if (prevVal.entries?.length !== newVal.entries?.length) {
        this.resetDeliveryModeCache();
        return;
      }
      const nrOfPiecesPrev = prevVal.entries?.reduce((acc, entry) => acc + entry.quantity, 0);
      const nrOfPiecesNew = newVal.entries?.reduce((acc, entry) => acc + entry.quantity, 0);
      if (nrOfPiecesPrev !== nrOfPiecesNew) {
        this.resetDeliveryModeCache();
        this.resetPaymentModeCache();
        return;
      }
    });
  }
}
