import { Injectable } from '@angular/core';
import { Cart } from '../../shared/model/cart.model';
import { map, Observable, of } from 'rxjs';
import { PriceSummaryRow } from '../../shared/model/price-summary.model';
import { TranslocoService } from '@ngneat/transloco';
import { Order } from '../../shared/model/order.model';
import { ProductPrices } from '../../shared/model/price.model';

@Injectable()
export class PriceSummaryService {
  constructor(private translocoService: TranslocoService) {}

  /**
   * Maps cart properties to price summary rows
   * @param cart
   * @param prices simulated prices
   * @param totalOnly whether to show only total price
   * @param includeSite whether to include extra info with site address
   */
  mapPriceSummaryRows(
    cart: Cart | Order | null,
    prices: ProductPrices | null,
    totalOnly = false,
    includeSite = false
  ): Observable<PriceSummaryRow[] | null> {
    if (!cart) return of(null);

    const translocoKeys = ['CART.DELIVERY_MODE', 'CART.PAYMENT_MODE', 'CART.PRICE_TOTAL'];

    return this.translocoService.selectTranslate(translocoKeys).pipe(
      map((translations: string[]) => {
        if (!translations.length) {
          return null;
        }

        const result: PriceSummaryRow[] = [];

        if (!totalOnly && cart.deliveryMode) {

          let siteStr = '';
          if (includeSite && 'pickupLocation' in cart) {
            siteStr = `${cart.pickupLocation?.name || ''}`
            if (cart.pickupLocation?.address) {
              siteStr = siteStr + `, ${cart.pickupLocation?.address?.street}, ${cart.pickupLocation?.address?.city}`;
            }
          }

          result.push({
            label: `${translations[0]} - ${cart.deliveryMode?.name}`,
            extraInfo: siteStr,
            price: prices ? { priceWithVat: prices.deliveryPriceWithVat } : { priceWithVat: cart.deliveryMode?.price },
          });
        }

        if (!totalOnly && cart.paymentMode) {
          result.push({
            label: `${translations[1]} - ${cart.paymentMode?.name}`,
            price: prices ? { priceWithVat: prices.paymentPriceWithVat } : { priceWithVat: cart.paymentMode?.price },
          });
        }

        // total
        result.push({
          label: `${translations[2]}`,
          price: prices ?? { priceWithVat: cart.priceCalculation?.priceWithVat, price: cart.priceCalculation?.price },
        });

        return result;
      })
    );
  }
}
