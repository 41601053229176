<ng-container *transloco="let t">

  <div class="dialog-continue-shopping" *ngIf="variant$ | async as variant">

    <div class="mat-dialog-header mat-typography">
      <h2 mat-dialog-title>
        <div class="d-flex align-items-center">
          <raf-icon size="32px" class="me-3" icon="check" color="success"></raf-icon>
          {{ t(variant.title) }}</div>
      </h2>
      <button class="mat-dialog-close-button mat-small" color="secondary" mat-icon-button (click)="close()">
        <raf-icon icon="close" size="16px"></raf-icon>
      </button>
    </div>

    <mat-dialog-content class="mat-typography">

      <raf-loading *ngIf="loading$ | async" variant="inline"></raf-loading>

      <raf-paper variant="stroked" *ngIf="products$ | async as products">
        <raf-products variant="list"
                      listItemSize="normal"
                      [entries]="products"
                      [stockVisible]="false"
                      [priceVisible]="false"
                      [quantityVisible]="variant.showQuantity"
                      [navDisabled]="true"
                      (clicked)="onNav($event)"
                      [readOnly]="true"></raf-products>
      </raf-paper>

    </mat-dialog-content>

    <!-- BUTTONS  -->
    <mat-dialog-actions align="center">

      <button class="mat-button-large" color="secondary" mat-button type="submit" data-cy="continue-shop-btn" (click)="close()">
        {{ t('CONTINUE_SHOPPING_DIALOG.CLOSE') }}
      </button>

      <button class="mat-button-large" type="submit" mat-flat-button color="primary" data-cy="go-to-cart-btn" (click)="close(variant.navPath)">
        {{ t(variant.navText) }}
      </button>

    </mat-dialog-actions>
  </div>

</ng-container>
