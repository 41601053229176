import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconVariant } from './icon.variants';

@Component({
  selector: 'raf-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input()
  icon?: IconVariant;

  @Input()
  size: IconSize = '24px';

  @Input()
  color: IconColor = 'default';
}

export type IconSize = '12px' | '16px' | '24px' | '32px';
export type IconColor = 'primary' | 'secondary' | 'warn' | 'success' | 'default';
