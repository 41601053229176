import { Component, Input } from '@angular/core';

@Component({
  selector: 'raf-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent {
  @Input()
  variant: 'simple' | 'paper' = 'simple';

  @Input()
  dividers?: boolean;


}
