<div class="navigation-menu-hover-container" (mouseleave)="resetActiveCategory()">
  <div class="navigation-menu">
    <raf-container>
      <nav class="stack stack-4" *ngIf="categories">
        <a *ngFor="let category of categories"
           [routerLink]="category.url"
           (click)="onNav(category)"
           (mouseenter)="setActiveCategory(category)"
           [class.active]="(activeCategory$ | async) === category"
           mat-flat-button
           color="secondary"
           data-cy="root-nav-item">{{ category.name }}</a>
      </nav>
    </raf-container>
  </div>

  <ng-container *ngIf="activeCategory$ | async as activeCategory">
    <div class="navigation-drop-menu" *ngIf="activeCategory.subcategories?.length">
      <raf-container>
        <div class="navigation-drop-menu-paper">
            <raf-catalog-navigation [categories]="activeCategory.subcategories || []" [gtmEnabled]="true"></raf-catalog-navigation>
        </div>
      </raf-container>
    </div>
  </ng-container>

</div>


