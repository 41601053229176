<raf-container>
  <div class="breadcrumb">
    <div class="breadcrumb-content">
      <!-- Home route -->
      <a class="breadcrumb_link breadcrumb_link-home" data-cy="home-route" routerLink="/">
        <raf-icon size="16px" icon="home"></raf-icon>
      </a>

      <div *ngIf="breadcrumbs?.length" class="breadcrumb_divider" data-cy="divider">
        <raf-icon size="12px" icon="arrow-right"></raf-icon>
      </div>

      <!-- Breadcrumbs -->
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last;">
        <a class="breadcrumb_link" *ngIf="!last" [routerLink]="breadcrumb.path">
          <raf-text color="inherit" variant="body-medium">{{ breadcrumb.label }}</raf-text>
        </a>
        <div *ngIf="!last" class="breadcrumb_divider" data-cy="divider">
          <raf-icon size="12px" icon="arrow-right"></raf-icon>
        </div>
        <raf-text *ngIf="last" color="inherit" class="breadcrumb_name"
                  variant="body-medium">{{ breadcrumb.label }}</raf-text>
      </ng-container>
    </div>
  </div>
</raf-container>
