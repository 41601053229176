import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { BehaviorSubject, combineLatest, map, Observable, of } from 'rxjs';
import { Image, ImageB2c } from '../../model/image.model';
import { Logger } from '../../../core/services/logger.service';


const IMAGES_KEY = makeStateKey<ImageState>('images');

interface ImageState {
  [key: string]: boolean;
}

@Component({
  selector: 'raf-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input()
  set fromCms(image: Image | undefined) {
    this.updateVisibility(image);
  }

  @Input()
  set fromB2c(image: ImageB2c | undefined | null) {
    this.updateVisibility(this.getImageFromB2c(image));
  }

  @Input() withBorder = false;
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() fixedContainerSize = true;
  @Input() imgCssClass?: string;
  @Input() showCaption = false;
  @Input() targetQuality: 'high' | 'medium' | 'preview' = 'medium';
  @Input() alternativeText?: string;
  @Input() fetchPriority?: 'auto' | 'high' | 'low' = 'auto';

  @ViewChild('imageContainer', { static: true }) imageContainer?: ElementRef<HTMLDivElement>;

  image: Image | null = null;
  placeholderVisible$: Observable<boolean> | null = null;
  imageVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  imageLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  imageError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isPlatformBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private logger: Logger,
              private transferState: TransferState) {
    this.isPlatformBrowser = isPlatformBrowser(this.platformId);
  }

  onImageLoaded(): void {
    this.imageLoaded$.next(true);
  }

  onImageError() {
    this.imageError$.next(true);
  }

  updateVisibility(image?: Image): void {

    if (!image) {
      this.placeholderVisible$ = of(true);
      this.reset();
      return;
    }

    if (this.image?.url === image.url) {
      // we are trying to initialize unchanged image, setting highQualityLoaded$ to false would break the logic
      // as (load) event will never fire again and placeholder would be shown forever
      return;
    }

    this.image = image;
    this.setImagePreloaded();

    if (this.image.url) {
      this.imageLoaded$.next(false);
      this.imageVisible$.next(true);
    }

    this.placeholderVisible$ = combineLatest([this.imageLoaded$, this.imageError$]).pipe(
      map(([loaded, error]) => error || !this.image?.url || (!loaded && !this.wasImagePreloaded()))
    );


    /*if (this.isPlatformBrowser) {
      const ssrRendered = state && state.includes(this.image.url);
      this.placeholderVisible$ = ssrRendered ? of(false) : this.imageLoaded$.pipe(
        map(
          (val) =>
            (!this.image?.url || !val) // no image src at all or image not loaded yet
        )
      );
    } else {
      this.placeholderVisible$ = of(false);
      state.push(this.image.url);
      this.transferState.set(IMAGES_KEY,state);
    }*/
  }

  reset(): void {
    this.image = null;
    this.imageVisible$.next(false);
    this.imageLoaded$.next(false);
  }

  // ========== SSR / BROWSER RE-HYDRATION LOGIC TO AVOID IMAGE FLICKERING
  // if an image is rendered by ssr, we should show it in browser by default without any delay
  // by default, all images are hidden and only show up when they appear in browser viewport

  // if image with this src url was rendered by ssr, we can find it in this state
  // for ssr, we always return true to force render everything
  // in browser it depends on whether the image was rendered by ssr before or not
  // todo try to detect viewport on ssr and only render necessary images
  wasImagePreloaded(): boolean {
    if (this.isPlatformBrowser && this.image?.url) {
      return this.transferState.get<{ [key: string]: boolean }>(IMAGES_KEY, {})[this.image?.url];
    }
    return true;
  }

  // on server, if image with this src url was rendered, save it to state
  setImagePreloaded(): void {
    if (!this.isPlatformBrowser && this.image?.url) {
      const state = this.transferState.get<ImageState>(IMAGES_KEY, {});
      this.transferState.set<ImageState>(IMAGES_KEY, { ...state, [this.image.url]: true });
    }
  }

  private getImageFromB2c(src: ImageB2c | undefined | null): Image | undefined {
    if (!src) {
      return undefined;
    }

    return {
      placeholder: src.thumbUrl,
      alternativeText: '',
      url:
        (this.targetQuality === 'high'
          ? src.largeUrl
          : this.targetQuality === 'medium'
          ? src.detailUrl
          : this.targetQuality === 'preview'
          ? src.previewUrl
          : '') || '', // todo detect optimal size
    };
  }


}
