import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '../../../model/product.model';
import { ErrorMessage } from '../../../model/error-message.model';
import { ProductPrices } from '../../../model/price.model';
import { LayoutService } from '../../../../core/services/layout.service';

@Component({
  selector: 'raf-product-list-item',
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListItemComponent {
  @Input()
  product?: Product;

  @Input()
  readOnly = false;

  @Input()
  quantity?: number;

  @Input()
  mobileLayout: boolean | null = false;

  @Input()
  productPrices?: ProductPrices | null;

  @Input()
  priceVisible = true;

  @Input()
  unitPriceVisible = true;

  @Input()
  stockVisible = true;

  @Input()
  quantityVisible = true;

  @Input()
  size: 'normal' | 'small' = 'normal';

  @Input()
  space: 'normal' | 'table' = 'normal';

  @Input()
  deleting = false;

  @Input()
  messages?: ErrorMessage[];

  @Input()
  navDisabled = false;

  @Output()
  quantityChange: EventEmitter<{ product: Product; quantity: number }> = new EventEmitter<{
    product: Product;
    quantity: number;
  }>();

  @Output()
  remove: EventEmitter<Product> = new EventEmitter<Product>();

  @Output()
  clicked: EventEmitter<Product> = new EventEmitter<Product>();

  xsMobile$: Observable<boolean>;

  constructor(private layoutService: LayoutService) {
    this.xsMobile$ = this.layoutService.observe('(max-width: 460px)');
  }

  onQuantityChange(quantity: number) {
    if (this.product) {
      this.quantityChange.emit({ product: this.product, quantity });
    }
  }

  onRemove() {
    this.remove.emit(this.product);
  }

  onClick(event: MouseEvent) {
    this.clicked.emit(this.product);
    if (this.navDisabled) {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
