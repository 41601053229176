import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, debounceTime, filter, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProductSearchResult } from '../../model/product-search-result.model';
import { Product } from '../../model/product.model';
import { ProductCategory } from '../../model/product-category.model';
import { ProductService } from '../../../core/services/product.service';
import { Destroyed } from '../../decorators/destroyed';
import { Router } from '@angular/router';
import { triggerShowSearchDrop } from './animations';
import { GtmService } from '../../../core/services/gtm.service';

@Component({
  selector: 'raf-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [triggerShowSearchDrop],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit {
  @Input()
  placeholder?: string = 'Hledat';

  productsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  productSearchResult$: BehaviorSubject<ProductSearchResult | null> = new BehaviorSubject<ProductSearchResult | null>(
    null
  );
  products$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([]);
  categories$: BehaviorSubject<ProductCategory[]> = new BehaviorSubject<ProductCategory[]>([]);
  searchFC: UntypedFormControl = new UntypedFormControl('');
  resultsVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Destroyed
  destroyed$!: Subject<void>;

  constructor(private productService: ProductService, private router: Router, private gtm: GtmService) {}

  ngOnInit() {
    this.bindSearch();
  }

  getProducts(query: string): Observable<ProductSearchResult> {
    this.productsLoading$.next(true);
    this.resultsVisible$.next(true);
    return this.productService.getProducts({ query }).pipe(
      tap(() => {
        this.productsLoading$.next(false);
      })
    );
  }

  onFocusChange($event: boolean): void {
    if (!this.searchOpen$.value && this.searchFC.value?.trim()?.length > 2) {
      this.searchOpen$.next($event);
    }
  }

  onNavToSearchResults(): void {
    if (!this.searchFC.value?.trim()) {
      return;
    }
    this.router.navigate(['/vyhledavani'], { queryParams: { query: this.searchFC.value } });
    this.reset();
  }

  private bindSearch(): void {
    this.searchFC.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(250),
        filter((val) => !!val?.trim() && val.length > 2),
        tap(() => this.openSearch()),
        tap((val) => this.logGtm(val)),
        switchMap((val) => this.getProducts(val))
      )
      .subscribe((res) => {
        this.productSearchResult$.next(res);
        this.products$.next(res.items);
        this.categories$.next(res?.queriedCategories || []);
      });

    this.searchFC.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        filter((val) => !val || val.length < 3)
      )
      .subscribe(() => {
        this.resultsVisible$.next(false);
        this.closeSearch();
      });
  }

  openSearch(): void {
    if (!this.searchOpen$.value) {
      this.searchOpen$.next(true);
    }
  }

  closeSearch(): void {
    this.searchOpen$.next(false);
  }

  reset(): void {
    this.searchFC.reset('');
    this.closeSearch();
  }

  private logGtm(query: string): void {
    this.gtm.search(query);
  }
}
