<ng-container *transloco="let t">
  <div class="cart-small">
    <raf-loading *ngIf="loading$ | async; else cart"></raf-loading>

    <ng-template #cart>
      <ng-container *ngIf="cart$ | async as cart; else nodata">


        <ng-container *ngIf="cart?.entries?.length; else nodata">

          <div class="cart-scroll-container">
            <raf-products
              data-cy="products"
              variant="list"
              [entries]="cart?.entries"
              [stockVisible]="false"
              [unitPriceVisible]="false"
              (quantityChange)="onQuantityChange($event)"
              (remove)="onRemove($event)"
            ></raf-products>
          </div>

          <mat-divider class="  mb-4" ></mat-divider>

          <div class="d-flex justify-content-end">
            <raf-text data-cy="total" class="me-4" variant="body-large"><strong>{{t('CART.TOTAL')}}</strong></raf-text>
            <raf-price data-cy="prices" [productPrices]="{priceWithVat: cart.priceCalculation?.priceWithVat}"></raf-price>
          </div>


          <div class="action-footer mt-3" data-cy="action-btn">
            <a routerLink="/kosik" class="full-width" mat-flat-button color="primary" (click)="onNav()">{{t('CART.NAV_TO_CART')}}</a>
          </div>
        </ng-container>

      </ng-container>
    </ng-template>

    <ng-template #nodata>
      <div data-cy="no-items">
        <raf-no-data text="CART.NO_DATA"></raf-no-data>
      </div>
    </ng-template>
  </div>
</ng-container>


