import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { LayoutService } from '../../../core/services/layout.service';

@Component({
  selector: 'raf-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent {
  @ViewChild('fgd', { static: false })
  loginFgd?: FormGroupDirective;

  forgottenPasswordVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  forgottenPasswordSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loginSuccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  fg: UntypedFormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  isMobile$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private layoutService: LayoutService
  ) {
    this.isMobile$ = this.layoutService.mdDown$;
  }

  close(): void {
    this.dialogRef.close();
  }

  toggleForgottenPassword(val: boolean): void {
    this.forgottenPasswordVisible$.next(val);
    if (val) {
      this.fg.get('password')?.disable();
    } else {
      this.fg.get('password')?.enable();
      this.forgottenPasswordSuccess$.next(false);
    }
  }

  onLogin(): void {
    if (this.fg.invalid) {
      return;
    }

    this.loading$.next(true);
    this.userService
      .login(this.fg.value.username, this.fg.value.password)
      .subscribe(() => {
        // show success icon, then close the dialog
        this.loginSuccess$.next(true);
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 1500);
      })
      .add(() => {
        this.loading$.next(false);
      });
  }

  onForgottenPass() {
    if (this.fg.invalid) {
      return;
    }

    this.loading$.next(true);
    this.userService
      .forgottenPassword(this.fg.value.username)
      .subscribe(() => {
        this.forgottenPasswordSuccess$.next(true);
      })
      .add(() => {
        this.loading$.next(false);
      });
  }

  onNav() {
    this.dialogRef.close();
  }
}
