import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Layout } from '../../../model/layout.model';
import { Image, ImageLink, ImageLinkResource } from '../../../model/image.model';
import { CmsAddress } from '../../../model/address.model';
import { SocialSites } from '../../../model/social-sites.model';
import { NavigationNode, NavigationNodeGroup } from '../../../model/navigation-node.model';
import { LayoutService } from '../../../../core/services/layout.service';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { animationRotate, triggerShowHeightState } from '../../../animations/animations';
import { GtmService } from '../../../../core/services/gtm.service';

@Component({
  selector: 'raf-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [triggerShowHeightState, animationRotate],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnChanges {
  @Input()
  layout: Layout | null = null;

  @Input()
  expandableNavigation = false;

  @Input()
  cartLayout = false;

  mobile$: Observable<boolean>;
  navGroupToggle: {
    [navGroupKey: string]: BehaviorSubject<boolean>;
  } = {};
  navGroupContentVisible: {
    [navGroupKey: string]: Observable<boolean>;
  } = {};


  constructor(private layoutService: LayoutService, private gtm: GtmService) {
    this.mobile$ = this.layoutService.mdDown$;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.expandableNavigation && changes['layout']) {
      this.bindNavGroupToggle();
    }
  }

  get navGroups(): NavigationNodeGroup[] | undefined {
    return this.layout?.footerNav;
  }

  get logo(): ImageLink | undefined {
    return {
      image: this.layout?.footerLogo?.image?.data?.attributes,
      url: this.layout?.footerLogo?.url
    };
  }

  get address(): CmsAddress | undefined {
    return this.layout?.address;
  }

  get images(): ImageLinkResource[] | null {
    return this.layout?.footerImages || null;
  }

  getImage(imgLinkResource: ImageLinkResource): Image | undefined {
    return imgLinkResource.image?.data?.attributes;
  }

  getUrl(imgLinkResource: ImageLinkResource): string | undefined {
    return imgLinkResource.url;
  }

  get socials(): SocialSites | undefined {
    return this.layout?.socialSites;
  }

  bindNavGroupToggle(): void {
    this.navGroups?.forEach(group => {
      // ever nav group is collapsed by default
      this.navGroupToggle[group.id] = new BehaviorSubject<boolean>(false);
      this.navGroupContentVisible[group.id] = combineLatest([this.mobile$, this.navGroupToggle[group.id]])
        .pipe(
          map(([mobile, toggle]) => !mobile || toggle)
        );
    })
  }

  toggleNavGroup(groupId: number): void {
    if (!this.navGroupToggle[groupId]) {
      return;
    }
    this.navGroupToggle[groupId].next(!this.navGroupToggle[groupId].value);
  }

  onNav(item: NavigationNode) {
    this.logGtm(item);
  }

  private logGtm(item: NavigationNode): void {
    this.gtm.selectContent('footer', item.text || '');
  }
}
