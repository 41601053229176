import { ChangeDetectorRef, Component, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { mergeWith } from 'rxjs';
import { CustomErrorMessages } from './custom-error-messages.model';
import { FormUtils } from '../../utils/form-utils';
import { Logger } from '../../../core/services/logger.service';


// @dynamic
@Component({
  selector: 'raf-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: (container: ControlContainer) => container,
      deps: [[new Optional(), new SkipSelf(), ControlContainer]],
    },
  ],
})
export class FormFieldErrorComponent implements OnInit {
  @Input()
  control: UntypedFormControl | null = null;

  @Input()
  includeGroupErr = false;

  @Input()
  set messageRequired(message: string) {
    this.customMessages = { ...this.customMessages, required: message };
  }

  @Input()
  set messagePattern(message: string) {
    this.customMessages = { ...this.customMessages, pattern: message };
  }

  @Input()
  set messageEmail(message: string) {
    this.customMessages = { ...this.customMessages, email: message };
  }

  @Input()
  set messageDate(message: string) {
    this.customMessages = { ...this.customMessages, date: message };
  }

  @Input()
  set messageMinlength(message: string) {
    this.customMessages = { ...this.customMessages, minlength: message };
  }

  @Input()
  set messageMaxlength(message: string) {
    this.customMessages = { ...this.customMessages, maxlength: message };
  }

  @Input()
  set messageMin(message: string) {
    this.customMessages = { ...this.customMessages, min: message };
  }

  @Input()
  set messageMax(message: string) {
    this.customMessages = { ...this.customMessages, max: message };
  }

  @Input()
  set messagePhoneNumber(message: string) {
    this.customMessages = { ...this.customMessages, phoneNumber: message };
  }

  @Input()
  set messageTiedRequired(message: string) {
    this.customMessages = { ...this.customMessages, tiedRequired: message };
  }

  @Input()
  set customMessage(def: { [key: string]: string }) {
    if (!def) {
      return;
    }
    this.customMessages = { ...this.customMessages, ...def };
    this.customErrors = Object.entries(def).map(([key, val]) => {
      return { errName: key, errMessage: val };
    });
  }

  formGroup: UntypedFormGroup | null = null;
  formGroupDirective: FormGroupDirective | null = null;
  customMessages: CustomErrorMessages = {};
  customErrors: { errName: string; errMessage: string }[] = [];

  constructor(
    @Optional() private parentContainer: ControlContainer,
    private cdr: ChangeDetectorRef,
    private logger: Logger
  ) {
    /*if (!parentContainer) {
      this.logger.warn('Parent ControlContainer not found');
    }*/
  }

  ngOnInit(): void {
    if (this.parentContainer) {
      const { formGroup, formGroupDirective } = FormUtils.extractFormGroup(this.parentContainer);
      this.formGroup = formGroup;
      this.formGroupDirective = formGroupDirective;

      if (!this.formGroup || !this.formGroupDirective) {
        this.logger.warn('FormGroup or FormGroupDirective not found for FormControl', this.control);
      }

      if (this.formGroup && this.formGroupDirective) {
        this.formGroup.valueChanges.pipe(mergeWith(this.formGroupDirective.ngSubmit)).subscribe(() => {
          // highlight error on submit
          this.cdr.detectChanges();
        });
      }

    }
  }
}
