<ng-container *transloco="let t">
  <div class="section-title" *ngIf="label" data-cy="products-label">
    <raf-text variant="h1" color="primary-color" align="center"  *ngIf="label">{{t(label)}}</raf-text>
  </div>

  <ng-container *ngIf="variant === 'grid'">
    <raf-product-grid [layout]="layout">
      <raf-product-card
        *ngFor="let product of products; trackBy: productTrackByFn; let idx = index"
        [product]="product"
        [stockVisible]="stockVisible"
        [priceVisible]="priceVisible"
        [unitPriceVisible]="unitPriceVisible"
        [quantityVisible]="quantityVisible"
        [navDisabled]="navDisabled"
        (clicked)="onClick(product, idx)"
        (quantityChange)="onQuantityChange($event)"
      ></raf-product-card>
    </raf-product-grid>
  </ng-container>

  <ng-container *ngIf="variant === 'list'">
    <raf-product-list *ngIf="products" [dividers]="listDividers" [variant]="listVariant">
      <raf-product-list-item
        *ngFor="let product of products; trackBy: productTrackByFn; let idx = index"
        [product]="product"
        [readOnly]="readOnly"
        [stockVisible]="stockVisible"
        [priceVisible]="priceVisible"
        [unitPriceVisible]="unitPriceVisible"
        [quantityVisible]="quantityVisible"
        [size]="listItemSize"
        [space]="listItemSpace"
        [mobileLayout]="mobile$ | async"
        [navDisabled]="navDisabled"
        (remove)="onRemove(product)"
        (clicked)="onClick(product, idx)"
        (quantityChange)="onQuantityChange($event)"
      ></raf-product-list-item>
    </raf-product-list>

    <raf-product-list *ngIf="entries" [dividers]="listDividers" [variant]="listVariant">
      <raf-product-list-item
        *ngFor="let entry of entries; trackBy: entryTrackByFn; let idx = index"
        [product]="entry.product"
        [productPrices]="entry.priceCalculation"
        [quantity]="entry.quantity"
        [readOnly]="readOnly"
        [stockVisible]="stockVisible"
        [priceVisible]="priceVisible"
        [unitPriceVisible]="unitPriceVisible"
        [quantityVisible]="quantityVisible"
        [size]="listItemSize"
        [space]="listItemSpace"
        [mobileLayout]="mobile$ | async"
        [deleting]="entry.deleting === true"
        [messages]="entry.messages"
        [navDisabled]="navDisabled"
        (quantityChange)="onQuantityChange($event, entry)"
        (remove)="onRemove(entry)"
        (clicked)="onClick(entry, idx)"
      ></raf-product-list-item>
    </raf-product-list>
  </ng-container>
</ng-container>


