import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request as Request_2 } from 'express';

@Injectable()
export class SsrCookieInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(REQUEST) protected httpRequest: Request_2) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    // If optional request is provided, we are server side
    if (this.httpRequest && this.httpRequest.headers.cookie) {
      req = req.clone({
        setHeaders: {
          Cookie: this.httpRequest.headers.cookie,
        },
      });
    }
    return next.handle(req);
  }
}
