import { AbstractControl, ValidatorFn } from '@angular/forms';

export function moqValidator(val: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {

    if (val && control.value) {
      return control.value % val !== 0 ? {moq: true} : null;
    }

    return null;
  };
}
