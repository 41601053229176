import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
  selector: '[rafLoading]',
})
export class LoadingDirective implements OnDestroy {
  @Input()
  set rafLoading(b: boolean) {
    this.setLoading(b);
  }

  @Input()
  loadingColor: 'white' | 'light' = 'white';

  visible = false;

  constructor(private renderer: Renderer2, private ele: ElementRef) {}

  setLoading(b: boolean): void {
    if (b && !this.visible) {
      this.renderer.addClass(this.ele.nativeElement, 'hasLoading');
      const spinner = this.renderer.createElement('div');
      this.renderer.addClass(spinner, 'loading-block');
      this.renderer.addClass(spinner, 'loading-small');
      if (this.loadingColor === 'light') {
        this.renderer.addClass(spinner, 'loading-block-light');
      }

      const spinnerin = this.renderer.createElement('div');
      this.renderer.addClass(spinnerin, 'loading');

      for (let i = 1; i < 5; i++) {
        const square = this.renderer.createElement('span');
        this.renderer.addClass(square, 'square');
        this.renderer.appendChild(spinnerin, square);
      }

      this.renderer.appendChild(spinner, spinnerin);
      this.renderer.appendChild(this.ele.nativeElement, spinner);
      this.visible = true;
    } else if (!b && this.visible) {
      const spinner = this.ele.nativeElement.querySelector('.loading-block');
      this.renderer.removeChild(this.ele.nativeElement, spinner);
      this.renderer.removeClass(this.ele.nativeElement, 'hasLoading');
      this.visible = false;
    }
  }

  ngOnDestroy(): void {
    if (this.visible) {
      const spinner = this.ele.nativeElement.querySelector('.loading-block');
      this.renderer.removeChild(this.ele.nativeElement, spinner);
      this.renderer.removeClass(this.ele.nativeElement, 'hasLoading');
      this.visible = false;
    }
  }
}
