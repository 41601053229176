import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer, Location } from '@angular/common';
import { Response } from 'express';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Logger } from './logger.service';


@Injectable()
export class SsrService {
  constructor(
    private location: Location,
    private logger: Logger,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(RESPONSE) private response: Response
  ) {}

  redirect(url: string) {
    if (isPlatformServer(this.platformId) && this.response) {
      this.logger.log('Redirecting to', url);
      return this.response.redirect(301, url);
    }
  }

  /** Redirect to given URL if it doesn't match the current one
   * On server, this redirects to target URL
   * In browser, this just changes URL in the browser URL bar without any redirect or reload
   * */
  ensureCorrectUrl(url: string) {
    if (this.location.path() !== url) {
      // redirect for ssr
      if (isPlatformServer(this.platformId)) {
        this.redirect(url);
      }

      // visual change in browser
      if (isPlatformBrowser(this.platformId)) {
        this.logger.log('Fixing URL to', url);
        this.location.go(url);
      }
    }
  }

  setHttpStatus(statusCode: number) {
    if (isPlatformServer(this.platformId) && this.response) {
      this.response.status(statusCode);
    }
  }
}
