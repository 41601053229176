import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Logger } from './logger.service';

const GTM_STATE_KEY = makeStateKey<GtmState>('raf_gtm_tokens');
const PACKETA_STATE_KEY = makeStateKey<PacketaState>('raf_packeta_tokens');

interface GtmState {
  gtm: string;
}

interface PacketaState {
  packetaApiKey: string;
  packetaEmbedUrl: string;
}

@Injectable()
export class SsrTransferStateService {
  constructor(
    private transferState: TransferState,
    private logger: Logger,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject('GTM') private gtm?: string,
    @Optional() @Inject('PACKETA_API_KEY') private packetaApiKey?: string,
    @Optional() @Inject('PACKETA_EMBED_URL') private packetaEmbedUrl?: string
  ) {}

  // on server, read injected tokens (from PM env) and save them to TransferState
  setTokensToTransferState(): void {
    if (isPlatformServer(this.platformId)) {
      this.logger.log('[GtmSsrService] Setting tokens to transferState');
      this.transferState.set<GtmState>(GTM_STATE_KEY, { gtm: this.gtm || '' });
      this.transferState.set<PacketaState>(PACKETA_STATE_KEY, {
        packetaApiKey: this.packetaApiKey || '',
        packetaEmbedUrl: this.packetaEmbedUrl || '',
      });
    }
  }

  // in browser, read the values from TransferState and return them
  getGtmTokensFromTransferState(): GtmState {
    const defaultValue = { gtm: '', gtag: '' };
    if (isPlatformBrowser(this.platformId)) {
      return this.transferState.get<GtmState>(GTM_STATE_KEY, defaultValue);
    }
    return defaultValue;
  }

  getPacketaTokensFromTransferState(): PacketaState {
    const defaultValue = { packetaApiKey: '', packetaEmbedUrl: '' };
    if (isPlatformBrowser(this.platformId)) {
      return this.transferState.get<PacketaState>(PACKETA_STATE_KEY, defaultValue);
    }
    return defaultValue;
  }
}
