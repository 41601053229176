<ng-container *transloco="let t">
  <li class="product-list-item" [class.product-list-table-space]="space === 'table'"
      [class.product-list-item-small]="size=== 'small'" [class.mobile-layout]="mobileLayout">

    <div class="product-list-item-in">

      <raf-image data-cy="image" class="product-img" *ngIf="(xsMobile$ | async) === false" [size]="size" [withBorder]="true"
                 [fromB2c]="product?.image" targetQuality="preview" [alternativeText]="product?.name"></raf-image>
      <div class="product-info-wrap">
        <div class="product-main-section">
          <raf-image data-cy="image" class="product-img" *ngIf="xsMobile$ | async" [size]="size" [withBorder]="true"
                     [fromB2c]="product?.image" targetQuality="preview" [alternativeText]="product?.name"></raf-image>
          <div class="product-title">
            <a class="link product-card_title" [routerLink]="product?.productUrl"
               data-cy="product-name-link">
              <raf-text data-cy="product-name" [variant]="size=== 'small' ? 'h5' : 'h4'"
                        color="primary-color" (click)="onClick($event)">{{ product?.name }}</raf-text>
            </a>
          </div>
        </div>

        <div class="product-middle-section" *ngIf="stockVisible && product?.stock">
          <raf-stock data-cy="stock" [stock]="product?.stock" [productId]="product?.code"></raf-stock>
        </div>

        <div class="product-last-section">
          <div class="product-quantity" [class.read-only]="readOnly" *ngIf="quantityVisible">
            <raf-quantity *ngIf="!readOnly" [size]="size" [value]="quantity" [unit]="product?.sellUnit"
                          [moq]="product?.moq || 1" (valueChange)="onQuantityChange($event)"
                          data-cy="quantity"></raf-quantity>
            <div *ngIf="readOnly" data-cy="quantity-read-only">{{quantity || 0}} {{product?.sellUnit?.symbol}}</div>
          </div>

          <div class="product-price" *ngIf="priceVisible">
            <!-- force small variant to contain only price and price with VAT -->
            <!-- <raf-price [productPrices]="size === 'small' ?
              {price: product?.prices?.price, priceWithVat: product?.prices?.priceWithVat} :
              product?.prices"></raf-price> -->
            <!-- full -->
            <raf-price [size]="size" [productPrices]="productPrices || product?.prices"
                       [unitPriceVisible]="unitPriceVisible" [unit]="product?.baseUnit?.symbol"
                       data-cy="price"></raf-price>
          </div>
        </div>
      </div>

      <div class="product-actions" *ngIf="!readOnly">
        <raf-loading variant="full-page" size="small" *ngIf="deleting"></raf-loading>
        <button data-cy="product-remove-btn" class="mat-button-small remove-button" mat-icon-button (click)="onRemove()"
                [disabled]="deleting">
          <raf-icon size="12px" icon="close"></raf-icon>
        </button>
      </div>
    </div>

    <div class="product-messages" *ngIf="messages?.length">
      <raf-text color="warn-color" *ngFor="let msg of messages">{{t(msg.message, msg.params)}}</raf-text>
    </div>

  </li>
</ng-container>
