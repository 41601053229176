<ng-container
  *ngIf="
    (control?.invalid || (includeGroupErr && formGroup?.invalid)) && (control?.touched || formGroupDirective?.submitted)
  "
>
  <raf-error-list
    [errors]="control?.errors || {}"
    [groupErrors]="includeGroupErr ? formGroup?.errors || null : null"
    [customMessages]="customMessages"
    [customErrors]="customErrors"
  ></raf-error-list>
</ng-container>
