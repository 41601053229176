import { Injectable, isDevMode } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../../../environments/environment';
import { AppConfig } from '../../shared/model/app-config.model';
import { Logger } from './logger.service';

@Injectable()
export class SentryService {
  constructor(private logger: Logger) {}

  initSentry(appConfig: AppConfig): void {
    this.logger.log(`[SentryErrorHandler] Initializing sentry with env=${appConfig.sentry.environment}, \
release=${environment.sentryRelease}`);
    Sentry.init({
      dsn: environment.sentryDns,
      environment: appConfig.sentry.environment,
      release: environment.sentryRelease,
      enabled: !isDevMode(),
      autoSessionTracking: false,
      sendClientReports: false,
      ignoreErrors: [
        'Non-Error exception captured'
      ]
    });
  }
}
