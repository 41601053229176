import { NgModule } from '@angular/core';
import { gtmInitializerProvider, RAF_GTM_TRACKING_CODE } from './core/providers/gtm-initializer-provider';
import { SsrTransferStateService } from './core/services/ssr-transfer-state.service';

/**
 * GTM code is provided in Node env variables and injected into Angular app during SSR (server.ts).
 * During SSR bootstrap this injected value is saved to TransferState (SsrTransferStateService) and transferred to browser.
 * During browser bootstrap this value is extracted from TransferState and provided as injection token again. (GtmModule, down below)
 * */

@NgModule({
  providers: [
    {
      provide: RAF_GTM_TRACKING_CODE,
      useFactory: (ssrTransferStateService: SsrTransferStateService) =>
        ssrTransferStateService.getGtmTokensFromTransferState().gtm,
      deps: [SsrTransferStateService],
    },
    gtmInitializerProvider,
  ],
})
export class GtmModule {}
