<ng-container *transloco="let t">

  <div class="section-title">
    <raf-text variant="h1" color="primary-color" align="center"  *ngIf="label">{{t(label)}}</raf-text>
  </div>

  <div *ngIf="content" class="row promo-block-container" [class.mobile-layout]="(isMobile$ | async)">
    <ng-container *ngFor="let item of content">
      <!--  show everything on desktop, show only flagged items on mobile   -->
      <raf-promo-text *ngIf="(isMobile$ | async) === false ||
      ((isMobile$ | async) === true && item.mobileVisible)" class="col" [content]="item"></raf-promo-text>
    </ng-container>
  </div>

</ng-container>
