<ng-container *transloco="let t">
 <div class="section-title">
  <raf-text variant="h1" color="primary-color" align="center" data-cy="catalog-navigation-label" *ngIf="label">{{ t(label) }}</raf-text>
 </div>

  <div class="subcategories-block-wrap">

      <div class="subcategories-block" *ngFor="let category of categories" class="subcategories-block" data-cy="product-category">

        <!-- CATEGORY IMAGE -->
        <raf-image [fixedContainerSize]="false" [routerLink]="category.url" (click)="onNav(category)" class="subcategories-block-image" targetQuality="preview" [fromB2c]="category.image" [alternativeText]="category.name"></raf-image>

        <div class="subcategories-block-links">
          <!-- CATEGORY NAME -->
          <a class="link" [routerLink]="category.url" (click)="onNav(category)" data-cy="main-category-link">
            <raf-text variant="h4" [gutterBottom]="true" color="primary-color" [align]="(isTablet$ | async) ? 'center' : 'left'">{{ category.name }}</raf-text>
          </a>

          <!-- SUBCATEGORIES -->
          <div class="subcategories-container">
            <div class="subcategories-rows">
              <ng-container *ngFor="let subcategory of category.subcategories;">
                <div class="subcategories-item">
                  <a class="link" [routerLink]="subcategory.url" (click)="onNav(subcategory)" data-cy="sub-category-link">
                    <raf-text color="grey-text">{{ subcategory.name }} </raf-text>
                  </a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

</ng-container>
