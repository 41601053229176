import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

enum PasswordStrength {
  EMPTY = 'EMPTY',
  WEAK = 'WEAK',
  OK = 'OK',
  STRONG = 'STRONG',
}

@Component({
  selector: 'raf-password-strength-indicator',
  templateUrl: './password-strength-indicator.component.html',
  styleUrls: ['./password-strength-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthIndicatorComponent {
  @Input()
  set password(val: string | undefined) {
    if (!val) {
      this.strength$.next(PasswordStrength.EMPTY);
      return;
    }
    this.detectPasswordStrength(val);
  }

  PasswordStrength = PasswordStrength;
  strength$: BehaviorSubject<PasswordStrength> = new BehaviorSubject<PasswordStrength>(PasswordStrength.EMPTY);

  detectPasswordStrength(password: string): void {
    // at least 8 chars (non-whitespace)
    const hasMinLength = /\S{8}/.test(password);
    // lowercase letter
    const hasLowerCase = /[a-z]/.test(password);
    // uppercase letter
    const hasUpperCase = /[A-Z]/.test(password);
    // numeric char
    const hasNumber = /\d/.test(password);

    // how many conditions are met
    const positiveFlagCount = [hasMinLength, hasLowerCase, hasUpperCase, hasNumber].filter((val) => val).length;

    switch (positiveFlagCount) {
      case 3: {
        this.strength$.next(PasswordStrength.OK);
        break;
      }
      case 4: {
        this.strength$.next(PasswordStrength.STRONG);
        break;
      }
      default: {
        this.strength$.next(PasswordStrength.WEAK);
      }
    }
  }
}
