import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/overlay';
import { DIALOG_SIZE } from '../../shared/const/dialog-sizes.const';
import { LayoutService } from './layout.service';

@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog, private layoutService: LayoutService) {}

  open<T, D = unknown, R = unknown>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    config = {
      panelClass: 'mat-dialog',
      maxWidth: DIALOG_SIZE.medium,
      position: { bottom: this.layoutService.mdDown$.value ? '0' : undefined },
      ...config,
    };
    return this.dialog.open(componentOrTemplateRef, config);
  }
}
