import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Injectable()
export class ServerStatusService {

  private cmsServerError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private b2xServerError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get serverError$(): Observable<boolean> {
    return combineLatest([this.cmsServerError$, this.b2xServerError$]).pipe(map(([cmsErr, b2xErr]) => {
      return cmsErr || b2xErr;
    }));
  }

  setCmsServerError(b: boolean): void {
    this.cmsServerError$.next(b);
  }

  setB2xServerError(b: boolean): void {
    this.b2xServerError$.next(b);
  }

  reset(): void {
    this.cmsServerError$.next(false);
    this.b2xServerError$.next(false);
  }
}
