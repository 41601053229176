<ng-container *transloco="let t">
  <ng-container *ngIf="messageGroups$ | async as messageGroups">
    <ng-container *ngFor="let messageGroupKey of messageGroupKeys; last as last">
      <ng-container *ngIf="messageGroups[messageGroupKey] as group">
        <ng-container *ngIf="group.messages.length">
          <ng-container *ngTemplateOutlet="messageWidget; context: {messageGroup: group}"></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>


  <ng-template #messageWidget let-messageGroup="messageGroup">
    <div class="alertWidget" [ngClass]="messageGroup.alertClass">
        <div class="alertWidget__iconWrap">
          <div class="alertWidget__icon" [ngSwitch]="messageGroup.type">
            <raf-icon *ngSwitchCase="'success'"
                      icon="full-circle-success"></raf-icon>
            <raf-icon *ngSwitchCase="'warn'"
                      icon="full-circle-warning"></raf-icon>
            <raf-icon *ngSwitchCase="'error'"
                      icon="full-circle-danger"></raf-icon>
            <raf-icon *ngSwitchDefault
                      icon="full-circle-warning"></raf-icon>
          </div>
        </div>

        <div class="alertWidget__content">
          <ul class="styled-list">
            <li *ngFor="let message of messageGroup.messages" [innerHTML]="message.message"></li>
          </ul>

        </div>
        <div class="alertWidget__action">
          <button class="mat-button-extra-small" mat-button (click)="onCloseGroup(messageGroup)">{{t('ALERTS.HIDE_GROUP')}}</button>
        </div>

    </div>
  </ng-template>
</ng-container>



