import { APP_INITIALIZER, Provider } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Logger } from '../services/logger.service';
import { environment } from '../../../environments/environment';

/**
 * Create a script element in DOM and initiate Cookie script.
 */
export function CookieScriptInitializer(document: Document, logger: Logger) {
  return async () => {
    if (!environment.cookieScriptId) {
      logger.log('Empty Cookie script ID. Skipping initialization.');
      return;
    }

    if (!document) {
      logger.error('Document interface unavailable. Cookie script works in browser only.');
      return;
    }

    const cookieScript: HTMLScriptElement = document.createElement('script');
    cookieScript.type = 'text/javascript';
    cookieScript.src = `//cdn.cookie-script.com/s/${environment.cookieScriptId}.js`;

    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.prepend(cookieScript);
  };
}

export const cookieScriptInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: CookieScriptInitializer,
  deps: [
    DOCUMENT,
    Logger
  ]
};
