import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'smallDecimals',
})
export class SmallDecimalsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml | string {
    if (!value) {
      return value;
    }

    const commaIdx = value.indexOf(',');

    const firstPart = value.substring(0, commaIdx);
    const decimalPart = value.substring(commaIdx);

    return `${firstPart}<small>${decimalPart}</small>`;
  }
}
