import { Component } from '@angular/core';
import { Event, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { delay, filter, Observable, of, pairwise, race, take } from 'rxjs';
import { ServerStatusService } from './core/services/server-status.service';
import { ErrorVariant } from './shared/model/error-variant.model';
import { SeoService } from './core/services/seo.service';
import { LoadingService } from './core/services/loading.service';

@Component({
  selector: 'raf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'raf-b2c-web-ui';

  everybodysDeadDave$: Observable<boolean>;
  errorVariant: ErrorVariant = 500;

  constructor(private serverStatusService: ServerStatusService,
              private router: Router,
              private viewportScroller: ViewportScroller,
              private seoService: SeoService,
              private loadingService: LoadingService) {
    this.everybodysDeadDave$ = serverStatusService.serverError$;

    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.handleScrollOnNavigation();
    this.seoService.setFacebookDomainVerificationTag();
    this.seoService.setSeznamMetaTag();
  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Source: https://gist.github.com/iffa/9c820072135d25a6372d58075fe264dd
   * Related to: https://github.com/angular/angular/issues/26744
   */
  private handleScrollOnNavigation(): void {
    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((e: Scroll[]) => {
      const previous = e[0];
      const current = e[1];
      if (current.position) {
        // Backward navigation
        const timeout$ = of(true).pipe(delay(500)); // if no loadingFinished value is emitted within 500ms, scroll
        race(
          timeout$,
          this.loadingService.loadingFinished$
        ).pipe(take(1)).subscribe(() => {
          setTimeout(() => {
            this.viewportScroller.scrollToPosition(current.position || [0, 0]);
          }, 0); // wait for page to render
        })
      } else if (current.anchor) {
        // Anchor navigation - disabled, smooth scroll is handled in page component manually
        // this.viewportScroller.scrollToAnchor(current.anchor);
      } else {
        // Check if routes match, or if it is only a query param change
        if (this.getBaseRoute(previous.routerEvent.urlAfterRedirects) !== this.getBaseRoute(current.routerEvent.urlAfterRedirects)) {
          // Routes don't match, this is actual forward navigation
          // Default behavior: scroll to top
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      }
    });
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
