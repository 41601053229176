import {ErrorHandler} from "@angular/core";

import * as Sentry from "@sentry/angular-ivy";

export const sentryErrorHandlerProvider = {
  provide: ErrorHandler,
  useValue: Sentry.createErrorHandler({
    showDialog: false
  })
}
